import styled, { css } from 'styled-components'
import { down } from 'styled-breakpoints'
import { VariantType } from 'styles/theme'

export const typographyCSS = (variant: VariantType) => css`
  margin: 0;
  ${({ theme }) => `
    ${
      theme.typography[variant].fontFamily
        ? `font-family: ${theme.typography[variant].fontFamily || ''};`
        : ''
    }
    ${
      theme.typography[variant].fontSize
        ? `font-size: ${theme.typography[variant].fontSize || ''}px;`
        : ''
    }
    ${
      theme.typography[variant].fontWeight
        ? `font-weight: ${theme.typography[variant].fontWeight || ''};`
        : ''
    }
    ${
      theme.typography[variant].lineHeight
        ? `line-height: ${theme.typography[variant].lineHeight || ''};`
        : ''
    }
    ${
      theme.typography[variant].letterSpacing
        ? `letter-spacing: ${theme.typography[variant].letterSpacing || ''}px;`
        : ''
    }
    ${
      theme.typography[variant].textTransform
        ? `text-transform: ${theme.typography[variant].textTransform || ''};`
        : ''
    }
  `}

  ${down('sm')} {
    ${({ theme }) => `
      ${
        theme.typography[variant].mobile?.fontFamily
          ? `font-family: ${
              theme.typography[variant].mobile?.fontFamily || ''
            };`
          : ''
      }
      ${
        theme.typography[variant].mobile?.fontSize
          ? `font-size: ${theme.typography[variant].mobile?.fontSize || ''}px;`
          : ''
      }
      ${
        theme.typography[variant].mobile?.fontWeight
          ? `font-weight: ${
              theme.typography[variant].mobile?.fontWeight || ''
            };`
          : ''
      }
      ${
        theme.typography[variant].mobile?.fontStyle
          ? `font-style: ${theme.typography[variant].mobile?.fontStyle || ''};`
          : ''
      }
      ${
        theme.typography[variant].mobile?.lineHeight
          ? `line-height: ${
              theme.typography[variant].mobile?.lineHeight || ''
            };`
          : ''
      }
      ${
        theme.typography[variant].mobile?.letterSpacing
          ? `letter-spacing: ${
              theme.typography[variant].mobile?.letterSpacing || ''
            }px;`
          : ''
      }
      ${
        theme.typography[variant].mobile?.textTransform
          ? `text-transform: ${
              theme.typography[variant].mobile?.textTransform || ''
            };`
          : ''
      }
    `}
  }
`

const createTypography = (
  variant: VariantType,
  element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'button' | 'div',
  textColor: 'body' | 'heading' = 'body',
) => {
  return styled.h1`
    ${typographyCSS(variant)}
    color: ${({ theme }) => theme.palette.text[textColor]};
  `.withComponent(element)
}

export const H1 = createTypography(VariantType.h1, 'h1', 'heading')
export const H2 = createTypography(VariantType.h2, 'h2', 'heading')
export const H3 = createTypography(VariantType.h3, 'h3', 'heading')
export const H4 = createTypography(VariantType.h4, 'h4', 'heading')
export const H5 = createTypography(VariantType.h5, 'h5', 'heading')
export const H6 = createTypography(VariantType.h6, 'h6', 'heading')
export const Body1 = createTypography(VariantType.body1, 'p')
export const Body2 = createTypography(VariantType.body2, 'div')
