import * as React from 'react'
import { Dropdown } from 'components/atoms'
import { PriceType } from 'types'
import { classNames } from '@progress/kendo-react-common'
import RadioButtonComponent from 'components/atoms/RadioButton'

export interface ChangePriceTypeProps {
  selected?: PriceType
  handleSelection: (PriceType: PriceType) => void
  className?: string
  labelClassName?: string
  supportedPriceTypes?: Array<PriceType>
}

const DEFAULT_OPTIONS = Object.values(PriceType)

export const ChangePriceType = ({
  className,
  selected,
  handleSelection,
  labelClassName,
  supportedPriceTypes,
}: ChangePriceTypeProps): React.ReactElement => {
  const options = React.useMemo(() => {
    const items = supportedPriceTypes?.length
      ? supportedPriceTypes
      : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedPriceTypes])
  return (
    <RadioButtonComponent
      showLabel
      current={options.find(({ value }) => value === selected)}
      options={options}
      onChange={handleSelection}
      className={className}
      labelClassName={classNames('k-mr-2', labelClassName)}
      label="Value"
    />
  )
}
