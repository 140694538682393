import React, { useState, useEffect, useContext } from 'react'
import { Parser } from './parser'
import { IHistoricalAnalyzerDataSource } from './types'
import useCodeMirror from './useCodeMirror'
import styled from 'styled-components'
import {HistoricalAnalyzerContext} from 'context/HistoricalAnalyzerContext'

const ExpressionContainer = styled.div`
    width: 100%;
    .ͼo .cm-placeholder {
        font-family: "OpenSans", Helvetica, Arial, sans-serif;
        font-weight: 200;
        color: #757575;
    }
    .ͼ1.cm-editor {
    background-color: inherit !important;
    }
    .ͼo.cm-editor {
        background-color: inherit;
        width: 280px;
        height: 30px;
        cm-diagnosticText {
            color: blue;
            background-color: yellow;
        }
    }
    #ExpressionId {
        width: 100px;
        height: 30px;
        boder: none;
        .cm-line {
          caret-color: #FFFFFF;
          font-size: 14px;
          font-family: "OpenSans", Helvetica, Arial, sans-serif;
        }
    }
    #ExpressionIdWithDiv {
        width: 100px;
        height: 30px;
        border: 1px solid white;
        border-radius: 4px;
        .cm-line {
          caret-color: #FFFFFF;
          font-size: 14px;
          font-family: "OpenSans", Helvetica, Arial, sans-serif;
        }   

    }
`


interface HistoricalAnalyzerExpressionTextCellProps {
    value?: string
    handleChange: (value: string, parser: Parser) => void
    dataSources: Array<IHistoricalAnalyzerDataSource>
}

const HistoricalAnalyzerExpressionTextCell: React.FC<HistoricalAnalyzerExpressionTextCellProps> =
    (props) => {
        const {
            value = '',
            dataSources,
            handleChange,
        } = props


        // Expression Validation

        const context = useContext(HistoricalAnalyzerContext)

        const {
          updateChartBtnStatus
        } = context.state
        const {
          setUpdateChartBtnStatus
        } = context



        const [localVal, setLocalVal] = useState(value)

        const [selectedDiv, setSelectedDiv] = useState("ExpressionId");

        const {
            containerRef: codeMirrorRef,
            parsed,
            clearInput,
            setInput,
        } = useCodeMirror(localVal, dataSources)


        useEffect(() => {
            if (parsed?.inputString() && parsed?.isValid()) {
                clearInput()
                setLocalVal('')
                handleChange(parsed.inputString(), parsed)
            }
        }, [localVal])

        const handleFocus = () => {
            setSelectedDiv("ExpressionIdWithDiv")
        }

        const handeOnBlur = () => { 
            setSelectedDiv("ExpressionId")
            if (parsed?.inputString() && parsed?.isValid()) {
                setUpdateChartBtnStatus(false)
                clearInput()
                setLocalVal('')
                handleChange(parsed.inputString(), parsed)
            } else {
                setUpdateChartBtnStatus(true)
                clearInput()
                setLocalVal('')
            }
        }
        
        return (
            <td
                style={{
                    display: 'table-cell',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                    }}
                >
                    <span
                        style={{
                            width: '70%',
                            display: 'flex',
                        }}
                    >
                        <ExpressionContainer>
                            <div
                                id={selectedDiv}
                                ref={codeMirrorRef}
                                style={{
                                    WebkitAppearance: 'none',
                                    background: 'none',
                                    color: 'rgb(228, 231, 235)',                                  
                                    width: '100%',
                                }}
                                onFocus={handleFocus}
                                // If this (contentEditable='true') is removed, the keyboard navigation will work and there will not be input on focus border but if kept, 
                                // the keyboard navigation will not work and there will be input on focus border
                                
                                // contentEditable='true'
                                onBlur={handeOnBlur}
                                placeholder={'Click to add expression'}
                            />

                        </ExpressionContainer>

                    </span>
                </div>
            </td>
        )
    }

export default HistoricalAnalyzerExpressionTextCell
