import { TenorDays } from 'consts'
import { ITimeSeriesDataPoint, Source, Ticker, TimeSeriesData } from 'types'
import { Model } from 'types/models'
import {
  ModelParametersDatetimeRangeWithFieldParams,
  MultiSeriesResult,
} from './common'
import { getModelParameters } from './modelParameters'

type OptionAtmVolatilityResponseItem = {
  atm: number
  timestamp: number
}

const isOptionAtmVolatilityResponseItem = (
  item: any,
): item is OptionAtmVolatilityResponseItem => {
  return (
    !!item &&
    item.atm !== undefined &&
    !Number.isNaN(item.atm) &&
    item.timestamp !== undefined &&
    !Number.isNaN(item.timestamp)
  )
}

const mapOptionAtmVolatilityResponseItemToSeries = (
  item: OptionAtmVolatilityResponseItem,
): ITimeSeriesDataPoint => {
  return {
    x: new Date(item.timestamp / 1000),
    y: item.atm,
  }
}

export const getOptionAtmVolatilitySeries = async (
  query: ModelParametersDatetimeRangeWithFieldParams,
): Promise<TimeSeriesData | Error> => {
  try {
    const data = await getModelParameters(
      query,
      isOptionAtmVolatilityResponseItem,
    )
    const series = data
      .map(mapOptionAtmVolatilityResponseItemToSeries)
      .sort((a, b) => a.x.getTime() - b.x.getTime())
    return {
      key:
        Object.keys(TenorDays).find(
          (key) => TenorDays[key] === parseInt(query.tenor),
        ) || '',
      dataPoints: series,
    }
  } catch (err) {
    return err as Error
  }
}

export const getMultiOptionAtmVolatilitySeries = async (
  source: Source,
  ticker: Ticker,
  model: Model,
  tenors: Array<string>,
  start: Date,
  end: Date,
): Promise<MultiSeriesResult<TimeSeriesData>> => {
  const queries: Array<ModelParametersDatetimeRangeWithFieldParams> =
    tenors.map((tenor) => ({
      model,
      exchange: source.toLowerCase(),
      currency: ticker,
      start: start.toISOString(),
      end: end.toISOString(),
      // TODO: Replace Tenor type with an object that has both label and dayCount
      tenor: `${TenorDays[tenor]}d`,
      field: 'atm',
    }))
  const results = await Promise.all(queries.map(getOptionAtmVolatilitySeries))
  const successes = results.filter(
    (result): result is TimeSeriesData => !(result instanceof Error),
  )
  const errors = results.filter(
    (result): result is Error => result instanceof Error,
  )
  return {
    successes,
    errors,
  }
}
