import { ReactNode } from 'react'
import styled from 'styled-components'

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
`

interface IAnalyzerSectionProps {
  children: ReactNode
  title?: string
}
export const AnalyzerSection: React.FC<IAnalyzerSectionProps> = (props) => {
  const { title, children } = props

  return (
    <StyledSection>
      {title && <h3>{title}</h3>}
      {children}
    </StyledSection>
  )
}
