import { MS_PER_DAY } from '@progress/kendo-date-math'
import { Dropdown } from 'components/atoms'
import { useMemo } from 'react'
import { IChartFrequencyData, Frequency } from 'types'

interface IChartFrequencyPickerProps {
  value: IChartFrequencyData
  handleChange?: (frequency: IChartFrequencyData) => void
}

export const FREQUENCIES: Record<Frequency, IChartFrequencyData> = {
  '1h': { unit: 'hours', step: 1, duration: MS_PER_DAY / 24 },
  '4h': { unit: 'hours', step: 4, duration: MS_PER_DAY / 6 },
  '1D': { unit: 'days', step: 1, duration: MS_PER_DAY },
  '1W': { unit: 'weeks', step: 1, duration: MS_PER_DAY * 7 },
  '1M': { unit: 'months', step: 1, duration: MS_PER_DAY * 7 * 30 },
}

const FrequencyChange: React.FC<IChartFrequencyPickerProps> = (
  props: IChartFrequencyPickerProps,
) => {
  const { handleChange, value } = props
  const data = useMemo(
    () =>
      Object.entries(FREQUENCIES).map(([key, frequency]) => ({
        name: key,
        value: frequency,
      })),
    [],
  )

  return (
    <Dropdown
      options={data}
      current={data.find(
        (i) => i.value.unit === value.unit && i.value.step === value.step,
      )}
      onChange={handleChange}
      label="Frequency"
    />
  )
}

export default FrequencyChange
