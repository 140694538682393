import { Dialog } from '@progress/kendo-react-dialogs'
import styled from 'styled-components'

interface IHelpModalProps {
  handleClose: () => void
  setFunctionName: (name: string) => void
}

const DialogWrapper = styled(Dialog)`
  .k-dialog{
   box-shadow: -4px 0px 55px rgb(255 255 255 / 10%);
  }
`

const StyledTable = styled.table`
  & th,
  & td {
    padding: 10px;
  }
  & td {
    vertical-align: top;
  }

  & tr {
    cursor: pointer;
  }
`

const DialogCustomTitle = styled.div`
  color: #FFFFFF;

  span {
    font-size: 14px;
  }
`

const FUNCTION_LIST = [
  {
    name: 'avg',
    desc: 'Average of the input elements',
    usage: ['avg(vector)', 'avg(scalar, ...)'],
  },
  {
    name: 'r_avg',
    desc: 'Rolling average of the input elements over window N (scalar)',
    usage: ['r_avg(vector, N)'],
  },
  {
    name: 'cdf',
    desc: 'Cumulative distribution function expressed as (quantile, cumulative probability)',
    usage: ['cdf(vector)'],
  },
  {
    name: 'corr',
    desc: 'Pearson correlation between two input vectors',
    usage: ['corr(vector, vector)'],
  },
  {
    name: 'r_corr',
    desc: 'Rolling Pearson correlation between two input vectors over windor N (scalar)',
    usage: ['r_corr(vector, vector, N)'],
  },
  {
    name: 'delta',
    desc: "Computes non-overlapping change over N time periods (according to vector's granularity)",
    usage: ['delta(vector, N)'],
  },
  {
    name: 'if',
    desc: 'If CASE is true, output A else B',
    usage: ['if(CASE, A, B)'],
  },
  {
    name: 'log10',
    desc: 'Element-wise common logarithm',
    usage: ['log10(vector)'],
  },
  { name: 'ln', desc: 'Element-wise natural logarithm', usage: ['ln(vector)'] },
  /* { name: 'hist', desc: 'Histogram of input vector', usage: ['hist(vector)'] }, */
  {
    name: 'max',
    desc: 'Maximum of input elements',
    usage: ['max(vector)', 'max(scalar, ...)'],
  },
  {
    name: 'mean',
    desc: 'Average of the input elements',
    usage: ['mean(vector)'],
  },
  {
    name: 'r_mean',
    desc: 'Rolling average of the input elements over window N (scalar)',
    usage: ['r_mean(vector, N)'],
  },
  {
    name: 'min',
    desc: 'Minimum of input elements',
    usage: ['min(vector)', 'min(scalar, ...)'],
  },
  {
    name: 'o_delta',
    desc: "Computes overlapping change over N time periods (according to vector's granularity)",
    usage: ['o_delta(vector, N)'],
  },
  {
    name: 'rdelta',
    desc: "Computes non-overlapping percentage change over N time periods (according to vector's granularity)",
    usage: ['rdelta(vector, N)'],
  },
  {
    name: 'o_rdelta',
    desc: "Computes overlapping percentage change over N time periods (according to vector's granularity)",
    usage: ['o_rdelta(vector, N)'],
  },
  {
    name: 'regress',
    desc: 'Regression of X against Y. Returns alpha, beta, R^2, correlation, KS and JB',
    usage: ['regress(vector X, vector Y)'],
  },
  {
    name: 'shift',
    desc: 'Shift vector right (-N) or left (N)',
    usage: ['shift(vector, N)'],
  },
  {
    name: 'sqrt',
    desc: 'Square root of the argument',
    usage: ['sqrt(scalar)', 'sqrt(vector)'],
  },
  {
    name: 'stdev',
    desc: 'Standard deviation of the input vector',
    usage: ['stdev(vector)'],
  },
  {
    name: 'r_stdev',
    desc: 'Rolling standard deviation of the input vector over window N (scalar)',
    usage: ['r_stdev(vector, N)'],
  },
  {
    name: 'sum',
    desc: 'Element-wise sum of input elements',
    usage: ['sum(vector)', 'sum(scalar, ...)'],
  },
]

const CustomTitle = () => {
  return (
    <DialogCustomTitle>
      Available functions (<span> * Use double-click to select function.</span>)
    </DialogCustomTitle>
  );
}

const HelpModal: React.FC<IHelpModalProps> = (props) => {
  const { handleClose, setFunctionName } = props

  const selectFunction = (func) => {
    if(func.length > 0){
      setFunctionName(func[0])
      handleClose()
    }
  }

  return (
    <DialogWrapper
      title={<CustomTitle/>}
      onClose={handleClose}
      style={{ overflowY: 'scroll' }}
      height={400}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Usage</th>
          </tr>
        </thead>
        <tbody>
          {FUNCTION_LIST.map((func) => {
            return (
              <tr key={func.name} onDoubleClick={() => selectFunction(func.usage)}>
                <td>{func.name}</td>
                <td>{func.desc}</td>
                <td>
                  {func.usage.map((usageCase) => {
                    return <p key={`${func.name}${usageCase}`}>{usageCase}</p>
                  })}
                </td>
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
    </DialogWrapper>
  )
}

export default HelpModal
