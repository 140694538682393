import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { DateTimePicker } from 'components/atoms/DatePickers'
import { DATETIME_FORMAT, START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'
import { isBefore } from 'date-fns'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import { DateRange } from 'types'
import { useTimestamp } from './ChartWrapper'

interface IDateRangeProps {
  range?: DateRange
  handleChange?: Dispatch<SetStateAction<DateRange | undefined> | DateRange>
  rootStyle?: Record<string, string>
  rowStyle?: Record<string, string>
}

const StyledLabel = styled.label`
  text-align: left;
`

const StyledWrapper = styled.div`
  display: flex;
  min-width: 275px;
  width: 100%;
  justify-content: flex-end;
`

const StyledRow = styled.div`
  display: flex;
  padding-right: 1rem;
  flex-direction: column;
  justify-content: space-around;
`

const DateRangeSelector: React.FC<IDateRangeProps> = (props) => {
  const { range, handleChange, rootStyle, rowStyle } = props
  const { timestampRange, setTimestampRange } = useTimestamp(
    range,
    handleChange,
  )

  return (
    <StyledWrapper style={rootStyle}>
      {timestampRange?.from && (
        <StyledRow style={rowStyle}>
          <StyledLabel htmlFor="from">From</StyledLabel>
          <DateTimePicker
            name="from"
            value={timestampRange.from}
            format={DATETIME_FORMAT}
            min={START_OF_BLOCKSCHOLES_TIME}
            max={TODAY_UTC}
            fillMode="flat"
            onChange={(e: DateTimePickerChangeEvent) => {
              if (!e.value || isBefore(e.value, START_OF_BLOCKSCHOLES_TIME)) {
                return
              }
              setTimestampRange({ until: timestampRange.until, from: e.value })
            }}
          />
        </StyledRow>
      )}
      {timestampRange?.until && (
        <StyledRow style={rowStyle}>
          <StyledLabel htmlFor="to">To</StyledLabel>
          <DateTimePicker
            name="to"
            format={DATETIME_FORMAT}
            value={timestampRange.until}
            min={START_OF_BLOCKSCHOLES_TIME}
            max={TODAY_UTC}
            fillMode="flat"
            onChange={(e: DateTimePickerChangeEvent) => {
              if (!e.value || isBefore(e.value, START_OF_BLOCKSCHOLES_TIME)) {
                return
              }
              setTimestampRange({ from: timestampRange.from, until: e.value })
            }}
          />
        </StyledRow>
      )}
    </StyledWrapper>
  )
}

export default DateRangeSelector
