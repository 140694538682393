import React, { useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import DateRangeSelector from 'components/common/DateRangeSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import { AssetClassSource, IChartFrequencyData, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import MultiTimeSeriesBarChart from './MultiSeriesBarChart'
import ChartLoader from './ChartLoader'

const PerpetualVolume: React.FC = () => {
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [source, setSource] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )

  const [seriesDataReader] = useAsyncResource(
    dataService.getVolume,
    source,
    AssetClass.SWAP,
    ticker,
    ['PERPETUAL'],
  )

  return (
    <ChartWrapper
      title="Volume"
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={AssetClassSource[AssetClass.SWAP]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <MultiTimeSeriesBarChart
            dataReader={seriesDataReader}
            frequency={frequency}
            activeSeries={['PERPETUAL']}
            valueAxisLabels={{ format: 'n0', font: '0.75em "OpenSans"' }}
            valueAxisTitle={() => 'CONTRACTS'}
            valueAxisCrosshairFormat="n0"
            singleAxis
            title="Perpetual Volume"
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default PerpetualVolume
