import { MAX_TERM_DAYS } from 'consts'
import { ModelParametersSeries, ModelParametersWithDate } from 'types'
import {
  EndpointDefinitionRequiringZipping,
  getBSData,
  ModelParametersQueryParams,
  ModelParametersSpecifiedDatetimeParams,
} from './common'
import {
  HistoricImpliedVolatilityResponseItemSvi,
  isHistoricImpliedVolatilityResponseItemSvi,
  HistoricImpliedVolatilityResponseItemSabr,
  isHistoricImpliedVolatilityResponseItemSabr,
} from './historicImpliedVolatility'

const MODEL_PARAMETERS_ENDPOINT: EndpointDefinitionRequiringZipping = {
  path: 'modelparams/getModelParameters',
  itemsRequireZipping: true,
}

type ModelParametersResponseItemSvi =
  HistoricImpliedVolatilityResponseItemSvi & {
    atm_vol: number
  }

const isImpliedVolatilityResponseItemSvi = (
  item: any,
): item is ModelParametersResponseItemSvi => {
  return (
    !!item && item.atm_vol && isHistoricImpliedVolatilityResponseItemSvi(item)
  )
}

type ModelParametersResponseItemSabr =
  HistoricImpliedVolatilityResponseItemSabr & {
    atm_vol: number
  }

const isImpliedVolatilityResponseItemSabr = (
  item: any,
): item is ModelParametersResponseItemSabr => {
  return (
    !!item &&
    item.atm_vol !== undefined &&
    isHistoricImpliedVolatilityResponseItemSabr(item)
  )
}

type ModelParametersResponseItem =
  | ModelParametersResponseItemSvi
  | ModelParametersResponseItemSabr

const isModelParametersResponseItem = (
  item: any,
): item is ModelParametersResponseItem => {
  return (
    isImpliedVolatilityResponseItemSvi(item) ||
    isImpliedVolatilityResponseItemSabr(item)
  )
}

export const getModelParameters = async <T>(
  query: ModelParametersQueryParams,
  typeguard?: (x) => x is T,
): Promise<Array<T>> => {
  const urlSearchParams = new URLSearchParams(query)
  const result = await getBSData(
    MODEL_PARAMETERS_ENDPOINT,
    urlSearchParams,
    typeguard,
  )
  return result[0]
}

const mapModelParametersResponseItemToSeries = (
  item: ModelParametersResponseItem,
): ModelParametersWithDate => {
  const dateOffset = item.expiry * MAX_TERM_DAYS
  const timestamp = new Date(+item.timestamp / 1000)
  if (isImpliedVolatilityResponseItemSvi(item)) {
    return {
      timestamp,
      dateOffset,
      sviA: item.svi_a,
      sviB: item.svi_b,
      sviM: item.svi_m,
      sviRho: item.svi_rho,
      sviSigma: item.svi_sigma,
      sviVol: item.atm_vol,
    }
  }
  return {
    timestamp,
    dateOffset,
    sabrAlpha: item.sabr_alpha,
    sabrRho: item.sabr_rho,
    sabrVolVol: item.sabr_volvol,
    sabrVol: item.atm_vol,
  }
}

export const getModelParametersData = async (
  query: ModelParametersSpecifiedDatetimeParams,
): Promise<ModelParametersSeries | Error> => {
  try {
    const data = await getModelParameters(query, isModelParametersResponseItem)
    const series = data
      .map(mapModelParametersResponseItemToSeries)
      .sort((a, b) => a.dateOffset - b.dateOffset)
    return {
      key: series[0].timestamp.toISOString(),
      dataPoints: series,
    }
  } catch (err) {
    return err as Error
  }
}
