// Based heavily on example here: https://github.com/prometheus/codemirror-promql/
import { Terms } from '@blockscholes/ql'
import Query from './query'
import { ParseTreeNode, ParseTreeRoot, AST, ValueType } from './types'

export interface BSLangFunction {
  name: string
  supportedArgTypes: ValueType[]
  minArgs: number
  variadic: boolean
  returnType: ValueType
}

const bsLangFunctions: { [key: number]: BSLangFunction } = {
  [Terms.Average]: {
    name: 'avg',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.RollingAverage]: {
    name: 'r_avg',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.CumulativeDistributionFunction]: {
    name: 'cdf',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.Change]: {
    name: 'delta',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.OverlappingChange]: {
    name: 'o_delta',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.CommonLog]: {
    name: 'log10',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.Correlation]: {
    name: 'corr',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.RollingCorrelation]: {
    name: 'r_corr',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.If]: {
    name: 'if',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.Histogram]: {
    name: 'hist',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.Maximum]: {
    name: 'max',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.Mean]: {
    name: 'mean',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.RollingMean]: {
    name: 'r_mean',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.Minimum]: {
    name: 'min',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.Multiply]: {
    name: 'mul',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.NaturalLog]: {
    name: 'ln',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.Regression]: {
    name: 'regress',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector, // 5 scalars
  },
  [Terms.Shift]: {
    name: 'shift',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.SquareRoot]: {
    name: 'sqrt',
    supportedArgTypes: [ValueType.scalar, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.StandardDeviation]: {
    name: 'stdev',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.RollingStandardDeviation]: {
    name: 'r_stdev',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.Sum]: {
    name: 'sum',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.scalar,
  },
  [Terms.OverlappingPercentChange]: {
    name: 'o_rdelta',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
  [Terms.PercentChange]: {
    name: 'rdelta',
    supportedArgTypes: [ValueType.vector, ValueType.scalar],
    variadic: true,
    minArgs: 1,
    returnType: ValueType.vector,
  },
}

export function getFunction(id: number): BSLangFunction {
  return bsLangFunctions[id]
}

const UnaryOpMapping = {
  '-': 'Negative',
  '+': 'Positive',
}
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
export const parseTreeToAst = (
  tree: ParseTreeRoot,
  parsedQueries: Record<string, Query>,
): AST => {
  if (!tree.program.children.length) {
    return {}
  }
  function walkTree(current: ParseTreeNode, output: Record<string, AST>) {
    // Expr nodes should always have one child, so go directly to it
    if (current.type === 'Expr' && current.children.length) {
      current = current.children[0]
    }

    switch (current.type) {
      case 'FunctionCall': {
        output.type = current.type
        // FunctionIdentifier is first child
        output.name = current.children[0]?.children[0]?.type
        // FunctionCallBody is next child and has the arg Exprs
        output.args = current.children[1].children.map((c) => walkTree(c, {}))
        break
      }
      case 'BinaryExpression': {
        // BinaryOperator always has operator as second child
        // We map to FunctionCall format for consistency
        output.type = 'FunctionCall'
        const [arg1, operator, ...rest] = current.children
        output.name = operator.type
        // Using the same name for the binary operator + and function sum()
        // causes grammar errors in Lezer, so manually rewrite here
        if (output.name === 'Add') {
          output.name = 'Sum'
        }
        if (output.name === 'Mul') {
          output.name = 'Multiply'
        }
        output.args = [arg1, ...rest].map((c) => walkTree(c, {}))
        break
      }
      case 'UnaryExpression': {
        // UnaryExpression always has operator as first child
        output.type = current.type
        const [operator, ...rest] = current.children
        if (operator.content) {
          output.name = UnaryOpMapping[operator.content]
        }
        output.args = rest.map((c) => walkTree(c, {}))
        break
      }
      case 'Identifier': {
        output.type = current.type
        if (current.content) {
          const subQuery = parsedQueries[current.content]
          if (subQuery) {
            output.type = 'SubExpression'
            output.args = [parseTreeToAst(subQuery.tree, parsedQueries)]
            output.name = current.content
            output.id = subQuery.id
          } else {
            output.type = current.type
            output.name = current.content
          }
        }
        break
      }
      default: {
        output.type = current.type
        if (current.content) {
          output.name = current.content
        }
        if (current.children.length) {
          output.args = current.children.map((c) => walkTree(c, {}))
        }
      }
    }
    return output
  }
  return walkTree(tree.program.children[0], {})
}
