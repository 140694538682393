import { ChangeTicker } from 'components/molecules/TickerChange'
import { ChangeSource } from 'components/molecules/SourceChange'
import { ChangeCurrency } from 'components/molecules/CurrencyChange'
import { ChangeSeries } from 'components/molecules/SeriesChange'
import { Source, Currency, Ticker, PriceType, Series } from 'types'
import { classNames } from '@progress/kendo-react-common'
import { ChangePriceType } from 'components/molecules/PriceTypeChange'

interface IDataChooserProps {
  source?: Source
  handleSourceChange?: (source: Source) => void
  supportedSources?: Array<Source>
  currency?: Currency
  handleCurrencyChange?: (currency: Currency) => void
  supportedCurrencies?: Array<Currency>
  ticker?: Ticker
  handleTickerChange?: (ticker: Ticker) => void
  supportedTickers?: Array<Ticker>
  rootClassNames?: any
  priceType?: PriceType
  handlePriceTypeChange?: (priceType: PriceType) => void
  supportedPriceTypes?: Array<PriceType>
  series?: Series
  handleSeriesChange?: (series: Series) => void
  supportedSeries?: Array<Series>
}

// TODO: give this a less generic, more accurate name
const DataChooser: React.FC<IDataChooserProps> = (props) => {
  const {
    source,
    handleSourceChange,
    supportedSources,
    currency,
    handleCurrencyChange,
    supportedCurrencies,
    ticker,
    handleTickerChange,
    supportedTickers,
    rootClassNames,
    priceType,
    handlePriceTypeChange,
    supportedPriceTypes,
    series,
    handleSeriesChange,
    supportedSeries,
  } = props

  return (
    <div className={classNames('k-d-inline-flex', rootClassNames)}>
      {source && handleSourceChange && (
        <ChangeSource
          className="k-mr-4"
          selected={source}
          supportedSources={supportedSources}
          handleSelection={handleSourceChange}
        />
      )}
      {currency && handleCurrencyChange && (
        <ChangeCurrency
          className="k-mr-4"
          selected={currency}
          supportedCurrencies={supportedCurrencies}
          handleSelection={handleCurrencyChange}
        />
      )}
      {ticker && handleTickerChange && (
        <ChangeTicker
          className="k-mr-4"
          selected={ticker}
          supportedTickers={supportedTickers}
          handleSelection={handleTickerChange}
        />
      )}
      {series && handleSeriesChange && (
        <ChangeSeries
          className="k-mr-4"
          selected={series}
          supportedSeries={supportedSeries}
          handleSelection={handleSeriesChange}
        />
      )}
      {priceType && handlePriceTypeChange && (
        <ChangePriceType
          className="k-mr-4"
          selected={priceType}
          supportedPriceTypes={supportedPriceTypes}
          handleSelection={handlePriceTypeChange}
        />
      )}
    </div>
  )
}

export default DataChooser
