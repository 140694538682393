import React, { ReactElement, ReactNode } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'

import LandingPage from 'pages/LandingPage'
import OptionPricer from 'pages/OptionPricer/OptionPricer'
import SpotMonitor from 'pages/SpotMonitor'
import FutureMonitor from 'pages/FutureMonitor'
import HistoricalAnalyzer from 'pages/HistoricalAnalyzer/HistoricalAnalyzer'
import MenuNavContainer from 'navigation/MenuNavContainer'
import { HistoricalAnalyzerProvider } from 'context/HistoricalAnalyzerContext'
import { CatalogProvider } from 'context/CatalogContext'

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const StyledAuthenticator = styled(Authenticator)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & .amplify-button[data-variation='primary'] {
    background: ${(props) => props.theme.palette.common.series1};
  }
`

const loginComponents = {
  ForceNewPassword: {
    FormFields() {
      return (
        <>
          <p>
            Passwords must be at 10 characters long and require numbers,
            uppercase letters and lowercase letters
          </p>
          <Authenticator.ForceNewPassword.FormFields />
        </>
      )
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <p>
          Passwords must be at 10 characters long and require numbers, uppercase
          letters and lowercase letters
        </p>
      )
    },
  },
}

const Login = () => (
  <StyledAuthenticator hideSignUp components={loginComponents} />
)

const RequireAuth: React.FC = () => {
  const { route } = useAuthenticator((context) => [context.route])

  if (route !== 'authenticated') {
    return <Login />
  }
  return <Outlet />
}

const Main = () => {
  return (
    <Routes>
      <Route element={<RequireAuth />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/option-pricer" element={<OptionPricer />} />
        <Route path="/spot-monitor" element={<SpotMonitor />} />
        <Route path="/future-monitor" element={<FutureMonitor />} />
        <Route path="/historical-analyzer" element={<HistoricalAnalyzer />} />
      </Route>
    </Routes>
  )
}

const App: React.FC = () => {
  return (
    <div id="app" className="scroll-fix">
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <HistoricalAnalyzerProvider>
            <CatalogProvider>
              <BrowserRouter>
                <MenuNavContainer />
                <main>
                  <Main />
                </main>
              </BrowserRouter>
            </CatalogProvider>
          </HistoricalAnalyzerProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
