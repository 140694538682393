import React, { useState } from 'react'
import { Label } from '@progress/kendo-react-labels'
import { classNames } from '@progress/kendo-react-common'

export interface StatNumberProps {
  className?: string
  labelClassName?: string
  label: string
  currency?: string
  currencyPosition?: 'front' | 'back'
  hasCurrency?: boolean
  labelWidth?: number
  value: number
}

export const StatNumber = ({
  className,
  label,
  currency = '$',
  hasCurrency = true,
  currencyPosition,
  labelClassName = 'col-2',
  labelWidth,
  value,
}: StatNumberProps): React.ReactElement => {
  return (
    <div className={classNames([className, 'k-mb-4 k-d-inline-flex'])}>
      <Label className={classNames(labelClassName)}>{label}</Label>
      <span className="number">
        <strong>
          {hasCurrency && currencyPosition === 'front' && currency}
          {new Intl.NumberFormat().format(value)}
          {hasCurrency && currencyPosition === 'back' && currency}
        </strong>
      </span>
    </div>
  )
}
