import { useMemo } from 'react'
import Download, { useSave } from 'hooks/useSave'
import {
  Chart,
  ChartLegend,
  ChartLegendItem,
  ChartPane,
  ChartPanes,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
  LegendItemClickEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import {
  ModelParametersSeries,
  MultiModelParametersSeries,
  MultiTermStructure,
  PriceType,
} from 'types'

import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { MAX_TERM_DAYS } from 'consts'
import 'hammerjs'
import { capitalize } from 'lodash'
import { dateFormatter } from 'utils/date-formatter'
import { customLabel } from './customLabel'

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      {point.dataItem.instrument && (
        <tr>
          <td>{point.dataItem.instrument}</td>
        </tr>
      )}
      {point.dataItem.timestamp && (
        <tr>
          <td>{formatDate(point.dataItem.timestamp, 'd MMM yy H:mm')}</td>
        </tr>
      )}
      <tr>
        <td>{formatNumber(point.value.y, 'p2')}</td>
      </tr>
    </table>
  )
}

type ValidTermType = MultiTermStructure | MultiModelParametersSeries

interface ITermStructureChartProps<T extends ValidTermType> {
  dataReader: () =>
    | MultiTermStructure
    | Record<string, ModelParametersSeries>
    | undefined
  selectedField: string
  onLegendItemClick: (index: number) => void
  xAxisTitle?: string
  yAxisTitle?: () => string
  title?: string
}

const TermStructureChart = <T extends ValidTermType>(
  props: ITermStructureChartProps<T>,
) => {
  const {
    dataReader,
    selectedField,
    onLegendItemClick,
    xAxisTitle,
    yAxisTitle,
    title = 'Term structure',
  } = props
  const theme = useTheme()
  const { chartRef, handleSave } = useSave()
  const data = dataReader()

  const labelFormat = [
    'yield',
    'sviA',
    'sviB',
    'sviRho',
    'sviM',
    'sviSigma',
    'sviVol',
    'sabrRho',
    'sabrAlpha',
    'sabrVolVol',
    'sabrVol',
  ].includes(selectedField)
    ? 'p2'
    : '{0:c2}'

  const precisionArray = [
    { key: -0.0001, precision: -0.0005 },
    { key: -0.001, precision: -0.005 },
    { key: -0.01, precision: -0.05 },
    { key: -0.1, precision: -0.5 },
    { key: 0.00099999, precision: 0.000005 },
    { key: 0.0099999, precision: 0.00005 },
    { key: 0.099999, precision: 0.0005 },
    { key: 0.99999, precision: 0.005 },
    { key: 1, precision: 0.05 },
  ]

  // FIXME: extract to hook
  const minYValue = useMemo(() => {
    if (!data) {
      return 0
    }
    let min = Infinity
    Object.values(data).forEach((seriesData) => {
      const tmpMin = Math.min(...seriesData.map((d) => d[selectedField]))
      min = tmpMin < min ? tmpMin : min
    })
    if (selectedField === PriceType.YIELD) {
      min = Math.min(min, 100)
    }
    min = Number.isNaN(min) ? 0 : min

    const precisionValue = precisionArray.filter((v) => v.key > min)
    if (precisionValue && precisionValue.length > 0) {
      if (min > 0) {
        min =
          Math.floor(min / precisionValue[0].precision) *
          precisionValue[0].precision
      } else {
        min =
          Math.ceil(min / precisionValue[0].precision) *
          precisionValue[0].precision
      }
    }
    return min
  }, [data, selectedField])

  const maxDateOffset = useMemo(() => {
    if (!data) {
      return MAX_TERM_DAYS
    }
    let max = 0
    Object.values(data).forEach((seriesData) => {
      max = Math.max(max, ...seriesData.map((d) => d.dateOffset))
    })
    return Number.isNaN(max) ? MAX_TERM_DAYS : max
  }, [data])

  const plotBand = useMemo(() => {
    if (minYValue === 0) {
      return []
    }
    return [
      {
        from: -0.0005,
        to: 0.0005,
        color: 'white',
        opacity: 0.3,
      },
    ]
  }, [minYValue])

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }
  const calcMinValue = (minYVal: number) => {
    if (selectedField === PriceType.YIELD) {
      return `${(minYVal * 100).toFixed(2)}%`
    }
    if (selectedField === PriceType.PRICE) {
      return `$${(minYVal * 100).toFixed(2)}`
    }
    return `${(minYVal * 100).toFixed(2)}`
  }

  return (
    <>
      {!data ? (
        <p>No series data available</p>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              position: 'relative',
              top: '60px',
              left: '-30px',
              paddingLeft: '8rem',
              zIndex: 1,
              height: 0,
            }}
          >
            {Object.keys(data).length > 0 && (
              <>
                <span>
                  minimum {selectedField}: {calcMinValue(minYValue)}
                </span>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
                    Shift + scroll to zoom
                  </p>
                  <Download handleClick={() => handleSave(title)} />
                </div>
              </>
            )}
          </div>
          <Chart
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            pannable
            zoomable={{ mousewheel: { rate: 0.1, lock: 'y' } }}
            onZoomStart={handleZoomStart}
            transitions={false}
            onLegendItemClick={(e: LegendItemClickEvent) => {
              onLegendItemClick(e.series.index)
            }}
            ref={chartRef}
          >
            <ChartLegend
              margin={20}
              width={500}
              position="top"
              align="end"
              orientation="horizontal"
            >
              <ChartLegendItem visual={customLabel} />
            </ChartLegend>
            <ChartPanes>
              <ChartPane name="main" clip={false} />
            </ChartPanes>
            <ChartTooltip />
            <ChartYAxis>
              <ChartYAxisItem
                pane="main"
                name="YAxis"
                labels={{ format: labelFormat, font: '0.75em "OpenSans"' }}
                min={minYValue}
                axisCrossingValue={minYValue}
                title={{ text: yAxisTitle?.() || capitalize(selectedField) }}
                plotBands={plotBand}
                crosshair={{
                  visible: true,
                  tooltip: {
                    visible: true,
                    format: labelFormat,
                    background: theme.palette.common.kendoGrey,
                  },
                }}
              />
            </ChartYAxis>
            <ChartSeries>
              {Object.entries(data).map(([seriesLabel, seriesData]) => (
                <ChartSeriesItem
                  key={seriesLabel}
                  type="scatterLine"
                  style="smooth"
                  name={dateFormatter.format(new Date(seriesLabel))}
                  width={2}
                  yField={selectedField}
                  xAxis="dateOffset"
                  xField="dateOffset"
                  data={seriesData}
                >
                  <ChartSeriesItemTooltip
                    render={tooltipRenderer}
                    color="white"
                    background={theme.palette.common.kendoGrey}
                  />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartXAxis>
              <ChartXAxisItem
                name="dateOffset"
                title={{ text: xAxisTitle }}
                min={0}
                max={maxDateOffset + 1}
                labels={{
                  visible: true,
                }}
                crosshair={{
                  visible: true,
                }}
              />
            </ChartXAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default TermStructureChart
