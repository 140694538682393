import {
  Chart,
  ChartLegend,
  ChartPane,
  ChartPanes,
  ChartSeries,
  ChartSeriesItem,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { HistoricalAnalyzerRegressionData } from 'types'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import Download, { useSave } from 'hooks/useSave'

import { AxisNames } from './types'

import 'hammerjs'
import Query from './query'

interface IHistoricalAnalyzerScatterChartProps {
  data: Array<[Query, HistoricalAnalyzerRegressionData]>
  axisNames: AxisNames
  title?: string
}

const HistoricalAnalyzerScatterChart: React.FC<IHistoricalAnalyzerScatterChartProps> =
  ({ data, axisNames, title }) => {
    const theme = useTheme()
    const { handleSave, chartRef } = useSave()

    const ranges = useMemo(() => {
      const xs = data[0][1].data.scatter.map((el) => el.x)
      const xsLine = data[0][1].data.line.map((el) => el.x)
      const ys = data[0][1].data.scatter.map((el) => el.y)
      const ysLine = data[0][1].data.line.map((el) => el.y)
      return {
        minX: Math.min(...xs, ...xsLine),
        maxX: Math.max(...xs, ...xsLine),
        minY: Math.min(...ys, ...ysLine),
        maxY: Math.max(...ys, ...ysLine),
      }
    }, [data])

    const plotBand = useMemo(() => {
      if (ranges.minY === 0) {
        return []
      }
      const bandWidth = ranges.maxY - ranges.minY
      return [
        {
          from: 0 - bandWidth / 400,
          to: 0 + bandWidth / 400,
          color: 'white',
          opacity: 0.3,
        },
      ]
    }, [ranges])
    const lastPoint = useMemo(() => {
      const xs = data[0][1].data.scatter
      return xs[xs.length - 1]
    }, [data])

    const formula = useMemo(() => {
      const { alpha, beta } = data[0][1].data
      if (alpha < 0) {
        return `y = ${beta.toFixed(2)}x - ${Math.abs(alpha).toFixed(2)}`
      }
      return `y = ${beta.toFixed(2)}x + ${alpha.toFixed(2)}`
    }, [data])
    return (
      <>
        <Chart
          renderAs="svg"
          style={{
            width: 1200,
            height: 400,
            overscrollBehavior: 'contain',
          }}
          transitions={false}
          ref={chartRef}
        >
          <ChartPanes>
            <ChartPane name="main" clip={false} />
          </ChartPanes>
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartYAxis>
            <ChartYAxisItem
              name="left"
              pane="main"
              min={ranges.minY}
              max={ranges.maxY}
              title={{ text: axisNames.left }}
              axisCrossingValue={ranges.minY}
              plotBands={plotBand}
              labels={{ format: 'n2', font: '0.75em "OpenSans"' }}
            />
          </ChartYAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="scatter"
              xField="x"
              yField="y"
              yAxis="left"
              xAxis="number"
              data={data[0][1].data.scatter}
            />
            <ChartSeriesItem
              type="scatterLine"
              xField="x"
              yField="y"
              yAxis="left"
              xAxis="number"
              data={data[0][1].data.line}
              width={4}
              markers={{ visible: false }}
              name={formula}
            />
            <ChartSeriesItem
              type="scatter"
              xField="x"
              yField="y"
              yAxis="left"
              xAxis="number"
              data={[lastPoint]}
              zIndex={100}
              markers={{
                type: 'triangle',
                background: theme.palette.common.red,
                border: { color: theme.palette.common.red },
                size: 20,
              }}
            />
          </ChartSeries>
          <ChartXAxis>
            <ChartXAxisItem
              name="number"
              pane="main"
              min={ranges.minX}
              max={ranges.maxX}
              axisCrossingValue={ranges.minX}
              labels={{
                rotation: 'auto',
                font: '0.75em "OpenSans"',
                format: 'n2',
              }}
            />
          </ChartXAxis>
        </Chart>
        {data.length > 0 && (
          <Download handleClick={() => handleSave(title || 'Scatter')} />
        )}
      </>
    )
  }

export default HistoricalAnalyzerScatterChart
