import { flatten } from 'lodash'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dataService } from 'services/lambdaDataService'

import { BSLANG } from '@blockscholes/ql'
import { EditorState } from '@codemirror/state'
import { Loader } from '@progress/kendo-react-indicators'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import {
  getCssColorValueForHeatmapCell,
  HeatMapLegend,
} from 'components/common/HeatMapLegend'
import {
  HEATMAP_NEGATIVE_COLOR,
  HEATMAP_POSITIVE_COLOR,
  HEATMAP_ZERO_COLOR,
} from 'consts'
import { HistoricalAnalyzerContext } from 'context/HistoricalAnalyzerContext'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import Query from 'pages/HistoricalAnalyzer/query'
import { FutureHeatMapDataPoint, Source, Ticker } from 'types'
import { getFuturesHeatmapData } from 'services/futuresHeatmap'
import { formatNumber } from '@telerik/kendo-intl'
import FutureHeatMapChart from './FuturesHeatMapChart'
import ChartLoader from './ChartLoader'

const labelForQueryNum = (queryNum: number): string =>
  String.fromCharCode(queryNum + 65)

const FuturesHeatMapChart: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [series, setSeries] = useState<Array<FutureHeatMapDataPoint>>([])
  const [categories, setCategories] = useState<Array<string>>([])
  const [isLoading, setIsLoading] = useState(false)

  const haContext = useContext(HistoricalAnalyzerContext)
  const { createQuery, state } = haContext
  const navigate = useNavigate()

  const handleCellClick = (item: FutureHeatMapDataPoint) => {
    if (!item.timeseries) {
      return
    }
    const editorState = EditorState.create({
      doc: item.timeseries,
      extensions: [BSLANG()],
    })
    const parser = new Parser(editorState, state.sources)
    // todo: error handling
    const query = new Query(
      item.timeseries,
      labelForQueryNum(state.queryCount),
      parser.parseTree,
    )

    createQuery(query, parser)
    navigate('/historical-analyzer')
  }

  useEffect(() => {
    setIsLoading(true)
    getFuturesHeatmapData(source, ticker)
      .then((results) => {
        const instruments = results.map((r) => r.instrument)
        setCategories(instruments)
        const seriesData = results.map((result) => {
          if (!result.cells) {
            return {
              xInstrument: result.instrument,
              yInstrument: result.instrument,
              value: 0,
              timeseries: '',
            }
          }
          return result.cells.map((cell, i) => {
            return {
              xInstrument: result.instrument,
              yInstrument: instruments[i],
              value: cell,
              timeseries: result.timeseries,
            }
          })
        })
        setSeries(flatten(seriesData))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [source, ticker])

  const values = series.map((item) => item.value)
  const min = Math.min(...values)
  const max = Math.max(...values)

  const colorCell = useCallback(
    (cell) => getCssColorValueForHeatmapCell(cell.value, min, max),
    [min, max],
  )
  return (
    <ChartWrapper
      title="Spreads Heatmap"
      legend={
        isLoading ? undefined : (
          <HeatMapLegend
            valueColorList={[
              { value: formatNumber(min, 'n2'), color: HEATMAP_NEGATIVE_COLOR },
              { value: 0, color: HEATMAP_ZERO_COLOR },
              { value: formatNumber(max, 'n2'), color: HEATMAP_POSITIVE_COLOR },
            ]}
            title="Spread"
          />
        )
      }
      controls={
        <DataChooser
          source={source}
          handleSourceChange={setSource}
          supportedSources={[Source.DERIBIT]}
          ticker={ticker}
          handleTickerChange={setTicker}
          supportedTickers={[Ticker.BTC, Ticker.ETH]}
          rootClassNames="k-mr-4"
        />
      }
      chart={
        <>
          {isLoading ? (
            <ChartLoader />
          ) : (
            <FutureHeatMapChart
              data={series}
              color={colorCell}
              categories={categories}
              onCellClick={handleCellClick}
            />
          )}
        </>
      }
    />
  )
}

export default FuturesHeatMapChart
