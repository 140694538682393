import { TreeNode } from 'pages/HistoricalAnalyzer/types'
import React, { useState, useEffect, createContext } from 'react'
import { constructCatalog } from 'services/constructCatalog'
import { dataService, CatalogItem } from 'services/lambdaDataService'

export const CatalogContext = createContext<{
  fetching: boolean
  data: Array<CatalogItem>
  obj?: TreeNode[]
}>({ fetching: false, data: [] })

export const CatalogProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Array<CatalogItem>>([])
  const [obj, setObj] = useState<TreeNode[]>()
  const [fetching, setFetching] = useState(false)

  const getCatalogData = async () => {
    setFetching(true)
    const catalogData = await dataService.getCatalogData()
    setData(catalogData)
    setFetching(false)
  }
  const getExpiredData = async () => {
    const expired = await dataService.getExpiredCatalogOptionData()
    setData((prev) => [...prev, ...expired])
  }
  useEffect(() => {
    getCatalogData().finally(() => getExpiredData())
  }, [])

  useEffect(() => {
    if (data) setObj(constructCatalog(data))
  }, [data])

  return (
    <CatalogContext.Provider value={{ fetching, data, obj }}>
      {children}
    </CatalogContext.Provider>
  )
}
