import { add, sub } from 'date-fns'
import { OptionType, Ticker } from 'types'

export const MAX_TERM_DAYS = 365

export const ACTIVE_TICKERS = [Ticker.BTC, Ticker.ETH]

export const API_ROOT_URL: string =
  process.env.REACT_APP_BLOCKSCHOLES_API_ENDPOINT || ''
if (!API_ROOT_URL) {
  throw new Error(
    'API endpoint is not defined in environment at REACT_APP_BLOCKSCHOLES_API_ENDPOINT',
  )
}

export const TenorDays: Record<string, number> = {
  '1W': 7,
  '1M': 30,
  '3M': 90,
  '6M': 180,
  '1Y': 365,
}

export const DaysTenor: Record<number, string> = {
  7: '1W',
  30: '1M',
  90: '3M',
  120: '4M',
  180: '6M',
  270: '9M',
  365: '1Y',
  730: '2Y',
}
export const START_OF_BLOCKSCHOLES_TIME = new Date(Date.UTC(2020, 0))
export const TEMP_DATA_END = new Date(Date.UTC(2022, 3, 10))
export const TODAY_LOCAL = new Date()
export const TODAY_UTC = new Date(
  Date.UTC(
    TODAY_LOCAL.getUTCFullYear(),
    TODAY_LOCAL.getUTCMonth(),
    TODAY_LOCAL.getUTCDate(),
    TODAY_LOCAL.getUTCHours(),
    TODAY_LOCAL.getUTCMinutes(),
  ),
)
export const SIX_MONTHS_AGO = sub(TODAY_UTC, { months: 6 })
export const SEVEN_DAYS_AHEAD = add(TODAY_UTC, { days: 8 })
export const TWO_YEARS_AHEAD = add(TODAY_UTC, { years: 2 })

export const SMILE_CATEGORIES = [
  '-0.01',
  '-0.05',
  '-0.1',
  '-0.2',
  '-0.25',
  '-0.4',
  '0.5',
  '0.4',
  '0.25',
  '0.2',
  '0.1',
  '0.05',
  '0.01',
]

export const OptionTypePricerMapping: Record<OptionType, string> = {
  [OptionType.PUT]: 'P',
  [OptionType.CALL]: 'C',
}

export const DATE_FORMAT = 'dd MMM yy'
export const DATETIME_FORMAT = `${DATE_FORMAT}, HH:00`
export const DATETIME_FORMAT_WITH_MINUTES = `${DATE_FORMAT}, HH:mm`

// TODO: move colors into own file and migrate everything to use RgbColor type and helpers
// Experiment to use TypeScript 4.5 features to enforce type safety around a number range
type Range<T extends number> = number extends T ? number : _Range<T, []>
type _Range<T extends number, R extends unknown[]> = R['length'] extends T
  ? R[number]
  : _Range<T, [R['length'], ...R]>
type RgbColorValue = Range<256>

export type RgbColor = {
  red: RgbColorValue
  green: RgbColorValue
  blue: RgbColorValue
}

export const getCssRgbStringFromColor = (color: RgbColor): string => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `rgb(${color.red}, ${color.green}, ${color.blue})`
}

export const HEATMAP_NEGATIVE_COLOR: RgbColor = {
  red: 45,
  green: 115,
  blue: 245,
} as const

export const HEATMAP_ZERO_COLOR: RgbColor = {
  red: 120,
  green: 210,
  blue: 55,
} as const

export const HEATMAP_POSITIVE_COLOR: RgbColor = {
  red: 255,
  green: 99,
  blue: 88,
} as const

export const HEATMAP_COLORS = {
  R_MIN: HEATMAP_NEGATIVE_COLOR.red,
  G_MIN: HEATMAP_NEGATIVE_COLOR.green,
  B_MIN: HEATMAP_NEGATIVE_COLOR.blue,
  R_MID: HEATMAP_ZERO_COLOR.red,
  G_MID: HEATMAP_ZERO_COLOR.green,
  B_MID: HEATMAP_ZERO_COLOR.blue,
  R_MAX: HEATMAP_POSITIVE_COLOR.red,
  G_MAX: HEATMAP_POSITIVE_COLOR.green,
  B_MAX: HEATMAP_POSITIVE_COLOR.blue,
}

export const SERIES_COLORS = [
  'rgb(51, 146, 255)',
  'rgb(159, 172, 187)',
  'rgb(255, 205, 0)',
  'rgb(0, 255, 162)',
  'rgb(255, 97, 0)',
  'rgb(255, 165, 192)',
]

export const getSeriesColorFromIndex = (i: number): string => {
  const seriesColorIndex = i % SERIES_COLORS.length
  return SERIES_COLORS[seriesColorIndex]
}
