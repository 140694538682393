import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { FutureHeatMapDataPoint } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'

interface IHeatMapChartProps {
  data: Array<FutureHeatMapDataPoint>
  color: (dataItem: FutureHeatMapDataPoint) => string
  categories: Array<string>
  onCellClick: (dataItem: FutureHeatMapDataPoint) => void
}
const FutureHeatMapChart: React.FC<IHeatMapChartProps> = (
  props: IHeatMapChartProps,
) => {
  const { data, color, categories, onCellClick } = props
  return (
    <>
      {!data.length ? (
        <p>No series data available</p>
      ) : (
        <>
          <Chart
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
              cursor: 'pointer',
            }}
            transitions={false}
            onSeriesClick={(e) => {
              onCellClick(e.dataItem)
            }}
          >
            <ChartSeries>
              <ChartSeriesItem
                type="heatmap"
                xField="xInstrument"
                yField="yInstrument"
                field="value"
                data={data}
                color={(e) => color(e.dataItem)}
              >
                <ChartSeriesLabels
                  content={({ value }) => formatNumber(value, 'n2')}
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartYAxis>
              <ChartYAxisItem categories={categories} />
            </ChartYAxis>
            <ChartXAxis>
              <ChartXAxisItem
                categories={categories}
                labels={{ content: ({ value }) => value, mirror: false }}
              />
            </ChartXAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default FutureHeatMapChart
