import { v4 as uuidv4 } from 'uuid'
import { ParseTreeRoot } from './types'

const colorPalette = [
  "#FBCF33",
  "#FF3D00",
  "#247CFF",
  "#1EB511",
  "#DB00FF",
  "#FF7A00",
  "#FF0099",
  "#893DFF",
  "#C5C5C5",
  "#FDE799",
  "#FF9E80",
  "#92BEFF",
  "#8EDA88",
  "#ED80FF",
  "#FFBD80",
  "#FF80CC",
  "#C49EFF",
  "#E2E2E2",
];
class Query {
  public id: string
  public expression: string
  public label: string
  public name: string | undefined
  public tree: ParseTreeRoot
  public isActive: boolean
  public axis: 'left' | 'right'
  public inEdit: boolean
  public histSeriesColor : string

  constructor(
    expression: string,
    label: string,
    tree: ParseTreeRoot,
    id = uuidv4(),

  ) {
    this.id = id
    this.expression = expression
    this.label = label
    this.tree = tree
    this.isActive = true
    this.inEdit = false
    this.axis = 'left'
    this.histSeriesColor = colorPalette[Math.floor(Math.random() * colorPalette.length)]

  }

  public isSameAs(other: Query): boolean {
    return this.id === other.id
  }

  public get userFacingIdent(): string {
    return this.name || this.label
  }

  public clone(newParams: Partial<Query>): Query {
    const cloned = new Query(this.expression, this.label, this.tree, this.id)
    Object.assign(cloned, this, newParams)
    return cloned
  }
  public toggleActive(): Query {
    return this.clone({ isActive: !this.isActive })
  }
  public AllowSeriesColorPicker(): Query {
    return this.clone({ histSeriesColor: this.histSeriesColor })
  }
}

export default Query
