import { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridNoRecords,
} from '@progress/kendo-react-grid'
import { Checkbox } from '@progress/kendo-react-inputs'
import { Dropdown } from 'components/atoms'
import { Dialog } from '@progress/kendo-react-dialogs'
import Query from './query'
import { IHistoricalAnalyzerDataSource } from './types'
import {
  HistoricalAnalyzerCommandCell,
  HistoricalAnalyzerTextCell,
} from './renderers'
import { Parser } from './parser'
import CodeMirrorComponent from './CodeMirrorComponent'
import ColorPickerForCharts from 'components/common/ColorPickerForCharts'
import checkboxTick from '../../images/checkbox-tick.svg'
import HistoricalAnalyzerExpressionTextCell from './HistoricalAnalyzerExpressionTextCell'

const StyledExpressionInput = styled.div`
  color: ${({ theme }) => theme.palette.common.grey1};
  display: inline-flex;
  align-items: baseline;
  height: 80px;
`

const StyledGrid = styled(Grid)`
width: 1226px;
table {
  width: 100% !important;
}
thead {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid white;
}
.k-grid .k-alt {
  background-color: rgba(255, 255, 255, 0.04);
}
thead, tbody, tfoot, tr, td, th {
  border: none;
  height: 52px;
}
  border: none;
  & .k-link {
    cursor: default;
  }
  .k-grid-header .k-header {
    vertical-align: middle;
  }
  .k-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
  }
  .k-checkbox:checked {
    background-color: #247CFF;
    border: none;
    background-image: url(${checkboxTick});
    background-size: 12px 12px;
  }
`

interface IConfigurationDialogProps {
  query: Query
  handleClose: () => void
  handleAxisChange: (query: Query, newAxis: 'left' | 'right') => void
}

const AXIS_OPTIONS = [
  { name: 'Left', value: 'left' },
  { name: 'Right', value: 'right' },
]
const ConfigurationDialog: React.FC<IConfigurationDialogProps> = (props) => {
  const { query, handleAxisChange, handleClose } = props
  const [localState, setLocalState] = useState(query.axis)
  return (
    <Dialog title="Configuration" onClose={handleClose}>
      <>
        <p>{`id: ${query.id}`}</p>
        <p>{`expression: ${query.expression}`}</p>
        <Dropdown
          showLabel
          current={AXIS_OPTIONS.find(({ value }) => value === localState)}
          options={AXIS_OPTIONS}
          onChange={(newChoice: 'left' | 'right') => {
            setLocalState(newChoice)
            handleAxisChange(query, newChoice)
          }}
          label="Axis"
        />
      </>
    </Dialog>
  )
}

const StyledLabelButton = styled.button`
  border: none;
  background-color: inherit;
  color: ${({ theme }) => theme.palette.common.white};
`

// to enable deletion we need a way to determine and delete all dependent expressions
interface IQueryWrapperProps {
  queries: Array<Query>
  dataSources: Array<IHistoricalAnalyzerDataSource>
  handleQueryCreate: (
    expression: string | undefined | null,
    parsed: Parser | null,
  ) => void
  handleQueryEdit: (query: Query) => void
  handleQueryDelete: (query: Query) => void
  updateChart: (value: boolean) => void
}

const QueryWrapper: React.FC<IQueryWrapperProps> = (props) => {
  let {
    queries,
    dataSources,
    handleQueryCreate,
    handleQueryDelete,
    handleQueryEdit,
    updateChart
  } = props

  const [modalQuery, setModalQuery] = useState<Query | null>(null)
  const [queryEditState, setQueryEditState] = useState<Array<Query>>(queries)

  useEffect(() => {
    setQueryEditState(queries)
  }, [queries])

  const enterEdit = (dataItem: Query) => {
    const newQueryEditState = queryEditState.map((query) =>

      query.clone({

        inEdit: query.id === dataItem.id,
      }),
    )
    setQueryEditState(newQueryEditState)
  }
  const exitEdit = (dataItem: Query) => {
    const newQueryEditState = queryEditState.map((query) =>
      query.clone({
        inEdit: false,
      }),
    )
    setQueryEditState(newQueryEditState)
    handleQueryEdit(dataItem.clone({ inEdit: false }))
  }
  const performChange = (newQuery: Query) => {
    const newQueryEditState = queryEditState.map((item) => {
      if (item.id !== newQuery.id) {
        return item
      }
      return newQuery
    })
    setQueryEditState(newQueryEditState)
  }
  // Don't think this is ever actually used as Kendo's grid editing insists on re-rendering
  // the whole row rather than just the cell
  const handleItemChange = (event: GridItemChangeEvent) => {
    if (!event.field) {
      return
    }
    performChange(event.dataItem.clone({ [event.field]: event.value }))
  }
  const handleQueryAxisChange = (query: Query, newAxis: 'left' | 'right') => {
    if (query.axis === newAxis) {
      return
    }
    handleQueryEdit(query.clone({ axis: newAxis }))
  }
  const CommandCell = (cellProps: GridCellProps) => (
    <HistoricalAnalyzerCommandCell
      {...cellProps}
      edit={enterEdit}
      remove={handleQueryDelete}
      editField="inEdit"
      discardChanges={exitEdit}
      queryEditState={queryEditState}
    />
  )

  const changeColor = (cellProps, data) => {
    handleQueryEdit(
      cellProps.dataItem.clone({
        histSeriesColor: data
      }),
    )
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 800 }}>
      {modalQuery !== null && (
        <ConfigurationDialog
          query={modalQuery}
          handleClose={() => setModalQuery(null)}
          handleAxisChange={handleQueryAxisChange}
        />
      )}
      <StyledGrid
        data={queryEditState}
        scrollable="none"
        sortable={false}
        dataItemKey="id"
        editField="inEdit"
        onItemChange={handleItemChange}
      >
        <GridColumn
          title="Show"
          width="60px"
          cell={(cellProps: GridCellProps) => (
            <td>
              <Checkbox
                value={cellProps.dataItem.isActive}
                onChange={() =>
                  handleQueryEdit(cellProps.dataItem.toggleActive())
                }
              />
            </td>
          )}
          field="isActive"
        />
        <GridColumn
          title="ID"
          width="50px"
          editable={false}
          cell={(cellProps: GridCellProps) => (
            <td>
              <StyledLabelButton
                type="button"
                onClick={() => setModalQuery(cellProps.dataItem)}
              >
                {cellProps.dataItem.label}
              </StyledLabelButton>
            </td>
          )}
        />
        <GridColumn
          field="name"
          title="Name"
          width="200px"
          cell={(cellProps: GridCellProps) => (
            <HistoricalAnalyzerTextCell
              value={cellProps.dataItem.name}
              inEdit={cellProps.dataItem.inEdit}
              handleSubmit={(name: string) => {
                exitEdit(cellProps.dataItem.clone({ name }))
              }}
            />
          )}
        />
        <GridColumn
          field="expression"
          title="Expression"
          width="370px"
          cell={(cellProps: GridCellProps) => (<HistoricalAnalyzerExpressionTextCell
              value={cellProps.dataItem.expression}
              dataSources={dataSources}
              handleChange={(expression, parser) => {
                handleQueryEdit(
                  cellProps.dataItem.clone({
                    expression,
                    tree: parser.parseTree,
                    inEdit: false,
                  }),
                )
              }}
            />)
          }
        />
        <GridColumn
          field="colorPickr"
          title="Color"
          width="150px"
          cell={(cellProps: GridCellProps) => (
            <td>
              <ColorPickerForCharts
                defaultValue={cellProps.dataItem.histSeriesColor}
                func={(data) => changeColor(cellProps, data)}
              />
            </td>
          )}
        />

        <GridColumn width="117px" cell={CommandCell} />
        <GridNoRecords>
          Add a new query by typing an expression below. You can browse
          available instruments and insert them into the expression.
        </GridNoRecords>
      </StyledGrid>
      <div>
        <StyledExpressionInput>
          <CodeMirrorComponent
            dataSources={dataSources}
            handleChange={(expression, parser) => {
              handleQueryCreate(expression, parser)
              updateChart(true)
            }}
            style={{ height: 80 }}
          />
        </StyledExpressionInput>
      </div>
    </div>
  )
}

export default QueryWrapper
