import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartLegendItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  LegendItemClickEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import { MultiModelParametersSeries, MultiTermStructure } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import { SMILE_CATEGORIES } from 'consts'
import 'hammerjs'
import { useMemo } from 'react'
import { customLabel } from './customLabel'

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{point.category.toUpperCase()}</td>
      </tr>
      <tr>
        <td>{formatNumber(point.dataItem, 'p2')}</td>
      </tr>
    </table>
  )
}

type ValidTermType = MultiTermStructure | MultiModelParametersSeries

interface ISmileChartProps<T extends ValidTermType> {
  data: T | null
  selectedField: string
  onLegendItemClick: (index: number) => void
}

const SmileChart = <T extends ValidTermType>(props: ISmileChartProps<T>) => {
  const { data, selectedField, onLegendItemClick } = props
  const theme = useTheme()

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const minYValue = useMemo(() => {
    if (!data) {
      return 0
    }
    let min = Infinity
    Object.values(data).forEach((seriesData) => {
      let tmpMin = Math.min.apply(Math, seriesData)
      min = tmpMin < min ? tmpMin : min
    })

    min = Math.floor(min / 0.05) * 0.05

    return min
  }, [data])

  return (
    <>
      {!data ? (
        <p>No series data available</p>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              position: 'relative',
              top: '60px',
              left: '-30px',
              zIndex: 1,
              height: 0,
            }}
          >
            <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
              Shift + scroll to zoom
            </p>
          </div>
          <Chart
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            pannable
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            transitions={false}
            onLegendItemClick={(e: LegendItemClickEvent) => {
              onLegendItemClick(e.series.index)
            }}
          >
            <ChartLegend position="top" align="end" orientation="horizontal">
              <ChartLegendItem cursor="pointer" visual={customLabel} />
            </ChartLegend>
            <ChartTooltip />
            <ChartValueAxis>
              <ChartValueAxisItem
                labels={{ format: 'p0', font: '0.75em "OpenSans"' }}
                name="yAxis"
                min={minYValue}
                crosshair={{
                  visible: true,
                  tooltip: {
                    visible: true,
                    format: 'p2',
                    background: theme.palette.common.kendoGrey,
                  },
                }}
              />
            </ChartValueAxis>
            <ChartSeries>
              {Object.entries(data).map(([seriesLabel, seriesData]) => (
                <ChartSeriesItem
                  key={seriesLabel}
                  type="line"
                  style="smooth"
                  name={seriesLabel}
                  width={2}
                  xAxis="x"
                  data={seriesData}
                >
                  <ChartSeriesItemTooltip
                    render={tooltipRenderer}
                    color="white"
                    background={theme.palette.common.kendoGrey}
                  />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                title={{ text: 'Delta' }}
                categories={SMILE_CATEGORIES}
                labels={{ content: ({ value }) => Math.abs(value).toString() }}
              />
            </ChartCategoryAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default SmileChart
