import { API_ROOT_URL } from 'consts'

export type EndpointDefinitionRequiringZipping = {
  path: string
  itemsRequireZipping: true
}

export type EndpointDefinitionNotRequiringZipping = {
  path: string
  itemsRequireZipping: false
}

export type EndpointDefinition =
  | EndpointDefinitionRequiringZipping
  | EndpointDefinitionNotRequiringZipping

export type MultiSeriesResult<T> = {
  successes: Array<T>
  errors?: Array<Error>
}

export type UnzippedItem<T> = {
  [Property in keyof T]: Array<T[Property]>
}

const zipItem = <T>(unzippedItem: UnzippedItem<T>): Array<T> => {
  const keys = Object.keys(unzippedItem) as Array<keyof T>
  if (keys.length === 0) {
    throw new Error('Response contains empty item')
  }
  const itemCount = unzippedItem[keys[0]].length
  const zippedItem = Array.from(Array(itemCount)).map((_, i) => {
    return keys.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: unzippedItem[cur][i],
      }
    }, {} as T)
  })
  return zippedItem
}

export type BSUnzippedItemApiResponse<T> = {
  data: {
    count: number
    items: Array<UnzippedItem<T>>
  }
  error?: {
    code: number
    message: string
  }
}

export type BSApiResponse<T> = {
  data: {
    count: number
    items: Array<T>
  }
  error?: {
    code: number
    message: string
  }
}

export type BaseModelParametersQueryParams = {
  model: string
  exchange: string
  currency: string
}

export type ModelParametersSpecifiedDatetimeParams =
  BaseModelParametersQueryParams & {
    date: string
  }

export type ModelParametersSpecifiedTimestampParams =
  BaseModelParametersQueryParams & {
    timestamp: string
  }

export type ModelParametersDatetimeRangeParams =
  BaseModelParametersQueryParams & {
    start: string
    end: string
    tenor: string
  }

export type ModelParametersDatetimeRangeWithFieldParams =
  ModelParametersDatetimeRangeParams & {
    field: string
  }

export type ModelParametersQueryParams =
  | ModelParametersSpecifiedDatetimeParams
  | ModelParametersDatetimeRangeParams
  | ModelParametersDatetimeRangeWithFieldParams
  | ModelParametersSpecifiedTimestampParams

const fetchUnzippedItems = async <T>(
  endpoint: EndpointDefinitionRequiringZipping,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
): Promise<Array<Array<T>>> => {
  const queryString = params.toString()
  const url = `${API_ROOT_URL}${endpoint.path}?${queryString}`
  const response = await fetch(url, { mode: 'cors' })
  const parsedResponse: BSUnzippedItemApiResponse<T> = await response.json()
  if (parsedResponse.error) {
    throw new Error(parsedResponse.error.message)
  }
  const items = parsedResponse?.data?.items
  if (!items) {
    throw new Error('No results for query')
  }
  const result = items.map((item) => zipItem<T>(item))
  if (typeguard && !result.every((x) => x.every(typeguard))) {
    throw new Error('Response does not match expected structure')
  }
  return result
}

const fetchItems = async <T>(
  endpoint: EndpointDefinitionNotRequiringZipping,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
): Promise<Array<T>> => {
  const queryString = params.toString()
  const url = `${API_ROOT_URL}${endpoint.path}?${queryString}`
  const response = await fetch(url, { mode: 'cors' })
  const parsedResponse: BSApiResponse<T> = await response.json()
  if (parsedResponse.error) {
    throw new Error(parsedResponse.error.message)
  }
  const items = parsedResponse?.data?.items
  if (!items) {
    throw new Error('No results for query')
  }
  if (typeguard && !items.every(typeguard)) {
    throw new Error('Response does not match expected structure')
  }
  return items
}
export async function getBSData<
  T,
  U extends EndpointDefinitionRequiringZipping,
>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
): Promise<Array<Array<T>>>
export async function getBSData<
  T,
  U extends EndpointDefinitionNotRequiringZipping,
>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
): Promise<Array<T>>
export async function getBSData<T, U extends EndpointDefinition>(
  endpoint: U,
  params: URLSearchParams,
  typeguard?: (x) => x is T,
) {
  if (endpoint.itemsRequireZipping) {
    return await fetchUnzippedItems(endpoint, params, typeguard)
  } else {
    return await fetchItems(endpoint, params, typeguard)
  }
}
