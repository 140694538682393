import { ReactNode, useLayoutEffect, useMemo } from 'react'
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartLegendItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  DragEndEvent,
  TooltipContext,
  ValueAxisLabels,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { useTheme } from 'styled-components'
import { DateRange, IChartFrequencyData, MultiTimeSeriesData } from 'types'
import { ITimeSeriesDataPoint, TimeSeriesData } from 'types/charts'
import Download, { useSave } from 'hooks/useSave'

import 'hammerjs'
import { useTimestamp } from 'components/common/ChartWrapper'
import { customLabel } from './customLabel'

interface IMultiTimeSeriesBarChartProps {
  dataReader: () => MultiTimeSeriesData
  frequency: IChartFrequencyData
  activeSeries: Array<string>
  singleAxis?: boolean
  valueAxisLabels?: ValueAxisLabels
  valueAxisTitle: (seriesLabel: string) => string
  valueAxisCrosshairFormat?: string
  renderer?: (props: TooltipContext) => ReactNode
  title?: string
}

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>{formatNumber(point.value, { style: 'decimal' })}</td>
      </tr>
    </table>
  )
}

const getFromUntilForSeries = (series: TimeSeriesData) => {
  return {
    from: series.dataPoints[0]?.x,
    until: series.dataPoints[series.dataPoints.length - 1]?.x,
  }
}

// Integrate into TimeSeriesLineChart?
const MultiTimeSeriesBarChart: React.FC<IMultiTimeSeriesBarChartProps> = ({
  dataReader,
  frequency,
  activeSeries,
  valueAxisLabels,
  valueAxisTitle,
  valueAxisCrosshairFormat = 'p2',
  renderer,
  singleAxis = false,
  title = `BlockScholes Multi bar series`,
}) => {
  const data = dataReader()
  const theme = useTheme()
  const { chartRef, handleSave } = useSave()
  const { timestampRange, setTimestampRange } = useTimestamp()

  useLayoutEffect(() => {
    let from: Date = Object.values(data)[0]?.dataPoints?.[0]?.x
    let until: Date = Object.values(data)[0]?.dataPoints?.[0]?.x
    Object.values(data).forEach((series) => {
      const { from: seriesFrom, until: seriesUntil } =
        getFromUntilForSeries(series)
      if (!from || seriesFrom?.getTime() < from?.getTime()) {
        from = seriesFrom
      }
      if (!until || seriesUntil?.getTime() > until?.getTime()) {
        until = seriesUntil
      }
    })
    setTimestampRange({
      from,
      until,
    })
  }, [data])

  const handleZoomEnd = (e: ZoomEndEvent) => {
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.nativeEvent.event.shiftKey) {
      // Shift-drag is used for zooming, so we do not want to handle here
      return
    }
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  const activeData: Record<
    string,
    Array<ITimeSeriesDataPoint>
  > = useMemo(() => {
    return activeSeries.reduce((acc, series) => {
      return {
        ...acc,
        [series]: data.find((x) => x.key === series)?.dataPoints,
      }
    }, {})
  }, [data, activeSeries])

  // FIXME: extract to hook
  const minYValue = useMemo(() => {
    if (!data) {
      return 0
    }
    let min = 0
    Object.values(data).forEach((seriesData) => {
      min = Math.min(
        min,
        ...seriesData.dataPoints.map((d) => (d.y as number) * 100),
      )
    })
    return Number.isNaN(min) ? 0 : min
  }, [data])
  return (
    <>
      {!timestampRange?.from && !timestampRange?.until ? (
        <p
          style={{
            height: '400px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          No series data available
        </p>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              position: 'relative',
              top: '60px',
              left: '-30px',
              zIndex: 1,
              height: 0,
            }}
          >
            <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
              Shift + scroll to zoom
            </p>
            <Download handleClick={() => handleSave(title)} />
          </div>
          <Chart
            pannable
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            onDragEnd={handleDragEnd}
            transitions={false}
            ref={chartRef}
          >
            <ChartLegend
              width={600}
              position="top"
              align="end"
              orientation="horizontal"
            >
              <ChartLegendItem cursor="pointer" visual={customLabel} />
            </ChartLegend>
            <ChartTooltip />
            <ChartValueAxis>
              {singleAxis ? (
                <ChartValueAxisItem
                  name="yAxis"
                  title={{ text: valueAxisTitle('') }}
                  axisCrossingValue={minYValue}
                  labels={
                    valueAxisLabels || {
                      format: valueAxisCrosshairFormat,
                      font: '0.75em "OpenSans"',
                    }
                  }
                  crosshair={{
                    visible: true,
                    tooltip: {
                      visible: true,
                      format: valueAxisCrosshairFormat,
                      background: theme.palette.common.kendoGrey,
                    },
                  }}
                />
              ) : (
                Object.keys(activeData).map((seriesLabel) => (
                  <ChartValueAxisItem
                    key={seriesLabel}
                    name={seriesLabel}
                    title={{ text: valueAxisTitle(seriesLabel) }}
                    axisCrossingValue={minYValue}
                    labels={
                      valueAxisLabels || {
                        format: valueAxisCrosshairFormat,
                        font: '0.75em "OpenSans"',
                      }
                    }
                    crosshair={{
                      visible: true,
                    }}
                  />
                ))
              )}
            </ChartValueAxis>
            <ChartSeries>
              {Object.entries(activeData).map(([seriesLabel, seriesData]) => (
                <ChartSeriesItem
                  key={seriesLabel}
                  type="column"
                  name={seriesLabel}
                  axis={singleAxis ? 'yAxis' : seriesLabel}
                  width={2}
                  field="y"
                  categoryAxis="timestamp"
                  categoryField="x"
                  data={seriesData}
                  markers={{ visible: false }}
                >
                  <ChartSeriesItemTooltip
                    render={renderer || tooltipRenderer}
                    color="white"
                    background={theme.palette.common.kendoGrey}
                  />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name="timestamp"
                baseUnit={frequency.unit}
                baseUnitStep={frequency.step}
                maxDivisions={20}
                axisCrossingValue={[0, Date.now()]}
                labels={{
                  rotation: 'auto',
                  font: '0.75em "OpenSans"',
                  dateFormats: {
                    weeks: 'd MMM yy',
                  },
                }}
                min={timestampRange.from}
                max={timestampRange.until}
                crosshair={{
                  visible: true,
                }}
              />
            </ChartCategoryAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default MultiTimeSeriesBarChart
