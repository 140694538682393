import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { Dropdown } from 'components/atoms'
import { DateTimePicker } from 'components/atoms/DatePickers'
import { Body1, Body2 } from 'components/atoms/Typography/styles'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import SeriesSelector from 'components/common/SeriesSelector'
import { DATETIME_FORMAT, START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'
import React, { useEffect, useState } from 'react'
import { dataService } from 'services/lambdaDataService'
import { MultiTermStructure, Source, Ticker } from 'types'
import { Model } from 'types/models'
import { convertDateToUTC } from 'utils/convert-date-to-utc'
import SmileChart from './SmileChart'

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: [Model.SABR],
  },
  {
    name: 'SVI',
    value: [Model.SVI],
  },
  {
    name: 'All',
    value: [Model.SABR, Model.SVI],
  },
]

const EXPIRY_OPTIONS = [
  {
    name: '1W',
    value: 7,
  },
  {
    name: '1M',
    value: 30,
  },
  {
    name: '3M',
    value: 90,
  },
  {
    name: '6M',
    value: 180,
  },
  {
    name: '1Y',
    value: 365,
  },
]

export enum OptionsSmileSeriesType {
  MODEL,
  TENOR,
  DATE,
  DEFAULT,
}

const getOptionsSmileSeriesType = (
  activeModels: Array<Model>,
  activeTenors: Array<string>,
  dates: Array<string>,
): OptionsSmileSeriesType => {
  if (activeModels.length > 1) {
    return OptionsSmileSeriesType.MODEL
  }
  if (activeTenors.length > 1) {
    return OptionsSmileSeriesType.TENOR
  }
  if (dates.length > 1) {
    return OptionsSmileSeriesType.DATE
  }
  return OptionsSmileSeriesType.DEFAULT
}

const OptionsSmile: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [activeModels, setActiveModels] = useState<Array<Model>>([Model.SVI])
  const [activeTenors, setActiveTenors] = useState<Array<string>>(['1W'])
  const [dates, setDates] = useState<Array<string>>([])
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const seriesType = getOptionsSmileSeriesType(
    activeModels,
    activeTenors,
    dates,
  )
  const [series, setSeries] = useState<MultiTermStructure | null>(null)

  useEffect(() => {
    dataService
      .getOptionSmile(
        source,
        ticker,
        activeModels,
        dates,
        activeTenors,
        seriesType,
        isInitialLoad,
      )
      .then((results) => {
        setSeries(results)
        if (isInitialLoad) {
          const latestDate = new Date(Object.keys(results)[0])
          setDates([convertDateToUTC(latestDate).toISOString()])
          setIsInitialLoad(false)
        }
      })
      .catch((err) => {})
  }, [dates, activeTenors, source, ticker, activeModels])

  const handleSeriesDeletion = (seriesIndex: number) => {
    if (!series) {
      return
    }
    const keyToDelete = Object.keys(series)[seriesIndex]
    if (
      seriesType === OptionsSmileSeriesType.DATE ||
      seriesType === OptionsSmileSeriesType.DEFAULT
    ) {
      const updatedDates = dates.filter((_, indx) => indx !== seriesIndex)
      setDates(updatedDates)
      return
    }
    if (seriesType === OptionsSmileSeriesType.TENOR) {
      const updatedTenors = activeTenors.filter((tenor) => {
        return tenor !== keyToDelete
      })
      setActiveTenors(updatedTenors)
    }
    if (seriesType === OptionsSmileSeriesType.MODEL) {
      const updatedModels = activeModels.filter((model) => {
        return model !== keyToDelete
      })
      setActiveModels(updatedModels)
    }
  }

  const handleDateSelect = (event: DateTimePickerChangeEvent) => {
    if (!event?.value) {
      return
    }
    const utcDate = convertDateToUTC(event.value)
    if (
      seriesType === OptionsSmileSeriesType.MODEL ||
      seriesType === OptionsSmileSeriesType.TENOR
    ) {
      setDates([utcDate.toISOString()])
      return
    }
    setDates(Array.from(new Set([...dates, utcDate.toISOString()])))
  }

  const handleModelSelect = (selectedModels: Array<Model>) => {
    if (selectedModels.length > 1) {
      setActiveTenors(activeTenors.slice(0, 1))
      setDates(dates.slice(0, 1))
    }
    setActiveModels(selectedModels)
  }

  const handleTenorSelect = (selectedTenors: Array<string>) => {
    if (
      seriesType === OptionsSmileSeriesType.DEFAULT ||
      seriesType === OptionsSmileSeriesType.TENOR ||
      selectedTenors.length <= activeTenors.length
    ) {
      setActiveTenors(selectedTenors)
      return
    }
    const [newTenor] = selectedTenors.filter((tenor) => {
      return !activeTenors.includes(tenor)
    })
    setActiveTenors([newTenor])
  }

  const datePickerDisabled =
    seriesType !== OptionsSmileSeriesType.DATE &&
    seriesType !== OptionsSmileSeriesType.DEFAULT
  return (
    <ChartWrapper
      title="Volatility Smile"
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <Dropdown
            showLabel
            current={MODEL_OPTIONS.find(({ name, value }) => {
              if (activeModels.length > 1) {
                return name === 'All'
              }
              return activeModels[0] === value[0]
            })}
            options={MODEL_OPTIONS}
            onChange={handleModelSelect}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Model"
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <DateTimePicker
              width={300}
              onChange={handleDateSelect}
              format={DATETIME_FORMAT}
              min={START_OF_BLOCKSCHOLES_TIME}
              max={TODAY_UTC}
              placeholder={
                !datePickerDisabled
                  ? 'Select a date and time'
                  : `Unavailable with multiple ${
                      activeModels.length > 1 ? 'Models' : 'Expiries'
                    }`
              }
              fillMode="flat"
              disabled={datePickerDisabled}
            />
          </div>
        </>
      }
      series={
        <SeriesSelector
          options={EXPIRY_OPTIONS.map((option) => option.name)}
          value={activeTenors}
          handleChange={handleTenorSelect}
          label="Expiry"
        />
      }
      chart={
        <SmileChart
          data={series}
          selectedField="y"
          onLegendItemClick={handleSeriesDeletion}
        />
      }
    />
  )
}

export default OptionsSmile
