import { H3 } from 'components/atoms/Typography/styles'
import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { marginTop } from 'styled-system'
import { DateRange } from 'types'

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: ${({ theme }) => theme.size.gutter}px;
  padding-right: ${({ theme }) => theme.size.gutter}px;
`

interface IStyledControlBarProps {
  singleRow: boolean
}

const StyledControlBar = styled.div<IStyledControlBarProps>`
  display: flex;
  width: 100%;
  column-gap: 1em;
  align-items: center;
  background: #313438;
  border-radius: 4px;
  column-gap: 1em;
  .k-d-inline-flex {
    justify-content: center;
    align-items: center;
  }
  padding: 20px 15px;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`
const StyledSeriesBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 25px;
`

interface IChartWrapperProps {
  title: string
  subtitle?: string
  controls: React.ReactNode
  chart: React.ReactNode
  singleRow?: boolean
  legend?: JSX.Element
  series?: React.ReactNode
}

type Context = {
  timestampRange?: DateRange
  setTimestampRange: Dispatch<SetStateAction<DateRange | undefined>>
}
const timestampRangeContext = React.createContext<Context>({
  setTimestampRange: () => {},
})

export const TimestampRangeProvider: React.FC<{ children }> = ({
  children,
}: {
  children: React.ReactChildren
}) => {
  const [timestampRange, setTimestampRange] = useState<DateRange>()

  return (
    <timestampRangeContext.Provider
      value={{ timestampRange, setTimestampRange }}
    >
      {children}
    </timestampRangeContext.Provider>
  )
}

export const useTimestamp = (
  range?: DateRange,
  handleChange?: Dispatch<SetStateAction<DateRange | undefined> | DateRange>,
): Context => {
  const timestampRange = useContext(timestampRangeContext)
  if (range && handleChange) {
    return { timestampRange: range, setTimestampRange: handleChange }
  }
  return timestampRange
}

const ChartWrapper: React.FC<IChartWrapperProps> = (props) => {
  const { title, subtitle, controls, series, chart, singleRow = true, legend } = props

  const theme = useTheme()

  return (
    <TimestampRangeProvider>
      <FlexColumn>
        <H3
          style={{
            color: theme.palette.primary.light,
            alignSelf: 'flex-start',
            padding: '3rem 0px 1.5rem 0',
          }}
        >
          {title}
        </H3>
        {subtitle && <p>{subtitle}</p>}
        <StyledControlBar singleRow={singleRow}>{controls}</StyledControlBar>
        {series && <StyledSeriesBar>{series}</StyledSeriesBar>}
        <CenteredContent>
          {chart}
          {legend}
        </CenteredContent>
      </FlexColumn>
    </TimestampRangeProvider>
  )
}

export default ChartWrapper
