import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import styled, { useTheme } from 'styled-components'
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification'
import { Fade } from '@progress/kendo-react-animation'
import { dataService } from 'services'
import Page from 'components/atoms/Page'
import DateRangeSelector from 'components/common/DateRangeSelector'
import Button from 'components/atoms/Button'
import FrequencyChange from 'components/molecules/FrequencyChange'
import { Loader } from '@progress/kendo-react-indicators'
import { HistoricalAnalyzerContext } from 'context/HistoricalAnalyzerContext'
import {
  DateRange,
  HistoricalAnalyzerCdfData,
  HistoricalAnalyzerRegressionData,
  HistoricalAnalyzerTimeSeriesData,
} from 'types'
import ChartLoader from 'components/charts/ChartLoader'

import QueryWrapper from './QueryWrapper'
import Query from './query'
import AxisNamesModal from './AxisNamesModal'

import { AxisNames, ParseTreeRoot, ValueType } from './types'
import { parseTreeToAst } from './language'
import { Parser } from './parser'
import HelpModal from './HelpModal'
import { AnalyzerSection } from './AnalyzerSection'
import HistoricalAnalyzerTimeSeriesChart from './HistoricalAnalyzerTimeSeriesChart'
import HistoricalAnalyzerScatterChart from './HistoricalAnalyzerScatterChart'
import HistoricalAnalyzerCdfChart from './HistoricalAnalyzerCdfChart'
import useCodeMirror from './useCodeMirror'
import { useSearchParams } from 'react-router-dom'

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const UpdateChartButton = styled.div`
  margin-left: 20px;
`

const labelForQueryNum = (queryNum: number): string => {
  if (queryNum >= 26) {
    const number = Math.floor(queryNum / 26)
    queryNum = queryNum - 26 * number
    const alpha = String.fromCharCode(queryNum + 65)
    return `${alpha}${number}`
  } else {
    return String.fromCharCode(queryNum + 65)
  }
}

enum ChartRenderType {
  TIMESERIES = 'TIMESERIES',
  SCATTER = 'SCATTER',
  CDF = 'CDF',
  MIXED = 'MIXED',
}

const ButtonGroupMain = styled.div`
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: center;
`

const AddNewRowButton = styled.div`
  display: block;
`

const AddFunctionButton = styled.div`
  margin-left: 10px;
  display: block;
`

const StyledControlBar = styled.div`
  display: flex;
  margin-bottom: auto;
  width: 100%;
  height: 80px;
  margin-left: 20px;
  column-gap: 1em;
  align-items: center;
  background: #313438;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  column-gap: 1em;
  justify-content: center;
  align-items: center;
`

const HistoricalAnalyzer: React.FC = () => {
  const context = useContext(HistoricalAnalyzerContext)

  const {
    chartAxisNames,
    chartFrequency,
    queries,
    querySeriesMapping,
    sources,
    timestampRange,
    queryCount,
    histChartTitle,
    updateChartBtnStatus,
  } = context.state
  const {
    createQuery,
    editQuery,
    deleteQuery,
    setQuerySeriesMapping,
    setTimestampRange,
    setChartFrequency,
    setChartAxisNames,
    setHistChartTitle,
  } = context

  const [historicalAnalyzerTitle, setHistoricalAnalyzerTitle] = useState('')

  useEffect(() => {
    let historicalAnalyzerTitleConcat: string = ''
    function isObjectEmpty(queries) {
      return Object.keys(queries).length === 0
    }

    const isQueriesEmpty = isObjectEmpty(queries)
    if (isQueriesEmpty) {
      setHistoricalAnalyzerTitle('')
    }
    Object.values(queries).forEach((dataItem, index) => {
      historicalAnalyzerTitleConcat =
        index === 0
          ? historicalAnalyzerTitleConcat + dataItem.label
          : historicalAnalyzerTitleConcat + ' - ' + dataItem.label
      setHistoricalAnalyzerTitle(historicalAnalyzerTitleConcat)
    })
  }, [queries])

  const handleTitleChange = (event) => {
    setHistChartTitle(event.target.value)
    setHistoricalAnalyzerTitle(event.target.value)
  }

  const [showHelpModal, setShowHelpModal] = useState(false)
  const [errorMap, setErrorMap] = useState<Map<Query, string>>(new Map())
  const [isLoading, setIsLoading] = useState(false)
  const [showAxisNamesModal, setShowAxisNamesModal] = useState(false)
  const [globalError, setGlobalError] = useState<string | null>(null)
  const [inBuiltFunc, setInBuiltFunc] = useState<any>(null)
  const [localVal, setLocalVal] = useState<any>('')
  const [updateChart, setUpdateChart] = useState<boolean>(false)

  const { parsed, setInput, clearInput } = useCodeMirror(localVal, sources)
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const createAST = (
    parseTree: ParseTreeRoot,
    parsedQueries: Record<string, Query>,
  ) => {
    const ast = parseTreeToAst(parseTree, parsedQueries)
    const output = {
      start: timestampRange.from.toISOString(),
      end: timestampRange.until.toISOString(),
      parse_tree: ast,
    }
    return output
  }

  // Add logic for avoiding empty queries

  const fetchQueries = () => {
    setIsLoading(true)
    const parsedQueries = Object.values(queries).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.label]: curr,
      }
    }, {})

    const requests = Object.values(queries).map((query) => {
      return { query, ast: createAST(query.tree, parsedQueries) }
    })

    dataService
      .getHistoricalSeries(requests, timestampRange)
      .then(({ successes, errors }) => {
        setQuerySeriesMapping(successes)
        setErrorMap(errors)
      })
      .catch((e) => {
        // TODO: log unexpected error
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleQueryCreate = (
    expression: string | undefined | null,
    parsed: Parser | null,
  ) => {
    if (expression && parsed?.isValid()) {
      const query = new Query(
        expression,
        labelForQueryNum(queryCount),
        parsed.parseTree,
      )
      createQuery(query, parsed)
    } else {
      if (parsed && parsed.parseTree) {
        const query = new Query(
          '',
          labelForQueryNum(queryCount),
          parsed.parseTree,
        )
        createQuery(query, parsed)
      }
    }
  }
  const handleQueryEdit = (query: Query) => {
    editQuery(query)
  }

  // TODO: warn about removing dependent queries
  const handleQueryDelete = (query: Query) => {
    deleteQuery(query)
  }

  // FIXME: this is truly awful code
  const chartRenderType = useMemo(() => {
    const kinds: string[] = []
    const timeseriesOutput: Array<[Query, HistoricalAnalyzerTimeSeriesData]> =
      []
    const regressionOutput: Array<[Query, HistoricalAnalyzerRegressionData]> =
      []
    const cdfOutput: Array<[Query, HistoricalAnalyzerCdfData]> = []

    for (const [query, querySeries] of querySeriesMapping) {
      if (query.isActive) {
        kinds.push(querySeries.kind)
        if (querySeries.kind === 'regression') {
          regressionOutput.push([query, querySeries])
        }
        if (querySeries.kind === 'timeseries') {
          timeseriesOutput.push([query, querySeries])
        }
        if (querySeries.kind === 'cdf') {
          cdfOutput.push([query, querySeries])
        }
      }
    }
    if (kinds.every((k) => k === 'timeseries')) {
      return { type: ChartRenderType.TIMESERIES, data: timeseriesOutput }
    }
    if (kinds.length === 1 && kinds[0] === 'regression') {
      return { type: ChartRenderType.SCATTER, data: regressionOutput }
    }
    if (kinds.length === 1 && kinds[0] === 'cdf') {
      return { type: ChartRenderType.CDF, data: cdfOutput }
    }
    if (kinds.length > 1 && kinds.every((k) => k === 'regression')) {
      setGlobalError(
        'Cannot render multiple regressions. Please select only one',
      )
      return { type: ChartRenderType.MIXED, data: [] }
    }
    if (kinds.length > 1 && kinds.every((k) => k === 'cdf')) {
      setGlobalError('Cannot render multiple CDFs. Please select only one')
      return { type: ChartRenderType.MIXED, data: [] }
    }
    setGlobalError(
      'Cannot combine timeseries, CDF and regression charts. Please select only one type',
    )
    return { type: ChartRenderType.MIXED, data: [] }
  }, [querySeriesMapping])

  const addNewRow = () => {
    handleQueryCreate('', parsed)
  }

  useEffect(() => {
    if (updateChart) {
      fetchQueries()
    }
    setUpdateChart(false)
  }, [updateChart])

  useEffect(() => {
    if (localVal && localVal !== '') {
      handleQueryCreate(localVal, parsed)
      setLocalVal('')
      clearInput()
    }
  }, [localVal])

  useEffect(() => {
    setLocalVal(inBuiltFunc)
    setInput(inBuiltFunc)
  }, [inBuiltFunc])

  return (
    <Page style={{ position: 'relative' }}>
      {showHelpModal && (
        <HelpModal
          handleClose={() => setShowHelpModal(false)}
          setFunctionName={setInBuiltFunc}
        />
      )}
      {showAxisNamesModal && (
        <AxisNamesModal
          axisNames={chartAxisNames}
          handleClose={(updated: AxisNames) => {
            if (
              updated.left !== chartAxisNames.left ||
              updated.right !== chartAxisNames.right
            ) {
              setChartAxisNames(updated)
            }
            setShowAxisNamesModal(false)
          }}
        />
      )}
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            color: theme.palette.primary.contrastText,
            background:
              'radial-gradient(circle at 50% 50%, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%)',
          }}
        >
          <ChartLoader />
        </div>
      )}
      <ColumnLayout>
        <AnalyzerSection>
          <CenteredContent>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '5rem',
                  width: '30px',
                }}
              >
                <span
                  style={{
                    transform: 'rotate(-90deg)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    size={50}
                    style={{
                      WebkitAppearance: 'none',
                      background: 'none',
                      color: 'rgb(228, 231, 235)',
                      borderStyle: 'none',
                      textAlign: 'center',
                    }}
                    placeholder={'Click to edit axis name'}
                    value={chartAxisNames.left || undefined}
                    onChange={(event) =>
                      setChartAxisNames({
                        ...chartAxisNames,
                        left: event.target.value,
                      })
                    }
                  />
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <input
                  size={50}
                  style={{
                    WebkitAppearance: 'none',
                    background: 'none',
                    color: 'rgb(228, 231, 235)',
                    borderStyle: 'none',
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    fontWeight: 700,
                    paddingLeft: '12px',
                    paddingRight: '12px',
                  }}
                  className="histTitleClassTitle"
                  placeholder={'Click to edit the title'}
                  value={histChartTitle || historicalAnalyzerTitle}
                  onChange={(event) => handleTitleChange(event)}
                />
                {chartRenderType.type === ChartRenderType.TIMESERIES ? (
                  <HistoricalAnalyzerTimeSeriesChart
                    data={
                      chartRenderType.data as Array<
                        [Query, HistoricalAnalyzerTimeSeriesData]
                      >
                    }
                    dateRange={timestampRange}
                    // TODO: fix this hack
                    axisNames={{ left: '', right: '' }}
                    frequency={chartFrequency}
                    title={
                      histChartTitle ||
                      historicalAnalyzerTitle ||
                      'Historical Analyzer'
                    }
                  />
                ) : chartRenderType.type === ChartRenderType.SCATTER ? (
                  <HistoricalAnalyzerScatterChart
                    data={
                      chartRenderType.data as Array<
                        [Query, HistoricalAnalyzerRegressionData]
                      >
                    }
                    axisNames={{ left: '', right: '' }}
                    title={histChartTitle || historicalAnalyzerTitle}
                  />
                ) : chartRenderType.type === ChartRenderType.CDF ? (
                  <HistoricalAnalyzerCdfChart
                    data={
                      chartRenderType.data as Array<
                        [Query, HistoricalAnalyzerCdfData]
                      >
                    }
                    title={histChartTitle || historicalAnalyzerTitle}
                    axisNames={{ left: '', right: '' }}
                  />
                ) : (
                  <HistoricalAnalyzerTimeSeriesChart
                    data={[]}
                    dateRange={timestampRange}
                    axisNames={{ left: '', right: '' }}
                    frequency={chartFrequency}
                    title={
                      histChartTitle ||
                      historicalAnalyzerTitle ||
                      'Historical Analyzer'
                    }
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '5rem',
                  width: '30px',
                }}
              >
                <span
                  style={{
                    transform: 'rotate(90deg)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    size={50}
                    style={{
                      WebkitAppearance: 'none',
                      background: 'none',
                      color: 'rgb(228, 231, 235)',
                      borderStyle: 'none',
                      textAlign: 'center',
                    }}
                    placeholder={'Click to edit axis name'}
                    value={chartAxisNames.right || undefined}
                    onChange={(event) =>
                      setChartAxisNames({
                        ...chartAxisNames,
                        right: event.target.value,
                      })
                    }
                  />
                </span>
              </div>
            </div>
          </CenteredContent>
        </AnalyzerSection>
        <AnalyzerSection>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p>
              You can create expressions using the{' '}
              <span
                className="clickable"
                onKeyPress={() => setShowHelpModal(true)}
                onClick={() => setShowHelpModal(true)}
              >
                built-in functions
              </span>{' '}
              and{' '}
              <span
                className="clickable"
                onKeyPress={() => setShowAxisNamesModal(true)}
                onClick={() => setShowAxisNamesModal(true)}
              >
                name the axes
              </span>
              .
            </p>
            <StyledControlBar
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div style={{ marginLeft: 'auto' }}>
                <FrequencyChange
                  value={chartFrequency}
                  handleChange={setChartFrequency}
                />
              </div>
              <DateRangeSelector
                range={timestampRange}
                handleChange={
                  setTimestampRange as Dispatch<
                    SetStateAction<DateRange | undefined>
                  >
                }
                rootStyle={{ width: 'unset' }}
                rowStyle={{}}
              />
              <UpdateChartButton>
                <Button
                  size="sm"
                  type="button"
                  disabled={
                    isLoading ||
                    Object.keys(queries).length === 0 ||
                    updateChartBtnStatus
                  }
                  onClick={fetchQueries}
                >
                  Update chart
                </Button>
              </UpdateChartButton>
              <div className="button-group">
                <ButtonGroupMain>
                  <AddNewRowButton>
                    <Button size="sm" onClick={addNewRow}>
                      Add +
                    </Button>
                  </AddNewRowButton>
                  <AddFunctionButton>
                    <Button size="sm" onClick={() => setShowHelpModal(true)}>
                      Functions
                    </Button>
                  </AddFunctionButton>
                  <AddFunctionButton>
                    <Button
                      size="sm"
                      onClick={() =>
                        setSearchParams({
                          searchOpen: 'true',
                          search: searchParams.get('search') || '',
                        })
                      }
                    >
                      Open Data Search
                    </Button>
                  </AddFunctionButton>
                </ButtonGroupMain>
              </div>
            </StyledControlBar>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '25px',
              }}
            >
              <QueryWrapper
                queries={Object.values(queries)}
                dataSources={sources}
                handleQueryCreate={handleQueryCreate}
                handleQueryEdit={handleQueryEdit}
                handleQueryDelete={handleQueryDelete}
                updateChart={setUpdateChart}
              />
            </div>
          </div>
        </AnalyzerSection>
      </ColumnLayout>
      {(errorMap.size > 0 || globalError) && (
        <NotificationGroup
          style={{ left: '50%', bottom: 0, transform: 'translateX(-50%)' }}
        >
          <Fade>
            <Notification
              type={{ style: 'error', icon: false }}
              style={{
                background: theme.palette.common.red,
                borderColor: theme.palette.common.red,
                color: 'white',
              }}
              closable
              onClose={() => {
                setErrorMap(new Map())
                setGlobalError(null)
              }}
            >
              <p style={{ fontWeight: 'bold' }}>Sorry, something went wrong:</p>
              {globalError && <p>{globalError}</p>}
              {Array.from(errorMap.entries()).map(([query, errorMessage]) => {
                return (
                  <p
                    key={query.id}
                  >{`Error in query ${query.label}: ${errorMessage}`}</p>
                )
              })}
            </Notification>
          </Fade>
        </NotificationGroup>
      )}
    </Page>
  )
}

export default HistoricalAnalyzer
