import Query from 'pages/HistoricalAnalyzer/query'
import { Ticker } from './ticker'

export interface ITimeSeriesDataPoint {
  x: Date
  y: number | string
}

export type TimeSeriesData = {
  key: string
  dataPoints: Array<ITimeSeriesDataPoint>
}

export interface INumberSeriesDataPoint {
  x: number
  y: number
}

export type NumberSeriesData = Array<INumberSeriesDataPoint>

export type MultiTimeSeriesData = Array<TimeSeriesData>

export type HistoricalAnalyzerTimeSeriesData = {
  kind: 'timeseries'
  data: Array<ITimeSeriesDataPoint>
}

export type HistoricalAnalyzerRegressionData = {
  kind: 'regression'
  data: {
    scatter: NumberSeriesData
    line: NumberSeriesData
    alpha: number
    beta: number
    rSquared: number
  }
}

export type HistoricalAnalyzerCdfData = {
  kind: 'cdf'
  data: NumberSeriesData
  percentiles: Array<number>
}

export type HistoricalAnalyzerData =
  | HistoricalAnalyzerTimeSeriesData
  | HistoricalAnalyzerRegressionData
  | HistoricalAnalyzerCdfData

export type HistoricalAnalyzerQuerySeries = Map<Query, HistoricalAnalyzerData>

export type MultiHistoricalAnalyzerQueryResponse = {
  successes: HistoricalAnalyzerQuerySeries
  errors: Map<Query, string>
}

export type Frequency = '1h' | '4h' | '1D' | '1W' | '1M'

export interface IChartFrequencyData {
  unit: 'hours' | 'days' | 'weeks' | 'months'
  step: number
  duration: number
}

export const TEMP_INSTRUMENTS = [
  '2SEP22',
  '23SEP22',
  '7OCT22',
  '14OCT22',
  '21OCT22',
  '28OCT22',
  '25NOV22',
  '30DEC22',
  '31MAR23',
] as const
type ConstantExpiryInstrumentsTuple = typeof TEMP_INSTRUMENTS
export type ConstantExpiryInstrument = ConstantExpiryInstrumentsTuple[number]

export type DateRange = {
  from: Date
  until: Date
}

export type TickerState = Partial<Record<Ticker, boolean>>

export const CONSTANT_TENORS = ['1M', '3M', '6M', '1Y'] as const
export const CONSTANT_TENORS_BY_DAYS = [
  '7d',
  '14d',
  '30d',
  '70d',
  '90d',
  '180d',
]
type ConstantTenorsTuple = typeof CONSTANT_TENORS
type ConstantTenorsByDaysTuple = typeof CONSTANT_TENORS_BY_DAYS
export type ConstantTenor = ConstantTenorsTuple[number]
export type ConstantTenorByDays = ConstantTenorsByDaysTuple[number]

export type CurveBasis = 'annual' | 'basis'

export type TermStructure = Array<{
  instrument: string
  yield: number
  dateOffset: number
}>

export type MultiTermStructure = Record<string, TermStructure>

export type HistoricSVIParams = {
  sviA: number
  sviB: number
  sviRho: number
  sviM: number
  sviSigma: number
}

export type SVIParams = HistoricSVIParams & {
  sviVol: number
}

export type HistoricSABRParams = {
  sabrRho: number
  sabrAlpha: number
  sabrVolVol: number
}

export type SABRParams = HistoricSABRParams & {
  sabrVol: number
}

export type HistoricImpliedVolParams = HistoricSVIParams | HistoricSABRParams

export type ModelParameters = SVIParams | SABRParams

export type HistoricImpliedVolParamsWithDate = HistoricImpliedVolParams & {
  dateOffset: number
  timestamp: Date
}

export type ModelParametersWithDate = ModelParameters & {
  dateOffset: number
  timestamp: Date
}

export type HistoricImpliedVolSeries = {
  key: string
  dataPoints: Array<HistoricImpliedVolParamsWithDate>
}

export type ModelParametersSeries = {
  key: string
  dataPoints: Array<ModelParametersWithDate>
}

export type MultiHistoricImpliedVolSeries = Record<
  string,
  Array<HistoricImpliedVolParamsWithDate>
>

export type MultiModelParametersSeries = Record<
  string,
  Array<ModelParametersWithDate>
>

export type TenorDay = number

export type HeatMapData = {
  strike: string
  expiry: number
  value: number
  zscore: number
  timeseries?: { series: string; field: string; start: string; end: string }
}

export type FuturesHeatMapEntry = {
  instrument: string
  expiry: Date | null
  availableSince: Date | null
  active?: boolean
  px?: number
  cells?: Array<number>
  timeseries?: string
}

export type FuturesHeatMap = Array<FuturesHeatMapEntry>

export type FutureHeatMapDataPoint = {
  xInstrument: string
  yInstrument: string
  value: number
  timeseries?: string
}
