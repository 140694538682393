/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash'
import {
  ConstantTenor,
  CurveBasis,
  DateRange,
  MultiTermStructure,
  MultiTimeSeriesData,
  TimeSeriesData,
  MultiHistoricalAnalyzerQueryResponse,
  HistoricImpliedVolSeries,
  HistoricImpliedVolParamsWithDate,
  ConstantTenorByDays,
  ModelParametersSeries,
} from 'types/charts'
import { OptionType, Series, Source, Ticker } from 'types'
import {
  API_ROOT_URL,
  MAX_TERM_DAYS,
  OptionTypePricerMapping,
  SMILE_CATEGORIES,
  START_OF_BLOCKSCHOLES_TIME,
  TenorDays,
  TODAY_UTC,
} from 'consts'
import AssetClass from 'types/assetClass'
import { Model, SABRParam, SVIParam } from 'types/models'
import { QueryRequest, TreeNode } from 'pages/HistoricalAnalyzer/types'
import { OptionsSmileSeriesType } from 'components/charts/OptionsSmile'
import { dateFormatter } from 'utils/date-formatter'
import {
  ModelParametersDatetimeRangeParams,
  ModelParametersSpecifiedDatetimeParams,
  MultiSeriesResult,
} from './common'
import { getHistoricalModelParams } from './historicImpliedVolatility'
import { getModelParametersData } from './modelParameters'
import { constructCatalog } from './constructCatalog'

const SeriesEndpointMap: Record<Series, string> = {
  [Series.CONSTANT_MATURITIES]: 'getConstantMaturityFutures',
  [Series.LISTED_EXPIRIES]: 'getListedTermstructure',
}

type HistoricalAnalyzerVectorResponse = {
  type: 'vector'
  timestamps: Array<number>
  values: Array<number>
  message?: string
}

type HistoricalAnalyzerScalarResponse = {
  type: 'num'
  timestamps: Array<number>
  values: number
  message?: string
}

type HistoricalAnalyzerRegressionResponse = {
  type: 'regression'
  timestamps: Array<number>
  values: [
    Array<number>,
    Array<number>,
    Array<number>,
    number,
    number,
    number,
    number,
    number,
  ]
  message?: string
}

type HistoricalAnalyzerCdfResponse = {
  type: 'cdf'
  timestamps: Array<number>
  values: [Array<number>, Array<number>, Array<number>]
  message?: string
}

type HistoricalAnalyzerResponse =
  | HistoricalAnalyzerVectorResponse
  | HistoricalAnalyzerScalarResponse
  | HistoricalAnalyzerRegressionResponse
  | HistoricalAnalyzerCdfResponse

function isHaVectorResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerVectorResponse {
  return resp.type === 'vector'
}

function isHaScalarResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerScalarResponse {
  return resp.type === 'num'
}

function isHaRegressionResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerRegressionResponse {
  return resp.type === 'regression'
}

function isHaCdfResponse(
  resp: HistoricalAnalyzerResponse,
): resp is HistoricalAnalyzerCdfResponse {
  return resp.type === 'cdf'
}

type OptionsPricerResponse = {
  price: number
  'implied vol': number
  delta: number
  gamma: number
  vega: number
  theta: number
  volga: number
  vanna: number
}

type PriceResponse = {
  data: {
    qualifiedName: string
    count: number
    items: Array<{ timestamp: string; px: string }>
  }
}

type OpenInterestResponse = {
  data: {
    qualifiedName: string
    count: number
    items: Array<{ timestamp: string; oi: string }>
  }
}

type YieldResponse = {
  data: {
    count: number
    items: Array<{ timestamp: string; px: string }> // use yield from API
  }
}

type AmountResponse = {
  data: {
    count: number
    items: Array<{ timestamp: string; sum: string; amt: string }> // TODO: remove amt once backfilled correctly
  }
}

type TermStructureResponse = {
  timestamp: string
  instruments: Array<string>
  yields: Array<string>
  expiries: Array<string>
}

type ConstantMaturityFuturesResponse = {
  data: {
    items: Array<{
      qualified_name: string
      future: number
      yield: number
      expiry: number
      timestamp: number
    }>
    error?: string
  }
  error: {
    code: number
    message: string
  }
}

type SmileResponse = {
  data: {
    count: number
    items: Array<{
      timestamp: number
      atm: number
      '-0.5': number
      '-0.4': number
      '-0.25': number
      '-0.2': number
      '-0.1': number
      '-0.05': number
      '-0.01': number
      '0.01': number
      '0.05': number
      '0.1': number
      '0.2': number
      '0.25': number
      '0.4': number
      '0.5': number
    }>
  }
}

type PutCallSkewResponse = {
  data: {
    count: number
    qualifiedName: string
    items: [
      {
        timestamp: Array<number>
        '0.05': Array<number>
        '0.1': Array<number>
        '0.2': Array<number>
        '0.25': Array<number>
        '0.4': Array<number>
      },
    ]
  }
}

type VolatilityRatioResponse = {
  data: {
    qualifiedName: string
    count: number
    lastModified: number | null
    items: Array<{
      last_modified: string
      ratio: Array<number>
      timestamp: Array<number>
    }>
  }
}

type OptionsPricerOutput = {
  data: OptionsPricerResponse
}

export type CatalogItem = {
  active: boolean | 'active' | 'expired'
  availableSince: string
  baseAsset: string
  expiry: string
  instrument: string
  listing: string
  quoteAsset: string
  exchange: string
  assetType?: string
  type?: string
}

export interface StaticCatalogItem extends CatalogItem {
  lastLeafs?: TreeNode[]
  staticCal?: boolean
}
const haEndpoint: string = process.env.REACT_APP_HA_ENDPOINT || ''
if (!haEndpoint) {
  throw new Error(
    'Historical analyzer endpoint is not defined in environment at REACT_APP_HA_ENDPOINT',
  )
}

const optionsPricerEndpoint: string = process.env.REACT_APP_OPTIONS_PRICER || ''
if (!optionsPricerEndpoint) {
  throw new Error(
    'Options pricer endpoint is not defined in environment at REACT_APP_OPTIONS_PRICES',
  )
}

export const dataService = {
  getFutureConstantTenor: async (
    ticker: Ticker,
    exchange: Source,
    constantTenors: Array<ConstantTenor>,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    // TODO: this whole function needs thought through again
    const tickerParam = ticker
    const exchangeParam = exchange.toLowerCase()

    async function performRequest(tenor: ConstantTenor) {
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.future.${tickerParam}.${tenor}.forward.index`

      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getFutureConstantTenor returned ${resp.status}`)
      // }
      const data: PriceResponse = (await resp.json()) as PriceResponse
      const series = data?.data?.items

      if (!series) {
        return []
      }

      return series.map((item) => ({
        y: +item.px,
        x: new Date(+item.timestamp / 1000),
      }))
    }

    const results = await Promise.all(constantTenors.map(performRequest))
    return {
      successes: constantTenors.map((tenor, i) => {
        return {
          key: tenor,
          dataPoints: results[i],
        }
      }),
    }
  },
  getCatalogData: async (): Promise<CatalogItem[]> => {
    type CatalogResponse = {
      data: {
        count: number
        items: Array<{
          active: Array<boolean>
          availableSince: Array<string>
          baseAsset: Array<string>
          expiry: Array<string>
          index: Array<string>
          instrument: Array<string>
          listing: Array<string>
          quoteAsset: Array<string>
          type?: Array<string>
        }>
      }
      error?: string
    }
    type ActiveCatalogResponse = {
      data: {
        count: number
        qualifiedName: string
        items: Array<{
          active: boolean
          availableSince: string
          expiry: string
          instrument: string
        }>
      }
      error?: string
    }
    async function performRequest(
      currency: string,
      assetType: string,
      exchange: string,
    ) {
      const URL = `${API_ROOT_URL}catalog/getData?exchange=${exchange}&currency=${currency}&assetType=${assetType}`
      const response = await fetch(URL, { mode: 'cors' })
      const data: CatalogResponse = await response.json()
      const item = data.data.items[0]
      const catalogItems: Array<CatalogItem> = item.active?.map(
        (active, i) => ({
          active: new Date(item.expiry[i]) > new Date() ? 'active' : 'expired',
          availableSince: item.availableSince?.[i],
          baseAsset: item.baseAsset[i],
          expiry: item.expiry[i],
          instrument: item.instrument[i],
          listing: item.listing?.[i],
          quoteAsset: item.quoteAsset[i],
          type: item.type?.[i],
          assetType,
          exchange,
        }),
        [],
      )

      const activeCatalogItems = catalogItems?.sort(
        (catalogItem1, catalogItem2) => {
          return (
            new Date(catalogItem1.expiry).getTime() -
            new Date(catalogItem2.expiry).getTime()
          )
        },
      )

      return activeCatalogItems || []
    }
    async function getActiveOptions(
      currency: string,
      assetType: string,
      exchange: string,
    ) {
      const URL = `${API_ROOT_URL}catalog/getAllActiveCatalogData?exchange=${exchange}&currency=${currency}&assetType=${assetType}`
      const res = await fetch(URL, { mode: 'cors' })
      const json: ActiveCatalogResponse = await res.json()
      if (json.error) return []

      return json.data.items.map((i) => ({
        ...i,
        exchange,
        assetType,
        baseAsset: currency,
        active: 'active',
        // eslint-disable-next-line no-nested-ternary
        ...(assetType === 'option'
          ? i.instrument.endsWith('-C')
            ? { type: 'call' }
            : { type: 'put' }
          : ''),
      })) as CatalogItem[]
    }
    const results = await Promise.all([
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'future', 'deribit')),
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'future', 'ftx')),
      ...['BTC', 'ETH'].map((i) => getActiveOptions(i, 'option', 'deribit')),
      // ...['BTC', 'ETH'].map((i) => getActiveOptions(i, 'option', 'ftx')),
    ])

    return results.flatMap((i) => i)
  },
  getExpiredCatalogOptionData: async (): Promise<CatalogItem[]> => {
    type CatalogResponse = {
      data: {
        count: number
        items: Array<{
          active: Array<boolean>
          availableSince: Array<string>
          baseAsset: Array<string>
          expiry: Array<string>
          index: Array<string>
          instrument: Array<string>
          listing: Array<string>
          quoteAsset: Array<string>
          type?: Array<string>
        }>
      }
      error?: string
    }
    async function performRequest(currency: string, exchange: string) {
      const URL = `${API_ROOT_URL}catalog/getData?exchange=${exchange}&currency=${currency}&assetType=option&active=false`
      const response = await fetch(URL, { mode: 'cors' })
      const data: CatalogResponse = await response.json()
      const item = data.data.items[0]
      const catalogItems: Array<CatalogItem> = item.active?.map(
        (active, i) => ({
          active: 'expired',
          availableSince: item.availableSince?.[i],
          baseAsset: item.baseAsset[i],
          expiry: item.expiry[i],
          instrument: item.instrument[i],
          listing: item.listing?.[i],
          quoteAsset: item.quoteAsset[i],
          type: item.type?.[i],
          assetType: 'option',
          exchange,
        }),
        [],
      )

      const activeCatalogItems = catalogItems?.sort(
        (catalogItem1, catalogItem2) => {
          return (
            new Date(catalogItem1.expiry).getTime() -
            new Date(catalogItem2.expiry).getTime()
          )
        },
      )

      return activeCatalogItems || []
    }

    const results = await Promise.all([
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'deribit')),
      ...['BTC', 'ETH'].map((i) => performRequest(i, 'ftx')),
    ])

    return results.reduce((acc: CatalogItem[], i) => {
      i.map((x) => acc.push(x as unknown as CatalogItem))
      return acc
    }, [])
  },
  getFutureConstantExpiry: async (
    ticker: Ticker,
    exchange: Source,
    instruments: Array<string>,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    // TODO: move API endpoint out to global and error on load
    const exchangeParam = exchange.toLowerCase()
    async function performRequest(instrumentName: string) {
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.future.${ticker}-${instrumentName}.1h.mid.px`

      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getFutureConstantExpiry returned ${resp.status}`)
      // }
      const data: PriceResponse = (await resp.json()) as PriceResponse
      const series = data?.data?.items
      if (!series) {
        return []
      }

      return series.map((item) => ({
        y: +item.px,
        x: new Date(+item.timestamp / 1000),
      }))
    }

    const results = await Promise.all(instruments.map(performRequest))
    return {
      successes: instruments.map((instrument, i) => {
        return {
          key: instrument,
          dataPoints: results[i],
        }
      }, {}),
    }
  },
  getFutureOpenInterest: async (
    exchange: Source,
    ticker: Ticker,
    instrument?: string,
  ): Promise<TimeSeriesData> => {
    if (!instrument) {
      return {
        key: '',
        dataPoints: [],
      }
    }
    // TODO: move API endpoint out to global and error on load
    const exchangeParam = exchange.toLowerCase()
    const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.future.${ticker}-${instrument}.1h.oi`

    const resp = await fetch(URL, { mode: 'cors' })
    // if (!resp.ok) {
    //   throw new Error(`getFutureOpenInterest returned ${resp.status}`)
    // }
    const data: OpenInterestResponse =
      (await resp.json()) as OpenInterestResponse

    if (!data?.data?.items) {
      return {
        key: '',
        dataPoints: [],
      }
    }

    return {
      key: instrument,
      dataPoints: data.data.items.map((item) => ({
        y: +item.oi,
        x: new Date(+item.timestamp / 1000),
      })),
    }
  },
  getHistoricalSeries: async (
    queries: QueryRequest[],
    timestampRange: DateRange,
  ): Promise<MultiHistoricalAnalyzerQueryResponse> => {
    const url = haEndpoint
    async function performFetch(query: QueryRequest) {
      const resp = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(query.ast),
      })

      try {
        const data = (await resp.json()) as HistoricalAnalyzerResponse
        if (!resp.ok) {
          const error = data?.message || resp.status
          return {
            error,
          }
        }

        if (isHaRegressionResponse(data)) {
          return {
            kind: 'regression',
            data: {
              scatter: data.values[0].map((xVal, i) => ({
                x: xVal,
                y: data.values[1][i],
              })),
              line: data.values[0].map((xVal, i) => ({
                x: xVal,
                y: data.values[2][i],
              })),
              alpha: data.values[3],
              beta: data.values[4],
              rSquared: data.values[5],
            },
          }
        }
        if (isHaCdfResponse(data)) {
          return {
            kind: 'cdf',
            data: data.values[0].map((xVal, i) => ({
              x: xVal,
              y: data.values[1][i],
            })),
            percentiles: [
              data.values[2][4],
              data.values[2][24],
              data.values[2][49],
              data.values[2][74],
              data.values[2][94],
            ],
          }
        }
        if (isHaScalarResponse(data)) {
          if (data.timestamps.length) {
            return {
              kind: 'timeseries',
              data: data.timestamps.map((timestamp) => ({
                x: new Date(timestamp / 1000),
                y: data.values,
              })),
            }
          }
          return {
            kind: 'timeseries',
            data: [
              { x: timestampRange.from, y: data.values },
              { x: timestampRange.until, y: data.values },
            ],
          }
        }
        if (isHaVectorResponse(data)) {
          if (!data.timestamps.length) {
            return { error: 'timeseries not found' }
          }
          return {
            kind: 'timeseries',
            data: (data.timestamps || []).map((timestamp, i) => ({
              x: new Date(timestamp / 1000),
              y: data.values[i],
            })),
          }
        }
        return { data: [] }
      } catch (error) {
        return { error }
      }
    }

    const results = await Promise.all(queries.map(performFetch))
    return queries.reduce(
      (acc, curr, i) => {
        const output = results[i]
        if (output.error) {
          acc.errors.set(curr.query, output.error)
        } else {
          acc.successes.set(curr.query, output)
        }
        return acc
      },
      { successes: new Map(), errors: new Map() },
    )
  },
  getPerpetualSwap: async (
    exchange: Source,
    tickers: Array<Ticker>,
    priceType: 'oi' | 'mid.px',
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const exchangeParam = exchange.toLowerCase()

    async function performFetch(ccy: string) {
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.perpetual.${ccy}-PERPETUAL.1h.${priceType}`

      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getPerpetualSwap returned ${resp.status}`)
      // }
      if (priceType === 'oi') {
        const data: OpenInterestResponse =
          (await resp.json()) as OpenInterestResponse

        if (!data?.data?.items) {
          return []
        }

        return data.data.items.map((item) => ({
          y: +item.oi,
          x: new Date(+item.timestamp / 1000),
        }))
      }
      const data: PriceResponse = (await resp.json()) as PriceResponse

      if (!data?.data?.items) {
        return []
      }

      return data.data.items.map((item) => ({
        y: +item.px,
        x: new Date(+item.timestamp / 1000),
      }))
    }

    const results = await Promise.all(tickers.map(performFetch))
    return {
      successes: tickers.map((ticker, i) => {
        return {
          key: ticker,
          dataPoints: results[i],
        }
      }, {}),
    }
  },
  getImpliedYield: async (
    source: Source,
    currency: Ticker,
    basis: CurveBasis,
    tenors: Array<ConstantTenor>,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const exchangeParam = source.toLowerCase()

    async function performFetch(tenor: ConstantTenor) {
      const tenorInDays = TenorDays[tenor]
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.future.${currency}.${tenorInDays}d.${basis}.pct`
      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getImpliedYield returned ${resp.status}`)
      // }
      const data: YieldResponse = (await resp.json()) as YieldResponse

      if (!data?.data?.items) {
        return []
      }

      return data.data.items.map((item) => ({
        y: +item.px,
        x: new Date(+item.timestamp / 1000),
      }))
    }

    const results = await Promise.all(tenors.map(performFetch))
    return {
      successes: tenors.map((tenor, i) => {
        return {
          key: tenor,
          dataPoints: results[i],
        }
      }, {}),
    }
  },
  getFutureTermStructure: async (
    exchange: Source,
    currency: Ticker,
    dates: Array<string>,
  ): Promise<MultiTermStructure> => {
    const exchangeParam = exchange.toLowerCase()

    async function performFetch(dateParam: string) {
      const URL = `https://rv9gb4donc.execute-api.eu-west-2.amazonaws.com/Stage/getFuturesCurve?mode=floating_tenor&date=${dateParam}&exchange=${exchangeParam}&currency=${currency}`
      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getFutureTermStructure returned ${resp.status}`)
      // }
      const data: TermStructureResponse =
        (await resp.json()) as TermStructureResponse

      if (!data?.instruments) {
        return {
          timestamp: dateParam,
          data: [],
        }
      }

      const instruments = data.instruments.map((instrument, i) => {
        return {
          instrument,
          yield: +data.yields[i],
          dateOffset: +data.expiries[i] * MAX_TERM_DAYS,
        }
      })
      instruments.sort((a, b) => a.dateOffset - b.dateOffset)

      const returnTimestamp =
        dateParam === 'LATEST'
          ? new Date(+data.timestamp / 1000).toISOString()
          : dateParam

      return {
        timestamp: returnTimestamp,
        data: instruments,
      }
    }

    const results = await Promise.all(dates.map(performFetch))
    return results.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.timestamp]: curr.data,
      }
    }, {})
  },
  getTermStructure: async (
    exchange: Source,
    currency: Ticker,
    dates: Array<number | 'LATEST'>,
    series: Series,
    assetType = 'future',
  ): Promise<MultiTermStructure> => {
    type FetchTermStructureResponse = {
      timestamp: string
      data: Array<{
        instrument: string
        yield: number
        price: number
        dateOffset: number
      }>
    }
    async function performFetch(date: number | 'LATEST', seriesParam: Series) {
      const endpoint = SeriesEndpointMap[seriesParam]
      let URL = `${API_ROOT_URL}timeseries/${endpoint}?exchange=${exchange.toLowerCase()}&currency=${currency}&assetType=${assetType}`
      if (date !== 'LATEST') {
        URL += `&timestamp=${date * 1000}`
      }
      const resp = await fetch(URL, { mode: 'cors' })

      let response: ConstantMaturityFuturesResponse | undefined
      try {
        response = (await resp.json()) as ConstantMaturityFuturesResponse
      } catch (err) {
        console.error('error getting term structure with params', {
          date,
          seriesParam,
          exchange,
          currency,
          assetType,
        })
      }
      if (!response || response.error) {
        return undefined
      }

      const {
        data: { items },
      } = response

      // clean up validation of responses
      const validItems = items.filter((item) => Object.keys(item).length)

      const instruments = validItems.map((item, i) => {
        return {
          instrument: item.qualified_name.split('.')[3],
          yield: item.yield,
          price: item.future,
          dateOffset: item.expiry * MAX_TERM_DAYS,
        }
      })
      if (!instruments.length) {
        return undefined
      }
      instruments.sort((a, b) => a.dateOffset - b.dateOffset)

      const returnTimestamp = new Date(
        validItems[0]?.timestamp / 1000,
      ).toISOString()

      return {
        timestamp: returnTimestamp,
        data: instruments,
      }
    }
    const results = await Promise.all(
      dates.map((date) => performFetch(date, series)),
    )
    const filteredResults = results.filter(
      (result): result is FetchTermStructureResponse => !!result,
    )
    return filteredResults.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.timestamp]: curr.data,
      }
    }, {})
  },
  getVolume: async (
    source: Source,
    assetClass: AssetClass,
    ticker: Ticker,
    instruments: Array<string>,
  ): Promise<MultiTimeSeriesData> => {
    const exchangeParam = source.toLowerCase()
    const assetClassParam = assetClass.toLowerCase()

    async function performFetch(instrument: string) {
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.${assetClassParam}.${ticker}-${instrument}.1h.volume.sum`
      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getVolume returned ${resp.status}`)
      // }
      const data: AmountResponse = (await resp.json()) as AmountResponse

      if (!data?.data?.items) {
        return []
      }

      return data.data.items.map((item) => ({
        y: item.amt ? item.amt : item.sum,
        x: new Date(+item.timestamp / 1000),
      }))
    }

    const results = await Promise.all(instruments.map(performFetch))
    return instruments.map((instrument, i) => {
      return {
        key: instrument,
        dataPoints: results[i],
      }
    }, {})
  },
  getOptionSmile: async (
    source: Source,
    ticker: Ticker,
    models: Array<Model>,
    dates: Array<string>,
    tenors: Array<string>,
    seriesType: OptionsSmileSeriesType,
    getLatest = false,
  ): Promise<MultiTermStructure> => {
    const exchangeParam = source.toLowerCase()

    async function performFetch(tenor: string, model: Model, date?: string) {
      const tenorDays = TenorDays[tenor]
      const URL = `${API_ROOT_URL}timeseries/getData?key=${exchangeParam}.option.${ticker}.${model}.${tenorDays}d.delta.smile&timestamp=${
        date ? new Date(date).getTime() * 1000 : 'LATEST'
      }`
      const resp = await fetch(URL, { mode: 'cors' })
      // if (!resp.ok) {
      //   throw new Error(`getOptionSmile returned ${resp.status}`)
      // }
      const data: SmileResponse = (await resp.json()) as SmileResponse
      const series = data?.data?.items?.[0]

      if (!series) {
        return {
          tenor,
          data: [],
        }
      }

      return {
        timestamp: series.timestamp,
        tenor,
        date,
        model,
        data: SMILE_CATEGORIES.map((c) => series[c]),
      }
    }

    let queryParams: Array<[tenor: string, model: Model, date?: string]> = []

    // TODO: all these if statements are a bad smell - refactor this
    if (getLatest) {
      queryParams = [[tenors[0], models[0]]]
    } else if (
      seriesType === OptionsSmileSeriesType.DATE ||
      seriesType === OptionsSmileSeriesType.DEFAULT
    ) {
      queryParams = dates.map((date) => [tenors[0], models[0], date])
    } else if (seriesType === OptionsSmileSeriesType.MODEL) {
      queryParams = models.map((model) => [tenors[0], model, dates[0]])
    } else if (seriesType === OptionsSmileSeriesType.TENOR) {
      queryParams = tenors.map((tenor) => [tenor, models[0], dates[0]])
    }

    const results = await Promise.all(
      queryParams.map((params) =>
        performFetch(params[0], params[1], params[2]),
      ),
    )

    return results.reduce((acc, curr) => {
      if (!curr.model || !curr.tenor) {
        return acc
      }
      let seriesKey = dateFormatter.format(new Date(curr.timestamp / 1000))
      if (seriesType === OptionsSmileSeriesType.TENOR) {
        seriesKey = curr.tenor
      } else if (seriesType === OptionsSmileSeriesType.MODEL) {
        seriesKey = curr.model
      }
      return {
        ...acc,
        [seriesKey]: curr.data,
      }
    }, {})
  },
  getOptionVolatilityRatio: async (
    source: Source,
    ticker: Ticker,
    model: Model,
    tenors: Array<string>,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const exchangeParam = source.toLowerCase()

    async function performFetch(tenor: string) {
      const tenorDays = TenorDays[tenor]

      const startDate = START_OF_BLOCKSCHOLES_TIME
      const endDate = TODAY_UTC

      let URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${exchangeParam}.option.${ticker}.${model}.${tenorDays}d.vol.ratio`
      URL += `&startTime=${+startDate * 1000}&endTime=${+endDate * 1000}`
      const resp = await fetch(URL, { mode: 'cors' })
      if (!resp.ok) {
        throw new Error(`getOptionVolatilityRatio returned ${resp.status}`)
      }
      const output: VolatilityRatioResponse =
        (await resp.json()) as VolatilityRatioResponse

      if (!output) {
        return {
          tenor,
          data: [],
        }
      }

      const points = output.data.items[0].timestamp.map((timestamp, i) => {
        return {
          x: new Date(timestamp / 1000),
          y: output.data.items[0].ratio[i],
        }
      })

      return {
        tenor,
        data: points,
      }
    }

    const results = await Promise.all(tenors.map(performFetch))
    return {
      successes: results.map((cur) => {
        return {
          key: cur.tenor,
          dataPoints: cur.data,
        }
      }, {}),
    }
  },
  getOptionsPrice: async (
    source: Source,
    ticker: Ticker,
    model: Model,
    strike: number,
    expiry: Date,
    type: OptionType,
  ): Promise<OptionsPricerOutput> => {
    const exchangeParam = source.toLowerCase()
    const tickerParam = ticker
    const expiryParam = expiry.toISOString() // FIXME handle constant tenors
    const typeParam = OptionTypePricerMapping[type]
    const URL = `${optionsPricerEndpoint}?exchange=${exchangeParam}&currency=${tickerParam}&strike=${strike}&expiry=${expiryParam}&model=${model}&type=${typeParam}`

    const resp = await fetch(URL, { mode: 'cors' })

    const data: OptionsPricerResponse =
      (await resp.json()) as OptionsPricerResponse

    return { data }
  },
  getOptionPutCallSkew: async (
    source: Source,
    ticker: Ticker,
    model: Model,
    dateRange: DateRange,
    tenor: number,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const exchangeParam = source.toLowerCase()
    const from = dateRange.from.getTime() * 1000
    const until = dateRange.until.getTime() * 1000

    const URL = `${API_ROOT_URL}timeseries/getHistoricTimeseriesData?key=${exchangeParam}.option.${ticker}.${model}.${tenor}d.skew&startTime=${from}&endTime=${until}`
    const resp = await fetch(URL, { mode: 'cors' })
    // if (!resp.ok) {
    //   throw new Error(`getOptionPutCallSkew returned ${resp.status}`)
    // }
    const result: PutCallSkewResponse =
      (await resp.json()) as PutCallSkewResponse
    const series = result?.data?.items?.[0]
    if (
      !series?.timestamp ||
      !series[0.05] ||
      !series[0.1] ||
      !series[0.2] ||
      !series[0.25] ||
      !series[0.4]
    ) {
      return {
        successes: [
          { key: '0.05', dataPoints: [] },
          { key: '0.1', dataPoints: [] },
          { key: '0.2', dataPoints: [] },
          { key: '0.25', dataPoints: [] },
          { key: '0.4', dataPoints: [] },
        ],
      }
    }

    return {
      successes: [
        {
          key: '0.05',
          dataPoints: series['0.05'].map((item, i) => ({
            x: new Date(series.timestamp[i] / 1000),
            y: item,
          })),
        },
        {
          key: '0.1',
          dataPoints: series['0.1'].map((item, i) => ({
            x: new Date(series.timestamp[i] / 1000),
            y: item,
          })),
        },
        {
          key: '0.2',
          dataPoints: series['0.2'].map((item, i) => ({
            x: new Date(series.timestamp[i] / 1000),
            y: item,
          })),
        },
        {
          key: '0.25',
          dataPoints: series['0.25'].map((item, i) => ({
            x: new Date(series.timestamp[i] / 1000),
            y: item,
          })),
        },
        {
          key: '0.4',
          dataPoints: series['0.4'].map((item, i) => ({
            x: new Date(series.timestamp[i] / 1000),
            y: item,
          })),
        },
      ],
    }
  },
  getHistoricalModelParams: async (
    source: Source,
    currency: Ticker,
    model: Model,
    tenors: Array<ConstantTenorByDays>,
    param: SVIParam | SABRParam,
  ): Promise<MultiSeriesResult<TimeSeriesData>> => {
    const queries: Array<ModelParametersDatetimeRangeParams> = tenors.map(
      (tenor) => {
        return {
          model,
          exchange: source.toLowerCase(),
          currency,
          start: START_OF_BLOCKSCHOLES_TIME.toISOString(),
          end: TODAY_UTC.toISOString(),
          tenor,
        }
      },
    )
    const results = await Promise.all(
      queries.map(getHistoricalModelParams),
    ).then((res) => {
      const successes = res.filter(
        (res): res is HistoricImpliedVolSeries => !(res instanceof Error),
      )
      const queryErrors = res.filter(
        (res): res is Error => res instanceof Error,
      )
      if (queryErrors.length) {
        return { error: queryErrors }
      }
      const series = successes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.key]: cur.dataPoints,
        }
      }, {} as Record<string, Array<HistoricImpliedVolParamsWithDate>>)

      return series
    })
    const activeSeries = tenors.map((tenor) => {
      const dataPoints = results[tenor].map((value) => ({
        x: value.timestamp,
        y: value[param],
      }))
      return {
        key: tenor,
        dataPoints,
      }
    })

    return {
      successes: activeSeries,
    }
  },
  getOptionsImpliedVol: async (
    source: Source,
    currency: Ticker,
    model: Model,
    dates: Array<string>,
  ): Promise<Record<string, ModelParametersSeries> | undefined> => {
    const queries: Array<ModelParametersSpecifiedDatetimeParams> = dates.map(
      (date) => {
        return {
          model,
          date,
          exchange: source.toLowerCase(),
          currency,
        }
      },
    )
    const results = await Promise.all(queries.map(getModelParametersData))
    const successes = results.filter(
      (result): result is ModelParametersSeries => !(result instanceof Error),
    )
    const queryErrors = results.filter(
      (result): result is Error => result instanceof Error,
    )
    if (queryErrors.length) {
      console.error(queryErrors)
      return undefined
    }
    const series = successes.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.key]: cur.dataPoints,
      }
    }, {})

    return series
  },
}
