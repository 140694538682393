import { MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout'
import { useAuthenticator } from '@aws-amplify/ui-react'
import styled from 'styled-components'
import LiveDataFeed from 'components/live'
import logo from 'images/logo.svg'
import { useLivePrices } from 'components/live/useLivePrices'

const StyledMenu = styled(Menu)`
  .k-menu {
    font-weight: 700;
  }
  .k-menu:not(.k-context-menu) > li.k-item {
    color: ${(props) => props.theme.palette.common.grey6};
    transition: color 300ms;
  }
  .k-menu:not(.k-context-menu) > li.k-item:hover {
    color: ${(props) => props.theme.palette.primary.contrastText};
    transition: color 150ms;
  }
  border-bottom: 2px solid ${(props) => props.theme.palette.common.kendoGrey};
  & > ul.k-menu.k-menu-horizontal:not(.k-context-menu) {
    padding-left: 1.5rem;
  }
  & span.k-menu-expand-arrow {
    padding-top: 4px;
  }
`
const MenuNavContainer: React.FC = () => {
  const navigate = useNavigate()
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const isBsUser = !!user?.attributes?.email.includes('@blockscholes.com')
  const liveData = useLivePrices(isBsUser)
  const onSelect = (event: MenuSelectEvent) => {
    if (event.item.data) {
      navigate(event.item.data.route)
    }
  }

  const handleSignOutClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    signOut()
  }

  return (
    <div className="row justify-content-center">
      <div className="col-24 flex-grow-1 text-center">
        <StyledMenu onSelect={onSelect} openOnClick>
          <MenuItem
            data={{ route: '/' }}
            render={() => (
              <img
                src={logo}
                alt="logo"
                style={{ height: '32px', width: '32px', padding: 0 }}
              />
            )}
          />
          <MenuItem text="Home" data={{ route: '/' }} cssClass="highlight" />
          <MenuItem
            text="Historical Analyzer"
            data={{ route: '/historical-analyzer' }}
            cssClass="highlight"
          />
          <MenuItem
            text="Option Pricer"
            data={{ route: '/option-pricer' }}
            cssClass="highlight"
          />
          {Object.keys(liveData).length > 0 && (
            <MenuItem
              key="live-data"
              disabled
              cssClass="nav-live-feed"
              render={() => (
                <span style={{ margin: '0 auto' }}>
                  <LiveDataFeed prices={liveData} />
                </span>
              )}
            />
          )}
          {user && (
            <MenuItem text={user.username} cssStyle={{ marginLeft: 'auto' }}>
              <MenuItem
                text="Sign out"
                render={({ item }) => (
                  <span
                    className="k-menu-link-text"
                    role="menuitem"
                    onClick={handleSignOutClick}
                  >
                    {item.text}
                  </span>
                )}
              />
            </MenuItem>
          )}
        </StyledMenu>
      </div>
    </div>
  )
}

export default MenuNavContainer
