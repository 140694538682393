import 'hammerjs'
import { useTheme } from 'styled-components'
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
} from '@progress/kendo-react-charts'

const categories = [2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011]
const series = [
  {
    name: 'PREMIUM',
    data: [3.907, 7.943, 7.848, 9.284, 9.263, 9.801, 3.89, 8.238, 9.552, 6.855],
    id: 1,
  },
  {
    name: 'DELTA',
    data: [4.743, 7.295, 7.175, 6.376, 8.153, 8.535, 5.247, -7.832, 4.3, 4.3],
    id: 2,
  },
  {
    name: 'BETA',
    data: [0.01, -0.375, 1.161, 0.684, 3.7, 3.269, 1.083, -5.127, 3.69, 2.995],
    id: 3,
  },
  {
    name: 'RHO',
    data: [
      1.988, 2.733, 3.994, 3.464, 4.001, 3.939, 1.333, -2.245, 4.339, 2.727,
    ],
    id: 4,
  },
]

interface IChartContainerProps {
  title: string
}

const ChartContainer: React.FC<IChartContainerProps> = (props) => {
  const { title } = props

  const theme = useTheme()

  return (
    <Chart>
      <ChartTitle text={title} color={theme.palette.primary.light} />
      <ChartLegend
        position="top"
        orientation="horizontal"
        labels={{ color: '#0a0' }}
      />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          categories={categories}
          startAngle={45}
          labels={{ color: '#0a0' }}
        />
      </ChartCategoryAxis>
      <ChartSeries>
        {series.map((item) => (
          <ChartSeriesItem
            key={item.id}
            type="line"
            data={item.data}
            name={item.name}
          />
        ))}
      </ChartSeries>
    </Chart>
  )
}

export default ChartContainer
