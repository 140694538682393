import React, { useCallback, useContext, useEffect, useState } from 'react'

import { BSLANG } from '@blockscholes/ql'
import { EditorState } from '@codemirror/state'
import { TooltipContext } from '@progress/kendo-react-charts'
import { Loader } from '@progress/kendo-react-indicators'
import { formatNumber } from '@telerik/kendo-intl'
import { Dropdown } from 'components/atoms'
import { DatePicker, DateTimePicker } from 'components/atoms/DatePickers'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import {
  getCssColorValueForHeatmapCell,
  HeatMapLegend,
} from 'components/common/HeatMapLegend'
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  DaysTenor,
  HEATMAP_COLORS,
  HEATMAP_NEGATIVE_COLOR,
  HEATMAP_POSITIVE_COLOR,
  HEATMAP_ZERO_COLOR,
  START_OF_BLOCKSCHOLES_TIME,
  TODAY_UTC,
} from 'consts'
import { HistoricalAnalyzerContext } from 'context/HistoricalAnalyzerContext'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import Query from 'pages/HistoricalAnalyzer/query'
import { useNavigate } from 'react-router-dom'
import { getOptionsHeatmapData } from 'services'
import { HeatMapData, Source, Ticker } from 'types'
import { Model } from 'types/models'
import HeatMapChart from './HeatMapChart'
import ChartLoader from './ChartLoader'

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: Model.SABR,
  },
  {
    name: 'SVI',
    value: Model.SVI,
  },
]

const tooltipRenderer = (props: TooltipContext) => {
  if (!props.point) {
    return <></>
  }
  const { dataItem } = props.point
  return (
    <table>
      <tr>
        <td>Expiry</td>
        <td>{DaysTenor[dataItem.expiry]}</td>
      </tr>
      <tr>
        <td>Value</td>
        <td>{formatNumber(dataItem.value, 'p2')}</td>
      </tr>
      <tr>
        <td>Strike</td>
        <td>{dataItem.strike.toUpperCase()}</td>
      </tr>
      <tr>
        <td>Z-Score</td>
        <td>{formatNumber(dataItem.zscore, 'n2')}</td>
      </tr>
    </table>
  )
}
const CATEGORIES = {
  x: ['7', '30', '90', '120', '180', '270', '365', '730'],
  y: ['-0.1', '-0.25', 'atm', '0.25', '0.1'],
}

const labelForQueryNum = (queryNum: number): string =>
  String.fromCharCode(queryNum + 65)

const OptionsHeatMap: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [activeModel, setActiveModel] = useState<Model>(Model.SVI)
  const [series, setSeries] = useState<Array<HeatMapData>>([])
  const [date, setDate] = useState<Date | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const haContext = useContext(HistoricalAnalyzerContext)
  const { createQuery, state } = haContext
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getOptionsHeatmapData(source, ticker, activeModel, date)
      .then((results) => {
        if (!date) {
          const latestDate = new Date(results[0].timestamp / 1000)
          setDate(latestDate)
        }
        setSeries(results)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [source, ticker, activeModel, date])

  const handleCellClick = (item: HeatMapData) => {
    if (!item.timeseries?.series) {
      return
    }
    const field_to_display = {
      '-0.01': '-1delta',
      '-0.05': '-5delta',
      '-0.1': '-10delta',
      '-0.2': '-20delta',
      '-0.25': '-25delta',
      '-0.4': '-40delta',
      '-0.5': '-50delta',
      '0.01': '1delta',
      '0.05': '5delta',
      '0.1': '10delta',
      '0.2': '20delta',
      '0.25': '25delta',
      '0.4': '40delta',
      '0.5': '50delta',
      atm: 'atm',
    }
    const text = `${item.timeseries.series}.${
      field_to_display[item.timeseries.field]
    }`
    const editorState = EditorState.create({
      doc: text,
      extensions: [BSLANG()],
    })
    const parser = new Parser(editorState, state.sources)
    // todo: error handling
    const query = new Query(
      text,
      labelForQueryNum(state.queryCount),
      parser.parseTree,
    )

    createQuery(query, parser)
    navigate('/historical-analyzer')
  }

  const zScores = series.map((item) => item.zscore)
  const min = Math.min(...zScores)
  const max = Math.max(...zScores)

  const colorCell = useCallback(
    (cell: HeatMapData) =>
      getCssColorValueForHeatmapCell(cell.zscore, min, max),
    [min, max],
  )
  return (
    <ChartWrapper
      legend={
        isLoading ? undefined : (
          <HeatMapLegend
            valueColorList={[
              {
                value: min.toPrecision(3),
                color: HEATMAP_NEGATIVE_COLOR,
              },
              { value: 0, color: HEATMAP_ZERO_COLOR },
              {
                value: max.toPrecision(3),
                color: HEATMAP_POSITIVE_COLOR,
              },
            ]}
            title="Z-Score"
          />
        )
      }
      title="Implied Vol Surface"
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
            rootClassNames="k-mr-4"
          />
          <Dropdown
            showLabel
            current={MODEL_OPTIONS.find(({ value }) => value === activeModel)}
            options={MODEL_OPTIONS}
            onChange={setActiveModel}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Model"
          />
          <div style={{ marginLeft: 'auto' }}>
            <DateTimePicker
              value={date}
              fillMode="flat"
              min={START_OF_BLOCKSCHOLES_TIME}
              format={DATETIME_FORMAT}
              max={TODAY_UTC}
              onChange={(e) => {
                if (!e.value) {
                  return
                }
                setDate(e.value)
              }}
            />
          </div>
        </>
      }
      chart={
        <>
          {isLoading ? (
            <ChartLoader />
          ) : (
            <HeatMapChart
              data={series}
              color={colorCell}
              categories={CATEGORIES}
              tooltipRenderer={tooltipRenderer}
              xAxisTitle="Expiry"
              yAxisTitle="Delta"
              onCellClick={handleCellClick}
            />
          )}
        </>
      }
    />
  )
}

export default OptionsHeatMap
