import { CatalogContext } from 'context/CatalogContext'
import React, { useContext, useEffect, useState } from 'react'

import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import SeriesSelector from 'components/common/SeriesSelector'
import DateRangeSelector from 'components/common/DateRangeSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import RadioButtonComponent from 'components/atoms/RadioButton'
import {
  AssetClassSource,
  ConstantTenor,
  CONSTANT_TENORS,
  IChartFrequencyData,
  Source,
  TEMP_INSTRUMENTS,
  Ticker,
} from 'types'
import AssetClass from 'types/assetClass'
import ChartLoader from '../ChartLoader'
import ExpiresChartWrapper from './ExpiresChartWrapper'
import TenorsChartWrapper from './TenorsChartWrapper'

enum ConstantInstrumentDuration {
  Expiry = 'ConstantExpiry',
  Tenor = 'ConstantTenor',
}

const DurationDropdownOptions = [
  {
    name: 'Listed',
    value: ConstantInstrumentDuration.Expiry,
  },
  {
    name: 'Constant',
    value: ConstantInstrumentDuration.Tenor,
  },
]

const FutureHistoricalPrice: React.FC = () => {
  const catalog = useContext(CatalogContext)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [source, setSource] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )
  const [durationType, setDurationType] = useState(
    ConstantInstrumentDuration.Tenor,
  )
  const [activeTenors, setActiveTenors] = useState<Array<ConstantTenor>>([
    '1M',
    '3M',
    // '6M',
    // disabling 1Y tenor due to gaps in series
    // '1Y',
  ])
  const [activeExpiries, setActiveExpiries] = useState<Array<string>>([
    ...TEMP_INSTRUMENTS,
  ])

  // useEffect(() => {
  //   setActiveExpiries(
  //     catalog.data
  //       .filter((item) => item.active)
  //       .map((item) => item.instrument.split('-')[1]),
  //   )
  // }, [catalog])

  const handleTenorsChange = (tenors: Array<ConstantTenor>) => {
    if (!tenors.length) {
      return
    }
    setActiveTenors(tenors)
  }

  const handleExpiriesChange = (expiries: Array<string>) => {
    if (!expiries.length) {
      return
    }
    setActiveExpiries(expiries)
  }

  const title = 'Futures prices'
  return (
    <ChartWrapper
      title={title}
      singleRow={false}
      controls={
        <>
          <DataChooser
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
            source={source}
            handleSourceChange={setSource}
            supportedSources={AssetClassSource[AssetClass.FUTURE]}
          />
          <RadioButtonComponent
            showLabel
            current={DurationDropdownOptions.find(
              ({ value }) => value === durationType,
            )}
            options={DurationDropdownOptions}
            onChange={setDurationType}
            label="Expiry"
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      series={
        <>
          {durationType === ConstantInstrumentDuration.Expiry && (
            <SeriesSelector
              options={[...TEMP_INSTRUMENTS]}
              value={activeExpiries}
              handleChange={handleExpiriesChange}
            />
          )}
          {durationType === ConstantInstrumentDuration.Tenor && (
            <SeriesSelector
              options={[...CONSTANT_TENORS.filter((tenor) => tenor !== '1Y')]}
              value={activeTenors}
              handleChange={handleTenorsChange}
            />
          )}
        </>
      }
      chart={
        catalog.fetching ? (
          <ChartLoader />
        ) : (
          <>
            {durationType === ConstantInstrumentDuration.Expiry ? (
              <ExpiresChartWrapper
                ticker={ticker}
                source={source}
                frequency={frequency}
                activeExpiries={activeExpiries}
                title={title}
              />
            ) : (
              <TenorsChartWrapper
                ticker={ticker}
                source={source}
                frequency={frequency}
                activeTenors={activeTenors}
                title={title}
              />
            )}
          </>
        )
      }
    />
  )
}

export default FutureHistoricalPrice
