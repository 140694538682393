export enum SVIParam {
  A = 'sviA',
  B = 'sviB',
  Rho = 'sviRho',
  M = 'sviM',
  Sigma = 'sviSigma',
  Vol = 'sviVol',
}

export enum SABRParam {
  Rho = 'sabrRho',
  Alpha = 'sabrAlpha',
  VolVol = 'sabrVolVol',
  Vol = 'sabrVol',
}

export enum Model {
  SVI = 'SVI',
  SABR = 'SABR',
}
