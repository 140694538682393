import { FREQUENCIES } from 'components/molecules/FrequencyChange'
import { SIX_MONTHS_AGO, TODAY_UTC } from 'consts'
import { omit } from 'lodash'
import { Parser } from 'pages/HistoricalAnalyzer/parser'
import Query from 'pages/HistoricalAnalyzer/query'
import {
  AxisNames,
  IHistoricalAnalyzerDataSource,
  HistChartTitle,
} from 'pages/HistoricalAnalyzer/types'
import { createContext, useReducer } from 'react'
import {
  DateRange,
  HistoricalAnalyzerQuerySeries,
  IChartFrequencyData,
} from 'types'

type HistoricalAnalyzerState = {
  queries: Record<string, Query>
  querySeriesMapping: HistoricalAnalyzerQuerySeries
  sources: Array<IHistoricalAnalyzerDataSource>
  timestampRange: DateRange
  queryCount: number
  chartFrequency: IChartFrequencyData
  chartAxisNames: AxisNames
  histChartTitle: HistChartTitle
  updateChartBtnStatus: boolean
}

const initialState: HistoricalAnalyzerState = {
  queries: {},
  querySeriesMapping: new Map(),
  sources: [],
  timestampRange: { from: SIX_MONTHS_AGO, until: TODAY_UTC },
  queryCount: 0,
  chartFrequency: FREQUENCIES['1D'],
  chartAxisNames: { left: '', right: '' },
  histChartTitle: '',
  updateChartBtnStatus: false,
}

export const HistoricalAnalyzerContext = createContext({
  state: initialState,
  createQuery: (query: Query, parsed: Parser) => {},
  editQuery: (query: Query) => {},
  deleteQuery: (query: Query) => {},
  setQuerySeriesMapping: (
    querySeriesMapping: HistoricalAnalyzerQuerySeries,
  ) => {},
  setTimestampRange: (range: DateRange) => {},
  setChartFrequency: (freq: IChartFrequencyData) => {},
  setChartAxisNames: (updated: AxisNames) => {},
  setHistChartTitle: (updatedTitle: HistChartTitle) => {},
  setUpdateChartBtnStatus: (updated: boolean) => {},
})

export enum HistoricalAnalyzerActionKind {
  CREATE_QUERY = 'CREATE_QUERY',
  EDIT_QUERY = 'EDIT_QUERY',
  DELETE_QUERY = 'DELETE_QUERY',
  SET_QUERY_SERIES_MAPPING = 'SET_QUERY_SERIES_MAPPING',
  SET_TIMESTAMP_RANGE = 'SET_TIMESTAMP_RANGE',
  SET_CHART_FREQUENCY = 'SET_CHART_FREQUENCY',
  SET_CHART_AXIS_NAMES = 'SET_CHART_AXIS_NAMES',
  SET_HIST_COLOR = 'SET_HIST_COLOR',
  SET_HIST_CHART_TITLE = 'SET_HIST_CHART_TITLE',
  SET_SHOW_DATA_SEARCH = 'SET_SHOW_DATA_SEARCH',
  SET_UPDATE_CHART_BTN_STATUS = 'SET_UPDATE_CHART_BTN_STATUS',
}

interface HistoricalAnalyzerAction {
  type: HistoricalAnalyzerActionKind
  payload: any
}

const reducer = (
  state: HistoricalAnalyzerState,
  action: HistoricalAnalyzerAction,
) => {
  const { type, payload } = action
  switch (type) {
    case HistoricalAnalyzerActionKind.CREATE_QUERY: {
      return {
        ...state,
        queries: {
          ...state.queries,
          [payload.query.id]: payload.query,
        },
        sources: [
          ...state.sources,
          { label: payload.query.label, type: payload.parsed.rootType },
        ],
        queryCount: state.queryCount + 1,
      }
    }
    case HistoricalAnalyzerActionKind.EDIT_QUERY: {
      // FIXME: query type might change
      return {
        ...state,
        queries: { ...state.queries, [payload.query.id]: payload.query },
      }
    }
    case HistoricalAnalyzerActionKind.DELETE_QUERY: {
      const newQueries = omit(state.queries, payload.query.id)
      const newQuerySeriesMapping = new Map(state.querySeriesMapping)
      newQuerySeriesMapping.delete(payload.query)
      return {
        ...state,
        queries: newQueries,
        querySeriesMapping: newQuerySeriesMapping,
      }
    }
    case HistoricalAnalyzerActionKind.SET_QUERY_SERIES_MAPPING: {
      return {
        ...state,
        querySeriesMapping: payload.querySeriesMapping,
      }
    }
    case HistoricalAnalyzerActionKind.SET_TIMESTAMP_RANGE: {
      return {
        ...state,
        timestampRange: payload.range,
      }
    }
    case HistoricalAnalyzerActionKind.SET_CHART_FREQUENCY: {
      return {
        ...state,
        chartFrequency: payload.chartFrequency,
      }
    }
    case HistoricalAnalyzerActionKind.SET_CHART_AXIS_NAMES: {
      return {
        ...state,
        chartAxisNames: payload.chartAxisNames,
      }
    }
    case HistoricalAnalyzerActionKind.SET_HIST_CHART_TITLE: {
      return {
        ...state,
        histChartTitle: payload.histChartTitle,
      }
    }
    case HistoricalAnalyzerActionKind.SET_UPDATE_CHART_BTN_STATUS: {
      return {
        ...state,
        updateChartBtnStatus: payload.updateChartBtnStatus,
      }
    }
    default: {
      return state
    }
  }
}

interface IHistoricalAnalyzerProviderProps {
  children: React.ReactNode
}

export const HistoricalAnalyzerProvider: React.FC<IHistoricalAnalyzerProviderProps> =
  (props: IHistoricalAnalyzerProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { children } = props
    return (
      <HistoricalAnalyzerContext.Provider
        value={{
          state,
          createQuery: (query: Query, parsed: Parser) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.CREATE_QUERY,
              payload: { query, parsed },
            }),
          editQuery: (query: Query) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.EDIT_QUERY,
              payload: { query },
            }),
          deleteQuery: (query: Query) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.DELETE_QUERY,
              payload: { query },
            }),
          setQuerySeriesMapping: (
            querySeriesMapping: HistoricalAnalyzerQuerySeries,
          ) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_QUERY_SERIES_MAPPING,
              payload: { querySeriesMapping },
            }),
          setTimestampRange: (range: DateRange) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_TIMESTAMP_RANGE,
              payload: { range },
            }),
          setChartFrequency: (frequency: IChartFrequencyData) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_CHART_FREQUENCY,
              payload: { chartFrequency: frequency },
            }),
          setChartAxisNames: (updated: AxisNames) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_CHART_AXIS_NAMES,
              payload: { chartAxisNames: updated },
            }),
          setHistChartTitle: (updatedTitle: HistChartTitle) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_HIST_CHART_TITLE,
              payload: { histChartTitle: updatedTitle },
            }),
          setUpdateChartBtnStatus: (updated: boolean) =>
            dispatch({
              type: HistoricalAnalyzerActionKind.SET_UPDATE_CHART_BTN_STATUS,
              payload: { updateChartBtnStatus: updated },
            }),
        }}
      >
        {children}
      </HistoricalAnalyzerContext.Provider>
    )
  }
