import React, { useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { Dropdown } from 'components/atoms'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import DateRangeSelector from 'components/common/DateRangeSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import { START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'
import { IChartFrequencyData, Source, Ticker } from 'types'
import { Model } from 'types/models'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import MultiTimeSeriesLineChart from './MultiTimeSeriesLineChart'
import ChartLoader from './ChartLoader'

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: Model.SABR,
  },
  {
    name: 'SVI',
    value: Model.SVI,
  },
]

const EXPIRY_OPTIONS = [
  {
    name: '1W',
    value: 7,
  },
  {
    name: '1M',
    value: 30,
  },
  {
    name: '3M',
    value: 90,
  },
  {
    name: '6M',
    value: 180,
  },
  {
    name: '1Y',
    value: 365,
  },
]

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>
          Delta {point.point.series.name}:{' '}
          {formatNumber(point.value, { style: 'percent' })}
        </td>
      </tr>
    </table>
  )
}
const TIMESTAMP_RANGE = {
  from: START_OF_BLOCKSCHOLES_TIME,
  until: TODAY_UTC,
}
const OptionsPutCallSkew: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [activeModel, setActiveModel] = useState<Model>(Model.SVI)
  const [activeTenor, setActiveTenor] = useState<number>(7)
  const [activeDeltas, setActiveDeltas] = useState<Array<string>>([
    '0.05',
    '0.1',
    '0.2',
    '0.25',
    '0.4',
  ])
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )

  const [seriesReader] = useAsyncResource(
    dataService.getOptionPutCallSkew,
    source,
    ticker,
    activeModel,
    TIMESTAMP_RANGE,
    activeTenor,
  )
  const title = 'Put/call skew'
  return (
    <ChartWrapper
      title={title}
      singleRow={false}
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <Dropdown
            showLabel
            current={MODEL_OPTIONS.find(({ value }) => value === activeModel)}
            options={MODEL_OPTIONS}
            onChange={setActiveModel}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Model"
          />
          <Dropdown
            showLabel
            current={EXPIRY_OPTIONS.find(({ value }) => value === activeTenor)}
            options={EXPIRY_OPTIONS}
            onChange={setActiveTenor}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Expiry"
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <MultiTimeSeriesLineChart
            dataReader={seriesReader}
            frequency={frequency}
            activeSeries={activeDeltas}
            valueAxisTitle={() => 'Call - Put Vol Points'}
            renderer={tooltipRenderer}
            singleAxis
            seriesLabelFormatter={(seriesLabel: string) => `Δ ${seriesLabel}`}
            title={title}
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default OptionsPutCallSkew
