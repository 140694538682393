import AssetClass from './assetClass'

export enum Source {
  FTX = 'FTX',
  BINANCE = 'BINANCE',
  DERIBIT = 'DERIBIT',
}

export const AssetClassSource: Record<AssetClass, Array<Source>> = {
  [AssetClass.FUTURE]: [Source.DERIBIT, Source.FTX],
  [AssetClass.SWAP]: [Source.DERIBIT, Source.FTX],
  [AssetClass.OPTION]: [Source.DERIBIT, Source.BINANCE],
}
