import * as React from 'react'
import { classNames } from '@progress/kendo-react-common'

export interface NavigationRowProps {
  children?: React.ReactNode
  className?: string
}

export const NavigationRow: React.FunctionComponent<NavigationRowProps> = ({
  className,
  children,
}: NavigationRowProps) => {
  return (
    <div className="navigation-container">
      <div className={classNames(className)}>{children}</div>
    </div>
  )
}
