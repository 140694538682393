import { useEffect, useRef, useState } from 'react'

interface Price {
  price: number
  prevPrice: number
}
type Ticker = string
export type LivePrices = Record<Ticker, Price>

export const useLivePrices = (allow: boolean): LivePrices => {
  const ws = useRef<WebSocket>()
  const [prices, setPrices] = useState<LivePrices>({})

  useEffect(() => {
    if (allow) {
      let interval
      ws.current = new WebSocket('wss://www.cryptofacilities.com/ws/v1')
      const wsCurrent = ws.current
      ws.current.onopen = () => {
        console.log('ws opened')
        interval = setInterval(
          () =>
            ws.current?.send(
              JSON.stringify({ event: 'subscribe', feed: 'prices' }),
            ),
          1000 * 30,
        )
        ws.current?.send(JSON.stringify({ event: 'subscribe', feed: 'prices' }))
      }
      ws.current.onclose = () => console.log('ws closed')

      return () => {
        wsCurrent.close()
        clearInterval(interval)
      }
    }
  }, [allow])

  useEffect(() => {
    if (!ws.current || !allow) return

    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data) as { base: string; price: number }
      // console.log('e', message)
      if (
        message.base === undefined ||
        !['ETH', 'XBT', 'SOL', 'XRP', 'ADA', 'DOT', 'UNI'].includes(
          message.base.toUpperCase(),
        )
      ) {
        return
      }
      setPrices((prev) => ({
        ...prev,
        [message.base]: {
          price: message.price,
          prevPrice: prev?.[message.base]?.price,
        },
      }))
    }
  }, [allow])

  return prices
}
