import { Source, Ticker } from 'types'
import { Model } from 'types/models'
import {
  EndpointDefinitionNotRequiringZipping,
  ModelParametersSpecifiedTimestampParams,
  getBSData,
} from './common'

const OPTIONS_HEATMAP_ENDPOINT: EndpointDefinitionNotRequiringZipping = {
  path: 'timeseries/getOptionsHeatMapData',
  itemsRequireZipping: false,
}

type OptionsHeatmapItem = {
  expiry: number
  strike: string
  timeseries: {
    series: string
    field: string
    start: string
    end: string
  }
  timestamp: number
  value: number
  zscore: number
}

const isOptionsHeatmapItem = (item: any): item is OptionsHeatmapItem => {
  return (
    !!item &&
    item.expiry !== undefined &&
    !Number.isNaN(item.expiry) &&
    item.strike !== undefined &&
    typeof item.strike === 'string' &&
    item.timestamp !== undefined &&
    !Number.isNaN(item.timestamp) &&
    item.value !== undefined &&
    !Number.isNaN(item.value) &&
    item.zscore !== undefined &&
    !Number.isNaN(item.zscore) &&
    !!item.timeseries &&
    item.timeseries.series !== undefined &&
    typeof item.timeseries.series === 'string' &&
    item.timeseries.field !== undefined &&
    typeof item.timeseries.field === 'string' &&
    item.timeseries.start !== undefined &&
    typeof item.timeseries.start === 'string' &&
    item.timeseries.end !== undefined &&
    typeof item.timeseries.end === 'string'
  )
}

export const getOptionsHeatmapData = async (
  source: Source,
  ticker: Ticker,
  model: Model,
  date?: Date,
): Promise<Array<OptionsHeatmapItem>> => {
  const query: ModelParametersSpecifiedTimestampParams = {
    model,
    currency: ticker,
    exchange: source.toLowerCase(),
    timestamp: date ? (date.getTime() * 1000).toString() : 'LATEST',
  }
  const urlSearchParams = new URLSearchParams(query)
  const result = await getBSData(
    OPTIONS_HEATMAP_ENDPOINT,
    urlSearchParams,
    isOptionsHeatmapItem,
  )
  return result
}
