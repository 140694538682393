import { RefObject } from 'react'
import { Parser } from './parser'
import Query from './query'
export interface IHistoricalAnalyzerDataSource {
  label: string
  type: ValueType
}

export type ParseTreeRoot = {
  program: ParseTreeNode
  treeInError: boolean
}

export type ParseTreeNode = {
  type: string
  isError: boolean
  children: Array<ParseTreeNode>
  content?: string
}

export interface ICodeMirrorState {
  containerRef: RefObject<HTMLDivElement>
  parsed: Parser | null
  clearInput: () => void
  setInput: (newInput: string) => void
}

export type AST = Object

export enum ValueType {
  none = 'none',
  vector = 'vector',
  scalar = 'scalar',
  identifier = 'identifier',
}

export type QueryRequest = {
  query: Query
  ast: AST
}

export type AxisNames = { left: string; right: string }

export type HistChartTitle = string

export type ShowDataSearch = boolean

export type TreeNode = {
  label: string
  children?: TreeNode[]
}
