import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartLegendItem,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  DragEndEvent,
  TooltipContext,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { ReactNode, useLayoutEffect } from 'react'
import { useTheme } from 'styled-components'
import { IChartFrequencyData, TimeSeriesData } from 'types'
import Download, { useSave } from 'hooks/useSave'

import { formatDate, formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'
import { useTimestamp } from 'components/common/ChartWrapper'
import { customLabel } from './customLabel'

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>
          {formatNumber(point.value, { style: 'currency', currency: 'USD' })}
        </td>
      </tr>
    </table>
  )
}

interface ITimeSeriesLineChartProps {
  dataReader: () => TimeSeriesData
  frequency: IChartFrequencyData
  valueAxisTitle?: string
  valueAxisCrosshairFormat?: string
  renderer?: (props: TooltipContext) => ReactNode
  title?: string
}

const TimeSeriesLineChart: React.FC<ITimeSeriesLineChartProps> = ({
  dataReader,
  frequency,
  valueAxisTitle,
  valueAxisCrosshairFormat = 'c0',
  renderer,
  title = 'BlockScholes series',
}) => {
  const data = dataReader()
  const theme = useTheme()
  const { chartRef, handleSave } = useSave()

  const { timestampRange, setTimestampRange } = useTimestamp()

  useLayoutEffect(() => {
    setTimestampRange({
      from: data.dataPoints[0]?.x,
      until: data.dataPoints?.[data.dataPoints.length - 1]?.x,
    })
  }, [data])

  const handleZoomEnd = (e: ZoomEndEvent) => {
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.nativeEvent.event.shiftKey) {
      // Shift-drag is used for zooming, so we do not want to handle here
      return
    }
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  return (
    <>
      {data.dataPoints.length === 0 ? (
        <p>No series data available</p>
      ) : (
        <>
          <div style={{ height: 0 }}>
            <svg
              xmlns="https://www.w3.org/2000/svg"
              version="1.1"
              width="0"
              height="0"
              style={{ visibility: 'hidden' }}
            >
              <defs>
                <linearGradient id="svg-gradient" x1="1" y1="0" x2="0" y2="1">
                  <stop
                    offset="0"
                    style={{
                      stopColor: theme.palette.common.series1,
                      stopOpacity: 0.9,
                    }}
                  />
                  <stop
                    offset="1"
                    style={{
                      stopColor: theme.palette.common.series1,
                      stopOpacity: 0.15,
                    }}
                  />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              position: 'relative',
              top: '45px',
              left: '-30px',
              zIndex: 1,
            }}
          >
            <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
              Shift + scroll to zoom
            </p>
            <Download handleClick={() => handleSave(title)} />
          </div>
          <Chart
            pannable
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            onDragEnd={handleDragEnd}
            transitions={false}
            ref={chartRef}
          >
            <ChartLegend
              width={1000}
              position="top"
              align="end"
              orientation="horizontal"
            >
              <ChartLegendItem cursor="pointer" visual={customLabel} />
            </ChartLegend>
            <ChartTooltip />
            <ChartValueAxis>
              <ChartValueAxisItem
                labels={{ format: 'n0', font: '0.75em "OpenSans"' }}
                title={{ text: valueAxisTitle }}
                crosshair={{
                  visible: true,
                  tooltip: {
                    visible: true,
                    format: valueAxisCrosshairFormat,
                    background: theme.palette.common.kendoGrey,
                  },
                }}
              />
            </ChartValueAxis>
            <ChartSeries>
              <ChartSeriesItem
                type="area"
                line={{
                  style: 'smooth',
                  color: theme.palette.common.series1,
                  width: 2,
                }}
                color="url(#svg-gradient)"
                width={2}
                field="y"
                categoryAxis="timestamp"
                categoryField="x"
                data={data.dataPoints}
                markers={{ visible: false }}
              >
                <ChartSeriesItemTooltip
                  render={renderer || tooltipRenderer}
                  color="white"
                  background={theme.palette.common.kendoGrey}
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name="timestamp"
                baseUnit={frequency.unit}
                baseUnitStep={frequency.step}
                maxDivisions={20}
                labels={{
                  rotation: 'auto',
                  font: '0.75em "OpenSans"',
                  dateFormats: {
                    weeks: 'd MMM yy',
                  },
                }}
                min={timestampRange?.from}
                max={timestampRange?.until}
                crosshair={{
                  visible: true,
                  tooltip: {
                    visible: false,
                    format: 'd MMM yy',
                    background: theme.palette.common.kendoGrey,
                  },
                }}
              />
            </ChartCategoryAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default TimeSeriesLineChart
