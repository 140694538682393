import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { Dropdown } from 'components/atoms'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import DateRangeSelector from 'components/common/DateRangeSelector'
import { CatalogContext } from 'context/CatalogContext'
import { AssetClassSource, IChartFrequencyData, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import TimeSeriesLineChart from './TimeSeriesLineChart'
import ChartLoader from './ChartLoader'

const FutureOpenInterest: React.FC = () => {
  const catalog = useContext(CatalogContext)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [source, setSource] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )
  const [availableInstruments, setAvailableInstruments] = useState<
    Array<string>
  >([])
  const [instrument, setInstrument] = useState<string>()

  useEffect(() => {
    const instruments = catalog.data
      .filter(
        (item) =>
          item.assetType === 'future' &&
          item.baseAsset === ticker &&
          item.exchange === source.toLowerCase() &&
          new Date(item.expiry) >
            new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
      )
      .map((item) => item.instrument.split('-')[1])
      .reverse()
    setAvailableInstruments(instruments)
  }, [catalog])

  useEffect(() => {
    setInstrument(availableInstruments[0])
  }, [availableInstruments])

  const [seriesDataReader] = useAsyncResource(
    dataService.getFutureOpenInterest,
    source,
    ticker,
    instrument,
  )

  const tooltipRenderer = ({ point }) => {
    return (
      <table>
        <tr>
          <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
        </tr>
        <tr>
          <td>{formatNumber(point.value, { style: 'decimal' })}</td>
        </tr>
      </table>
    )
  }

  // FIXME: this hack is used too often
  const options = useMemo(() => {
    return availableInstruments.map((item) => ({
      name: item,
      value: item,
    }))
  }, [availableInstruments])

  return (
    <ChartWrapper
      title="Open interest"
      singleRow={false}
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={AssetClassSource[AssetClass.SWAP]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <Dropdown
            showLabel
            current={options.find(({ value }) => value === instrument)}
            options={options}
            onChange={setInstrument}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Expiry"
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <TimeSeriesLineChart
            dataReader={seriesDataReader}
            frequency={frequency}
            valueAxisTitle="Contracts"
            valueAxisCrosshairFormat="n0"
            renderer={tooltipRenderer}
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default FutureOpenInterest
