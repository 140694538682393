import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartLegendItem,
  ChartLegendItemProps,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  DragEndEvent,
  TooltipContext,
  ValueAxisLabels,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { ReactNode, useEffect, useLayoutEffect, useMemo } from 'react'
import Download, { useSave } from 'hooks/useSave'
import { useTheme } from 'styled-components'
import {
  DateRange,
  IChartFrequencyData,
  ITimeSeriesDataPoint,
  TimeSeriesData,
  Source,
  Ticker,
} from 'types'
import { preloadResource } from 'use-async-resource'
import { dataService, getMultiOptionAtmVolatilitySeries } from 'services'
import { Model } from 'types/models'
import {
  getSeriesColorFromIndex,
  ACTIVE_TICKERS,
  START_OF_BLOCKSCHOLES_TIME,
  TODAY_UTC,
} from 'consts'
import { compareAsc } from 'date-fns'
import 'hammerjs'
import { MultiSeriesResult } from 'services/common'
import { useTimestamp } from 'components/common/ChartWrapper'
import { customLabel } from './customLabel'

interface IMultiTimeSeriesLineChartProps {
  dataReader: () => MultiSeriesResult<TimeSeriesData>
  frequency: IChartFrequencyData
  activeSeries: Array<string>
  singleAxis?: boolean
  valueAxisLabels?: ValueAxisLabels
  valueAxisTitle: (seriesLabel: string) => string
  valueAxisCrosshairFormat?: string
  renderer?: (props: TooltipContext) => ReactNode
  seriesLabelFormatter?: (label: string) => string
  title?: string
}

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>
          {formatNumber(point.value, { style: 'currency', currency: 'USD' })}
        </td>
      </tr>
    </table>
  )
}

const MultiTimeSeriesLineChart: React.FC<IMultiTimeSeriesLineChartProps> = ({
  dataReader,
  frequency,
  activeSeries,
  valueAxisLabels,
  valueAxisTitle,
  valueAxisCrosshairFormat = 'p2',
  renderer,
  singleAxis = false,
  seriesLabelFormatter,
  title = `BlockScholes Multi timeseries`,
}) => {
  const { handleSave, chartRef } = useSave()

  const results: MultiSeriesResult<TimeSeriesData> = dataReader()
  const theme = useTheme()
  const series = results.successes || []
  const data = series.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.key]: cur.dataPoints,
    }
  }, {} as Record<string, Array<ITimeSeriesDataPoint>>)
  // TODO: render errors to user
  const { timestampRange, setTimestampRange } = useTimestamp()
  preloadResource(
    getMultiOptionAtmVolatilitySeries,
    Source.DERIBIT,
    Ticker.BTC,
    Model.SVI,
    ['1W'],
    START_OF_BLOCKSCHOLES_TIME,
    TODAY_UTC,
  )
  preloadResource(
    dataService.getOptionVolatilityRatio,
    Source.DERIBIT,
    Ticker.BTC,
    Model.SVI,
    ['1M'],
  )
  preloadResource(
    dataService.getPerpetualSwap,
    Source.DERIBIT,
    ACTIVE_TICKERS,
    'mid.px',
  )
  preloadResource(
    dataService.getOptionPutCallSkew,
    Source.DERIBIT,
    Ticker.BTC,
    Model.SVI,
    {
      from: START_OF_BLOCKSCHOLES_TIME,
      until: TODAY_UTC,
    },
    7,
  )
  useEffect(() => {
    const allSeriesDates = Object.values(data)
      .flatMap((ser) => ser.map((el) => el.x))
      .sort((a, b) => compareAsc(a, b))
    setTimestampRange({
      from: allSeriesDates[0],
      until: allSeriesDates[allSeriesDates.length - 1],
    })
  }, [])

  const handleZoomEnd = (e: ZoomEndEvent) => {
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  const handleZoomStart = (e: ZoomStartEvent) => {
    if (!e.nativeEvent.shiftKey) {
      e.preventDefault()
    }
  }

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.nativeEvent.event.shiftKey) {
      // Shift-drag is used for zooming, so we do not want to handle here
      return
    }
    if (
      e.axisRanges.timestamp.min instanceof Date &&
      e.axisRanges.timestamp.max instanceof Date
    ) {
      setTimestampRange({
        from: e.axisRanges.timestamp.min,
        until: e.axisRanges.timestamp.max,
      })
    }
  }

  const activeData: Record<
    string,
    Array<ITimeSeriesDataPoint>
  > = useMemo(() => {
    return activeSeries.reduce((acc, ser) => {
      if (!data[ser]) {
        return acc
      }
      return {
        ...acc,
        [ser]: data[ser],
      }
    }, {})
  }, [data, activeSeries])

  // FIXME: extract to hook
  const minYValue = useMemo(() => {
    if (!data) {
      return 0
    }
    let min = 0
    Object.entries(data).forEach(([ser, seriesData]) => {
      if (activeSeries.includes(ser)) {
        min = Math.min(min, ...seriesData.map((d) => d.y as number))
      }
    })
    return Number.isNaN(min) ? 0 : min
  }, [data, activeSeries])
  return (
    <>
      {!timestampRange?.from && !timestampRange?.until ? (
        <p>No series data available</p>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'baseline',
              position: 'relative',
              top: '60px',
              left: singleAxis ? '-30px' : '-65px',
              zIndex: 1,
              height: 0,
            }}
          >
            <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
              Shift + scroll to zoom
            </p>
            <Download handleClick={() => handleSave(title)} />
          </div>
          <Chart
            pannable
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
            }}
            zoomable={{ mousewheel: { rate: 0.1 } }}
            onZoomStart={handleZoomStart}
            onZoomEnd={handleZoomEnd}
            onDragEnd={handleDragEnd}
            transitions={false}
            ref={chartRef}
          >
            <ChartLegend
              width={1000}
              position="top"
              align="end"
              orientation="horizontal"
            >
              <ChartLegendItem cursor="cursor" visual={customLabel} />
            </ChartLegend>
            <ChartTooltip />
            <ChartValueAxis>
              {singleAxis ? (
                <ChartValueAxisItem
                  name="yAxis"
                  title={{ text: valueAxisTitle('') }}
                  axisCrossingValue={minYValue}
                  labels={
                    valueAxisLabels || {
                      format: valueAxisCrosshairFormat || 'p1',
                      font: '0.75em "OpenSans"',
                    }
                  }
                  crosshair={{
                    visible: true,
                    tooltip: {
                      visible: true,
                      format: valueAxisCrosshairFormat,
                      background: theme.palette.common.kendoGrey,
                    },
                  }}
                />
              ) : (
                Object.keys(activeData).map((seriesLabel, i) => (
                  <ChartValueAxisItem
                    key={seriesLabel}
                    name={seriesLabel}
                    title={{
                      text: valueAxisTitle(seriesLabel),
                      color: getSeriesColorFromIndex(i),
                    }}
                    axisCrossingValue={minYValue}
                    labels={
                      valueAxisLabels || {
                        format: valueAxisCrosshairFormat || 'n0',
                        font: '0.75em "OpenSans"',
                      }
                    }
                    crosshair={{
                      visible: true,
                    }}
                  />
                ))
              )}
            </ChartValueAxis>
            <ChartSeries>
              {Object.entries(activeData).map(([seriesLabel, seriesData]) => (
                <ChartSeriesItem
                  key={seriesLabel}
                  type="line"
                  style="smooth"
                  name={
                    seriesLabelFormatter
                      ? seriesLabelFormatter(seriesLabel)
                      : seriesLabel
                  }
                  axis={singleAxis ? 'yAxis' : seriesLabel}
                  width={2}
                  field="y"
                  categoryAxis="timestamp"
                  categoryField="x"
                  data={seriesData}
                  markers={{ visible: false }}
                >
                  <ChartSeriesItemTooltip
                    render={renderer || tooltipRenderer}
                    color="white"
                    background={theme.palette.common.kendoGrey}
                  />
                </ChartSeriesItem>
              ))}
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                name="timestamp"
                baseUnit={frequency.unit}
                baseUnitStep={frequency.step}
                maxDivisions={20}
                axisCrossingValue={[0, Date.now()]}
                labels={{
                  rotation: 'auto',
                  font: '0.75em "OpenSans"',
                  dateFormats: {
                    weeks: 'd MMM yy',
                  },
                }}
                min={timestampRange.from}
                max={timestampRange.until}
                crosshair={{
                  visible: true,
                }}
              />
            </ChartCategoryAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default MultiTimeSeriesLineChart
