import React from 'react'
import { ColorPicker, ColorPickerChangeEvent } from "@progress/kendo-react-inputs";
import styled from 'styled-components'



interface StyledColorPickerPropsType {
    pickedColor: string
}


const ColorPickerWrapper = styled.div<StyledColorPickerPropsType>`
   width: 100%;
   background-color: inherit;
   margin: 0px;
   .k-color-picker, .k-colorpicker {
      background-color: ${props => props.pickedColor};
   }
   .k-input, .k-picker {
      border: none;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      box-shadow: 0px 0px 5px ${props => props.pickedColor};
      margin-left: 6px;
   }
   .k-color-preview {
    border: none;
    border-radius: 0px;
   }
   .k-i-arrow-s {
    display: none;
   }
   .k-input-inner {
    display: none;
   }
   .k-button {
    box-shadow: 0px 0px 5px ${props => props.pickedColor};
   }
`

interface ColorPickerSeriesProps {
    func: (newColor: string) => void;
    defaultValue: string;
}



const ColorPickerForCharts: React.FC<ColorPickerSeriesProps> = (props) => {

    const handleColorPickerChange = (event: ColorPickerChangeEvent) => {
        props.func(event.value)
    }

    const paletteSettings = {
        palette: [
            "#FBCF33",
            "#FF3D00",
            "#247CFF",
            "#1EB511",
            "#DB00FF",
            "#FF7A00",
            "#FF0099",
            "#893DFF",
            "#C5C5C5",
            "#FDE799",
            "#FF9E80",
            "#92BEFF",
            "#8EDA88",
            "#ED80FF",
            "#FFBD80",
            "#FF80CC",
            "#C49EFF",
            "#E2E2E2",
        ],
        columns: 6,
        tileSize: 30,
    };


    return (
        <>
            <ColorPickerWrapper pickedColor={props.defaultValue} >
                <ColorPicker paletteSettings={paletteSettings} value={props.defaultValue} onChange={handleColorPickerChange} view="palette" />
            </ColorPickerWrapper>
        </>
    )
}

export default ColorPickerForCharts
