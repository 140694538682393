import { v4 as uuidv4 } from 'uuid'
import { toString } from '@telerik/kendo-intl'
import { differenceInCalendarDays } from 'date-fns'
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar,
} from '@progress/kendo-react-grid'
import Page from 'components/atoms/Page'
import { useState } from 'react'
import styled from 'styled-components'
import { Currency, Source, Ticker } from 'types'
import { Model } from 'types/models'
import { OptionType } from 'types/options'
import { dataService } from 'services/lambdaDataService'
import DataChooser from 'components/common/DataChooser'
import Button from 'components/atoms/Button'
import {
  DateDropdownCell,
  ModelDropdownCell,
  OptionPricerCommandCell,
  OptionTypeDropdownCell,
  TickerDropdownCell,
} from './cells'

const ColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface OptionPriceQuery {
  id: string
  model?: Model
  type?: OptionType
  ccy?: Ticker
  atm?: boolean
  strike?: number
  expiry?: Date
  price?: number
  impliedvol?: number
  delta?: number
  gamma?: number
  vega?: number
  theta?: number
  volga?: number
  vanna?: number
}

interface CompleteOptionPriceQuery extends OptionPriceQuery {
  id: string
  model: Model
  type: OptionType
  ccy: Ticker
  atm: boolean
  strike: number
  expiry: Date
}

interface EnrichedOptionPriceQuery extends CompleteOptionPriceQuery {
  price: number
  impliedvol: number
  delta: number
  gamma: number
  vega: number
  theta: number
  volga: number
  vanna: number
}

interface EditableOptionPriceQuery extends OptionPriceQuery {
  inEdit: boolean
}

const createNewQuery = (
  params: Partial<EditableOptionPriceQuery>,
): EditableOptionPriceQuery => {
  return {
    inEdit: true,
    id: uuidv4(),
    ...params,
  }
}

const queryIsComplete = (
  query: OptionPriceQuery,
): query is CompleteOptionPriceQuery => {
  return (
    query.model !== undefined &&
    query.type !== undefined &&
    query.ccy !== undefined &&
    query.strike !== undefined &&
    query.type !== undefined
  )
}

const queryIsEnriched = (
  query: OptionPriceQuery,
): query is EnrichedOptionPriceQuery => {
  return (
    queryIsComplete(query) &&
    query.price !== undefined &&
    query.impliedvol !== undefined &&
    query.delta !== undefined &&
    query.gamma !== undefined &&
    query.vega !== undefined &&
    query.theta !== undefined &&
    query.volga !== undefined &&
    query.vanna !== undefined
  )
}

// TODO: all this was done in a rush, needs redone to avoid abusing !important
const StyledGrid = styled(Grid)`
  & .k-input-inner,
  & .k-button-sm,
  & input.k-input {
    padding: 0.2rem !important;
    border: none !important;
    color: white !important;
    background-color: #1c1d21 !important;
  }
  & .k-input-md,
  & .k-buttom-sm,
  & input.k-input {
    font-size: 0.8rem !important;
    border: none !important;
    color: white !important;
    background-color: #1c1d21 !important;
  }
  & .k-picker {
    border: none !important;
  }
  & .k-icon {
    width: 0.6rem !important;
    height: 0.6rem !important;
  }
  & {
    border-color: ${({ theme }) => theme.palette.common.grey4} !important;
  }
  & tr[aria-rowindex='1'] .k-header[aria-colindex='1'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='1'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='2'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='3'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='4'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='5'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='6'] {
    background: ${({ theme }) => theme.palette.common.seriesg} !important;
  }
  & tr[aria-rowindex='1'] .k-header[aria-colindex='7'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='7'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='8'] {
    background: rgb(40,120,255) !important;
  }
  & tr[aria-rowindex='1'] .k-header[aria-colindex='9'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='9'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='10'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='11'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='12'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='13'],
  & tr[aria-rowindex='2'] .k-header[aria-colindex='14'] {
    background: ${({ theme }) => theme.palette.common.series1} !important;
  }
  & td[aria-colindex='4'] .k-input-spinner {
    display: none;
  }
  &
`

const OptionPricer: React.FC = () => {
  const [optionPriceQueries, setOptionPriceQueries] = useState<
    Array<EditableOptionPriceQuery>
  >([{ inEdit: true, id: uuidv4() }])
  const [source, setSource] = useState(Source.DERIBIT)
  const [isLoading, setIsLoading] = useState(false)

  const handleNewClick = () => {
    const newDataItem = createNewQuery({ inEdit: true })

    setOptionPriceQueries([...optionPriceQueries, newDataItem])
  }

  const showFooterRow = optionPriceQueries.some(queryIsEnriched)

  const fetchData = (dataItem: CompleteOptionPriceQuery) => {
    setIsLoading(true)
    return dataService
      .getOptionsPrice(
        source,
        dataItem.ccy,
        dataItem.model,
        dataItem.strike,
        dataItem.expiry,
        dataItem.type,
      )
      .then((response) => {
        const { data } = response
        return {
          ...dataItem,
          price: data.price,
          impliedvol: data['implied vol'],
          delta: data.delta,
          gamma: data.gamma,
          vega: data.vega,
          theta: data.theta,
          volga: data.volga,
          vanna: data.vanna,
        }
      })
  }

  type SubType<Base, Condition> = Pick<
    Base,
    {
      [Key in keyof Base]-?: Base[Key] extends Condition ? Key : never
    }[keyof Base]
  >
  type OptionPriceQueryNumberKey = keyof SubType<
    OptionPriceQuery,
    number | undefined
  >

  const getSumOfFieldFromQueries = <T extends OptionPriceQueryNumberKey>(
    numberField: T,
    queries: Array<OptionPriceQuery>,
  ): number => {
    return queries
      .map((query) => query[numberField])
      .filter((price): price is number => !!price)
      .reduce((acc, cur) => acc + cur, 0)
  }
  const summedFields: Array<OptionPriceQueryNumberKey> = [
    'price',
    'delta',
    'gamma',
    'vega',
    'theta',
    'volga',
    'vanna',
  ]
  const fieldSums = summedFields.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: getSumOfFieldFromQueries(cur, optionPriceQueries),
    }
  }, {} as Record<OptionPriceQueryNumberKey, number>)

  const remove = (dataItem: EditableOptionPriceQuery) => {
    const index = optionPriceQueries.findIndex((pq) => pq.id === dataItem.id)
    const data = [...optionPriceQueries]
    data.splice(index, 1)
    setOptionPriceQueries(data)
  }

  const update = (dataItem: EditableOptionPriceQuery) => {
    if (!queryIsComplete(dataItem)) {
      const index = optionPriceQueries.findIndex((pq) => pq.id === dataItem.id)
      const data = [...optionPriceQueries]
      data.splice(index, 1, {
        ...dataItem,
      })
      setOptionPriceQueries(data)
      return
    }
    fetchData(dataItem)
      .then((newItem) => {
        const index = optionPriceQueries.findIndex(
          (pq) => pq.id === dataItem.id,
        )
        const data = [...optionPriceQueries]
        data.splice(index, 1, {
          ...newItem,
          inEdit: true,
        })
        setOptionPriceQueries(data)
      })
      .catch(() => {
        // TODO: show error notification like HA
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const enterEdit = (dataItem: EditableOptionPriceQuery) => {
    const data = optionPriceQueries.map((item) =>
      item.id === dataItem.id ? { ...item, inEdit: true } : item,
    )
    setOptionPriceQueries(data)
  }

  const handleItemChange = (event: GridItemChangeEvent) => {
    const data = optionPriceQueries.map((item) =>
      item.id === event.dataItem.id
        ? { ...item, [event.field || '']: event.value }
        : item,
    )

    setOptionPriceQueries(data)
  }

  const CommandCell = (cellProps: GridCellProps) => (
    <OptionPricerCommandCell
      {...cellProps}
      edit={enterEdit}
      remove={remove}
      update={update}
      editField="inEdit"
      isLoading={isLoading}
    />
  )
  return (
    <Page>
      <ColumnLayout>
        <h3>Vanilla Options Pricer</h3>
        <DataChooser source={source} handleSourceChange={setSource} />
        <StyledGrid
          style={{ width: 1200, fontSize: '0.85em' }}
          data={optionPriceQueries}
          onItemChange={handleItemChange}
          editField="inEdit"
          scrollable="none"
        >
          <GridToolbar>
            <Button
              size="sm"
              type="button"
              disabled={isLoading}
              onClick={handleNewClick}
            >
              <span style={{ display: 'flex', alignItems: 'center' }}>
                Add new{' '}
                <span
                  className="k-icon k-i-plus"
                  style={{ marginLeft: '0.5rem' }}
                />
              </span>
            </Button>
          </GridToolbar>
          <Column title="Input">
            <Column
              field="model"
              title="Model"
              cell={ModelDropdownCell}
              width="70px"
            />
            <Column
              field="type"
              title="Type"
              cell={OptionTypeDropdownCell}
              width="70px"
            />
            <Column
              field="ccy"
              title="Ccy"
              cell={TickerDropdownCell}
              width="70px"
            />
            <Column
              field="strike"
              title="Strike ($)"
              editor="numeric"
              width="100px"
            />
            <Column
              field="expiry"
              title="Expiry"
              cell={DateDropdownCell}
              width="130px"
            />
            <Column
              cell={CommandCell}
              width="120px"
              footerCell={
                showFooterRow ? () => <td>Portfolio totals</td> : undefined
              }
            />
          </Column>
          <Column title="Optional">
            <Column
              field="price"
              title="Price"
              width="80px"
              format="{0:n2}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.price, 'n2')}</td>
                  : undefined
              }
            />
            <Column
              field="impliedvol"
              title="Implied Vol"
              width="80px"
              format="{0:n3}"
              editable={false}
              footerCell={showFooterRow ? () => <td>–</td> : undefined}
            />
          </Column>
          <Column title="Output">
            <Column
              field="delta"
              title="Delta"
              width="80px"
              format="{0:n2}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.delta, 'n2')}</td>
                  : undefined
              }
            />
            <Column
              field="gamma"
              title="Gamma"
              width="80px"
              format="{0:e3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.gamma, 'e3')}</td>
                  : undefined
              }
            />
            <Column
              field="vega"
              title="Vega"
              width="80px"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.vega, 'n3')}</td>
                  : undefined
              }
            />
            <Column
              field="theta"
              title="Theta"
              width="80px"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.theta, 'n3')}</td>
                  : undefined
              }
            />
            <Column
              field="volga"
              title="Volga"
              width="80px"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.volga, 'n3')}</td>
                  : undefined
              }
            />
            <Column
              field="vanna"
              title="Vanna"
              width="80px"
              format="{0:n3}"
              editable={false}
              footerCell={
                showFooterRow
                  ? () => <td>{toString(fieldSums.vanna, 'n3')}</td>
                  : undefined
              }
            />
          </Column>
          <GridNoRecords>
            Click 'add new' to create a new price query
          </GridNoRecords>
        </StyledGrid>
      </ColumnLayout>
    </Page>
  )
}

export default OptionPricer
