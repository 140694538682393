import { ReactNode, useState } from 'react'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import AssetClass from 'types/assetClass'
import styled from 'styled-components'
import FutureHistoricalPrice from 'components/charts/FutureHistoricalPrice'
import PerpetualSwapsMidPrice from 'components/charts/PerpetualSwapsMidPrice'
import PerpetualSwapsOpenInterest from 'components/charts/PerpetualSwapsOpenInterest'
import FutureImpliedYield from 'components/charts/FutureImpliedYield'
import FutureOpenInterest from 'components/charts/FutureOpenInterest'
import FutureTermStructure from 'components/charts/FutureTermStructure'
import PerpetualVolume from 'components/charts/PerpetualVolume'
import FutureVolume from 'components/charts/FutureVolume'
import FutureHeatMap from 'components/charts/FuturesHeatMap'
import OptionsImpliedVolatility from 'components/charts/OptionsImpliedVolatility'
import OptionsHistoricalModel from 'components/charts/OptionsHistoricalModel'
import OptionsSmile from 'components/charts/OptionsSmile'
import OptionsAtmVolatility from 'components/charts/OptionsAtmVolatility'
import OptionsHeatMap from 'components/charts/OptionsHeatMap'
import OptionsPutCallSkew from 'components/charts/OptionsPutCallSkew'
import OptionsVolatilityRatio from 'components/charts/OptionsVolatilityRatio'
import { preloadResource } from 'use-async-resource'
import { dataService, getMultiOptionAtmVolatilitySeries } from 'services'
import { Source, Ticker } from 'types'
import { Model } from 'types/models'
import { ACTIVE_TICKERS, START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'

interface ILandingPageSection {
  assetClass: AssetClass
  title: string
  children: ReactNode
}

const StyledTabStrip = styled(TabStrip)`
  .k-tabstrip-items-wrapper .k-item {
    background-color: ${(props) => props.theme.palette.common.grey};
    filter: brightness(90%);
    transition: filter 200ms;
  }
  .k-tabstrip-items-wrapper .k-item:hover {
    filter: brightness(140%);
    transition: filter 100ms;
    border-color: ${(props) => props.theme.palette.common.grey};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
  .k-tabstrip-items-wrapper .k-item:active {
    filter: brightness(150%);
    transition: filter 10ms;
    border-color: ${(props) => props.theme.palette.common.grey};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
  .k-tabstrip-items-wrapper .k-item.k-state-active {
    background-color: ${(props) => props.theme.palette.common.grey};
    filter: brightness(110%);
    transition: filter 200ms;
  }
`

const LandingPageSection: React.FC<ILandingPageSection> = (props) => {
  const { assetClass, title, children } = props

  return (
    <TabStripTab title={<h5>{title}</h5>} key={assetClass}>
      {children}
    </TabStripTab>
  )
}

const LandingPage: React.FC = () => {
  const [selected, setSelected] = useState(0)

  const handleSelected = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  return (
    <StyledTabStrip
      selected={selected}
      onSelect={handleSelected}
      animation={false}
      tabContentStyle={{ width: '100%' }}
    >
      <LandingPageSection title="Futures" assetClass={AssetClass.FUTURE}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FutureHistoricalPrice />
          <FutureImpliedYield />
          <FutureTermStructure />
          <FutureOpenInterest />
          <FutureVolume />
          <FutureHeatMap />
        </div>
      </LandingPageSection>
      <LandingPageSection title="Options" assetClass={AssetClass.OPTION}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <OptionsImpliedVolatility />
          <OptionsAtmVolatility />
          <OptionsVolatilityRatio />
          <OptionsHistoricalModel />
          <OptionsSmile />
          <OptionsHeatMap />
          <OptionsPutCallSkew />
        </div>
      </LandingPageSection>
      <LandingPageSection title="Perpetual Swaps" assetClass={AssetClass.SWAP}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PerpetualSwapsMidPrice />
          <PerpetualSwapsOpenInterest />
          <PerpetualVolume />
        </div>
      </LandingPageSection>
    </StyledTabStrip>
  )
}

export default LandingPage
