import { ReactNode, Attributes } from 'react'
import styled from 'styled-components'

export interface PageProps extends Attributes {
  children?: ReactNode
}

const Page = styled.div<PageProps>`
  background-color: ${({ theme }) => theme.palette.background.base};
  border-radius: 10px;
  padding: 25px;
  color: ${({ theme }) => theme.palette.primary.light};
  border: 1px solid #4B5056;
  border-radius: 4px;
  `

export default Page
