import * as React from 'react'
import { Dropdown } from 'components/atoms'
import { Source } from 'types'
import { classNames } from '@progress/kendo-react-common'

export interface ChangeSourceProps {
  selected?: Source
  handleSelection: (source: Source) => void
  className?: string
  labelClassName?: string
  supportedSources?: Array<Source>
}

const DEFAULT_OPTIONS = Object.values(Source)

export const ChangeSource = ({
  className,
  selected,
  handleSelection,
  labelClassName,
  supportedSources,
}: ChangeSourceProps): React.ReactElement => {
  const options = React.useMemo(() => {
    const items = supportedSources?.length ? supportedSources : DEFAULT_OPTIONS
    return items.map((item) => ({
      name: item,
      value: item,
    }))
  }, [supportedSources])
  return (
    <Dropdown
      showLabel
      current={options.find(({ value }) => value === selected)}
      options={options}
      onChange={handleSelection}
      className={className}
      labelClassName={classNames('k-mr-2', labelClassName)}
      label="Source"
    />
  )
}
