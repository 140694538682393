import logo from 'images/logo.svg'

const ChartLoader: React.FC = () => (
  <div
    style={{
      height: '400px',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
    }}
  >
    <div className="spinner">
      <img src={logo} alt="loader" />
    </div>
  </div>
)

export default ChartLoader
