import { Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { EndpointDefinitionNotRequiringZipping, getBSData } from './common'

const OPTIONS_HEATMAP_ENDPOINT: EndpointDefinitionNotRequiringZipping = {
  path: 'timeseries/getActiveFutureHeatMapData',
  itemsRequireZipping: false,
}

type FuturesHeatmapSpotItem = {
  instrument: 'spot'
  expiry: number
}

const isFuturesHeatmapSpotItem = (
  item: any,
): item is FuturesHeatmapSpotItem => {
  return !!item && item.instrument === 'spot'
}

type FuturesHeatmapFutureItem = {
  cells: number[]
  availableSince: string
  timeseries: {
    series: string
    start: string
    end: string
  }
  px: number
  active: boolean
  instrument: string
  expiry: string
}

const isFuturesHeatmapFutureItem = (
  item: any,
): item is FuturesHeatmapFutureItem => {
  return (
    !!item &&
    !isFuturesHeatmapSpotItem(item) &&
    Array.isArray(item.cells) &&
    item.cells.every((cell) => cell !== undefined && !Number.isNaN(cell)) &&
    item.availableSince !== undefined &&
    typeof item.availableSince === 'string' &&
    item.timeseries?.series !== undefined &&
    typeof item.timeseries.series === 'string' &&
    item.timeseries?.start !== undefined &&
    typeof item.timeseries.start === 'string' &&
    item.timeseries?.end !== undefined &&
    typeof item.timeseries.end === 'string' &&
    item.px !== undefined &&
    !Number.isNaN(item.px) &&
    typeof item.active === 'boolean' &&
    item.instrument !== undefined &&
    typeof item.instrument === 'string' &&
    item.expiry !== undefined &&
    typeof item.expiry === 'string'
  )
}

type FuturesHeatmapItem = FuturesHeatmapSpotItem | FuturesHeatmapFutureItem

const isFuturesHeatmapItem = (item: any): item is FuturesHeatmapItem => {
  return isFuturesHeatmapSpotItem(item) || isFuturesHeatmapFutureItem(item)
}

export type FuturesHeatMapEntry = {
  instrument: string
  expiry: Date | null
  availableSince: Date | null
  active?: boolean
  px?: number
  cells?: Array<number>
  timeseries?: string
}

const mapFuturesHeatmapItemToFuturesHeatMapEntry = (
  item: FuturesHeatmapItem,
): FuturesHeatMapEntry => {
  if (isFuturesHeatmapSpotItem(item)) {
    return {
      instrument: item.instrument,
      expiry: null,
      availableSince: null,
    }
  }
  return {
    instrument: item.instrument,
    active: item.active,
    px: item.px,
    cells: item.cells,
    expiry: new Date(item.expiry),
    availableSince: new Date(item.availableSince),
    timeseries: item.timeseries.series,
  }
}

type FuturesHeatmapQuery = {
  currency: Ticker
  exchange: Source
  assetType: AssetClass
  active: boolean
}

export const getFuturesHeatmapData = async (
  source: Source,
  ticker: Ticker,
): Promise<Array<FuturesHeatMapEntry>> => {
  const query: FuturesHeatmapQuery = {
    currency: ticker,
    exchange: source,
    assetType: AssetClass.FUTURE,
    active: true,
  }
  const urlSearchParams = new URLSearchParams({
    currency: query.currency,
    exchange: query.exchange.toLowerCase(),
    assetType: query.assetType.toLowerCase(),
    active: query.active.toString(),
  })
  const futuresHeatmapItems = await getBSData(
    OPTIONS_HEATMAP_ENDPOINT,
    urlSearchParams,
    isFuturesHeatmapItem,
  )
  const result = futuresHeatmapItems
    .map(mapFuturesHeatmapItemToFuturesHeatMapEntry)
    .sort((a, b) => (a.expiry?.getTime() || 0) - (b.expiry?.getTime() || 0))
  return result
}
