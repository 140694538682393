import { DefaultTheme } from 'styled-components'
import 'styles/main.scss'

export enum VariantType {
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'body1',
  'body2',
  'buttonMedium',
  'buttonSmall',
}

const colors = {
  white: '#FFFFFF',
  black: '#000000',
  grey: '#2D2F33',
  grey1: '#717C8E',
  grey2: '#797878',
  grey3: '#BEBEBE',
  grey4: '#979797',
  grey5: '#C4C4C4',
  grey6: '#E9E9E9',
  darkGrey: '#555555',
  lightBlue: '#D4EBF4',
  darkBlue: '#003764',
  darkWhite: '#F5FCFF',
  red: '#C02B0A',
  lightBlack: '#1C1D21',
  lightYellow: '#F0C673',
  yellow: '#E9AA2D',
  midYellow: '#C4912B',
  darkYellow: '#7B5F27',
  kendoGrey: '#495057',
  series1: '#3392ff',
  seriesc: '#ffcd00',
  seriesd: '#00ffa2',
  seriesf: '#ffa5c0',
  seriesg: '#1f51ff',
}

export const palette = {
  common: colors,
  primary: {
    light: '#e4e7eb',
    main: '#009AA7',
    dark: '#7EADB1',
    contrastText: colors.series1,
  },
  text: {
    heading: colors.white,
    body: colors.grey1,
    background: colors.lightBlack,
  },
  background: {
    base: colors.lightBlack,
  },
}

const baseFontSize = 16

const fontFamilies = {
  primary: "'OpenSans', Helvetica, Arial, sans-serif",
  secondary: "'OpenSans', Helvetica, Arial, sans-serif",
}

interface IFontFamily {
  primary: string
  secondary: string
}

interface ITypographicDetails {
  fontFamily?: string
  fontSize?: number
  fontStyle?: string
  fontWeight?: number
  lineHeight?: number
  letterSpacing?: number
  textTransform?: string
}

interface ITypographicDetailsWithMobile extends ITypographicDetails {
  mobile?: ITypographicDetails
}

export type TypographicVariants = {
  [variant in VariantType]: ITypographicDetailsWithMobile
}

export interface ITypography extends TypographicVariants, ITypographicDetails {
  fontFamilies: IFontFamily
}

export const typography: ITypography = {
  fontFamilies,
  fontFamily: fontFamilies.primary,
  fontSize: baseFontSize,
  fontWeight: 400,
  lineHeight: 1.5,
  [VariantType.h1]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 4,
    fontWeight: 1300,
    lineHeight: 1,
    letterSpacing: -1,
    mobile: {
      fontSize: baseFontSize * 2,
      lineHeight: 1.2,
    },
  },
  [VariantType.h2]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 3.5,
    fontWeight: 800,
    lineHeight: 1,
    letterSpacing: -1,
    mobile: {
      fontSize: baseFontSize * 1.75,
      lineHeight: 1.1,
    },
  },
  [VariantType.h3]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 2.25,
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 0.3,
    mobile: {
      fontSize: baseFontSize * 1.6,
      lineHeight: 1.05,
      letterSpacing: 0,
    },
  },
  [VariantType.h4]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.7,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0.2,
    mobile: {
      fontSize: baseFontSize * 1.4,
      lineHeight: 1,
      letterSpacing: 0,
    },
  },
  [VariantType.h5]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.5,
    fontWeight: 600,
    lineHeight: 1,
    mobile: {
      fontSize: baseFontSize * 1.2,
    },
  },
  [VariantType.h6]: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.25,
    fontWeight: 400,
    lineHeight: 1.25,
    mobile: {
      fontSize: baseFontSize * 1.2,
      fontWeight: 600,
      lineHeight: 1.2,
    },
  },
  [VariantType.body1]: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 1.25,
    fontWeight: 400,
    lineHeight: 1.5,
    mobile: {
      fontSize: baseFontSize,
      lineHeight: 1.4,
    },
  },
  [VariantType.body2]: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize,
    fontWeight: 400,
    lineHeight: 1.5,
    mobile: {
      fontSize: baseFontSize * 0.9,
      lineHeight: 1.3,
    },
  },
  [VariantType.buttonMedium]: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 0.9,
    fontWeight: 700,
    lineHeight: 1,
    letterSpacing: 1.1,
    textTransform: 'uppercase',
    mobile: {
      fontSize: baseFontSize * 0.8,
    },
  },
  [VariantType.buttonSmall]: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 0.85,
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'uppercase',
    mobile: {
      fontSize: baseFontSize * 0.75,
    },
  },
}

export const size = {
  desktopMaxWidth: 1300,
  gutter: 20,
}

// Exports
const theme: DefaultTheme = {
  palette,
  typography,
  size,
}

export default theme
