import { Dialog } from '@progress/kendo-react-dialogs'
import { Input } from '@progress/kendo-react-inputs'
import { Label } from '@progress/kendo-react-labels'
import { useState } from 'react'
import { AxisNames } from './types'
import styled from 'styled-components'

const DialogWrapper = styled(Dialog)`
  .k-dialog{
    width: 644px;
    height: 395px;
   box-shadow: -4px 0px 55px rgb(255 255 255 / 10%);
  }
   
`


interface IAxisNamesModalProps {
  axisNames: AxisNames
  handleClose: (updated: AxisNames) => void
}
const AxisNamesModal: React.FC<IAxisNamesModalProps> = (props) => {
  const { axisNames, handleClose } = props
  const [localState, setLocalState] = useState<AxisNames>(axisNames)
  return (
    <DialogWrapper title="Axis Names" onClose={() => handleClose(localState)}>
      <div>
        <Label editorId="left">Left axis: </Label>
        <Input
          id="left"
          type="text"
          onChange={(e) =>
            setLocalState({
              ...localState,
              left: e.target.value?.toString() || '',
            })
          }
          value={localState.left}
        />
        <Label editorId="right">Right axis: </Label>
        <Input
          id="right"
          type="text"
          onChange={(e) =>
            setLocalState({
              ...localState,
              right: e.target.value?.toString() || '',
            })
          }
          value={localState.right}
        />
      </div>
    </DialogWrapper>
  )
}

export default AxisNamesModal
