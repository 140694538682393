import React from 'react'

import { ButtonGroup, Button, Chip } from '@progress/kendo-react-buttons'
import { Label } from '@progress/kendo-react-labels'
import { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs'
import { NavigationRow } from 'components/molecules/Navigation'

import { SpotDisplayBar } from 'components/common/SpotDisplayBar'
import ChartContainer from 'components/common/Stock/Stock'
import { Currency, Source, Ticker } from 'types'
import DataChooser from 'components/common/DataChooser'
import { TODAY_UTC } from 'consts'
import { DatePicker } from 'components/atoms/DatePickers'

const FutureMonitor: React.FC = () => {
  const [ticker, setTicker] = React.useState<Ticker>(Ticker.BTC)
  const [currency, setCurrency] = React.useState<Currency>(Currency.USD)
  const [source, setSource] = React.useState<Source>(Source.DERIBIT)
  const [active, setActive] = React.useState('historical')
  const [endDate, setEndDate] = React.useState(TODAY_UTC)

  return (
    <div className="container-fluid">
      <h3>Future Monitor</h3>
      <div className="row my-4 mt-4">
        <div className="col-12 col-lg-9 border-right mt-3">
          <NavigationRow className="row justify-content-center">
            <DataChooser
              ticker={ticker}
              handleTickerChange={setTicker}
              source={source}
              handleSourceChange={setSource}
              currency={currency}
              handleCurrencyChange={setCurrency}
            />
          </NavigationRow>
        </div>
      </div>
      <SpotDisplayBar />
      <div className="row">
        <div className="col-12 col-lg-12 border-right mt-3">
          <ButtonGroup>
            <Button
              togglable
              selected={active.toLocaleLowerCase() === 'curve'}
              onClick={() => setActive('curve')}
            >
              CURVE
            </Button>
            <Button
              togglable
              selected={active.toLocaleLowerCase() === 'historical'}
              onClick={() => setActive('historical')}
            >
              HISTORICAL
            </Button>
            <Button
              togglable
              selected={active.toLocaleLowerCase() === 'basis'}
              onClick={() => setActive('basis')}
            >
              BASIS
            </Button>
            <Button
              togglable
              selected={active.toLocaleLowerCase() === 'corelation'}
              onClick={() => setActive('corelation')}
            >
              CORELATION
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className="row k-mt-6">
        <div className="col-3">
          <Label className="k-mr-3">AS OF </Label>
          <DatePicker
            defaultValue={endDate}
            format="dd/MMM/yyyy"
            weekNumber
            onChange={(event: DatePickerChangeEvent) => {
              setEndDate(event.value || TODAY_UTC)
            }}
            fillMode="flat"
          />
        </div>
        <div className="col-1">
          <Chip text="1d ago" value="1d" selected />
        </div>
        <div className="col-1">
          <Chip text="1w ago" value="1w" />
        </div>
        <div className="col-1">
          <Chip text="1m ago" value="1m" />
        </div>
        <div className="col-3 col-sm-6">
          <Chip text="Custom" value="custom" />
          <Label className="k-ml-2 k-mr-2">Or</Label>
          <DatePicker
            defaultValue={endDate}
            format="dd/MMM/yyyy"
            weekNumber
            onChange={(event: DatePickerChangeEvent) => {
              setEndDate(event.value || TODAY_UTC)
            }}
            fillMode="flat"
          />
        </div>
      </div>
      <div className="row k-mt-12">
        <div className="col-6">
          <div className="row">
            <div className="col-12">
              <div className="k-card">
                <div>
                  <ChartContainer title="Future chart" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FutureMonitor
