import React, { useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { Dropdown } from 'components/atoms'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import SeriesSelector from 'components/common/SeriesSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import DateRangeSelector from 'components/common/DateRangeSelector'
import {
  ConstantTenor,
  CONSTANT_TENORS,
  CurveBasis,
  IChartFrequencyData,
  Source,
  Ticker,
} from 'types'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import MultiTimeSeriesLineChart from './MultiTimeSeriesLineChart'
import RadioButtonComponent from 'components/atoms/RadioButton'
import ChartLoader from './ChartLoader'

const OPTIONS: Array<{ name: string; value: string }> = [
  { name: 'Raw', value: 'basis' },
  { name: 'Annualized', value: 'annual' },
]

const AXIS_LOOKUP = {
  annual: 'Annualized Implied Yield',
  basis: 'Implied Yield',
}

const tooltipRenderer = ({ point }) => {
  const series: string = point.series.name || ''
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>{`${series}: ${formatNumber(point.value, 'p5')}`}</td>
      </tr>
    </table>
  )
}

const FutureImpliedYield: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )
  const [basis, setBasis] = useState<CurveBasis>('annual')
  const [activeTenors, setActiveTenors] = useState<Array<ConstantTenor>>([
    '1M',
    '3M',
    // '6M',
    // '1Y',
  ])
  const [ticker, setTicker] = useState(Ticker.BTC)

  const [seriesDataReader] = useAsyncResource(
    dataService.getImpliedYield,
    source,
    ticker,
    basis,
    activeTenors,
  )
  const title = 'Implied Yields'
  return (
    <ChartWrapper
      title={title}
      singleRow={false}
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <RadioButtonComponent
            showLabel
            current={OPTIONS.find(({ value }) => value === basis)}
            options={OPTIONS}
            onChange={setBasis}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Yields"
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      series={
        <SeriesSelector
          options={[...CONSTANT_TENORS]}
          value={activeTenors}
          handleChange={setActiveTenors}
        />
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <MultiTimeSeriesLineChart
            dataReader={seriesDataReader}
            frequency={frequency}
            activeSeries={activeTenors}
            valueAxisLabels={{ format: 'p1', font: '0.75em "OpenSans"' }}
            renderer={tooltipRenderer}
            valueAxisTitle={() => AXIS_LOOKUP[basis]}
            singleAxis
            title={title}
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default FutureImpliedYield
