import React from 'react'
import { NavigationRow } from 'components/molecules/Navigation'
import { SpotDisplayBar } from 'components/common/SpotDisplayBar'
import { Currency, Source, Ticker } from 'types'
import DataChooser from 'components/common/DataChooser'

const SpotMonitor: React.FC = () => {
  const [ticker, setTicker] = React.useState<Ticker>(Ticker.BTC)
  const [currency, setCurrency] = React.useState<Currency>(Currency.USD)
  const [source, setSource] = React.useState<Source>(Source.DERIBIT)

  return (
    <div className="container-fluid">
      <h3>Spot Monitor</h3>
      <div className="row my-4 mt-4">
        <div className="col-12 col-lg-9 border-right mt-3">
          <NavigationRow className="row justify-content-center">
            <DataChooser
              ticker={ticker}
              handleTickerChange={setTicker}
              source={source}
              handleSourceChange={setSource}
              currency={currency}
              handleCurrencyChange={setCurrency}
            />
          </NavigationRow>
        </div>
      </div>
      <SpotDisplayBar />
    </div>
  )
}

export default SpotMonitor
