import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { HistoricalAnalyzerCdfData } from 'types'
import { useTheme } from 'styled-components'
import { useMemo } from 'react'
import Download, { useSave } from 'hooks/useSave'

import 'hammerjs'
import Query from './query'
import { AxisNames } from './types'

interface IHistoricalAnalyzerCdfChartProps {
  data: Array<[Query, HistoricalAnalyzerCdfData]>
  axisNames: AxisNames
  title?: string
}

const PERCENTILE_WIDTH = 0.3
const PERCENTILES = ['5th', '25th', 'median', '75th', '95th']

const HistoricalAnalyzerCdfChart: React.FC<IHistoricalAnalyzerCdfChartProps> =
  ({ data, axisNames, title }) => {
    const theme = useTheme()
    const { handleSave, chartRef } = useSave()
    const xRange = useMemo(() => {
      const min = Math.min(...data[0][1].data.map((d) => d.x))
      const max = Math.max(...data[0][1].data.map((d) => d.x))

      return { min, max }
    }, [data])
    // FIXME: extract to hook
    const minYValue = useMemo(() => {
      const allSeriesData = data.flatMap(([query, series]) => {
        return query.isActive ? series.data.map((el) => el.y) : []
      })
      const min = Math.min(...allSeriesData)
      return Number.isNaN(min) ? 0 : min
    }, [data])

    return (
      <>
        <Chart
          renderAs="svg"
          style={{
            width: 1200,
            height: 400,
            overscrollBehavior: 'contain',
          }}
          transitions={false}
          ref={chartRef}
        >
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartTooltip />
          <ChartYAxis>
            <ChartYAxisItem
              name="left"
              title={{ text: axisNames.left }}
              labels={{ format: 'n2', font: '0.75em "OpenSans"' }}
              min={0}
              max={1}
              axisCrossingValue={minYValue}
              plotBands={data[0][1].percentiles.map((percentile, i) => ({
                from: Math.max(0, percentile - PERCENTILE_WIDTH / 200),
                to: Math.min(1.0, percentile + PERCENTILE_WIDTH / 200),
                color: 'white',
                opacity: 0.5,
                label: {
                  text: `${PERCENTILES[i]} percentile`,
                  font: '0.75em "OpenSans"',
                  color: theme.palette.primary.light,
                },
              }))}
            />
          </ChartYAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="scatterLine"
              xField="x"
              yField="y"
              yAxis="left"
              xAxis="number"
              width={2}
              data={data[0][1].data}
              markers={{ visible: false }}
            />
          </ChartSeries>
          <ChartXAxis>
            <ChartXAxisItem
              name="number"
              min={xRange.min}
              max={xRange.max}
              axisCrossingValue={xRange.min}
              labels={{
                rotation: 'auto',
                font: '0.75em "OpenSans"',
                format: 'n2',
              }}
            />
          </ChartXAxis>
        </Chart>
        {data.length > 0 && (
          <Download handleClick={() => handleSave(title || 'CDF')} />
        )}
      </>
    )
  }

export default HistoricalAnalyzerCdfChart
