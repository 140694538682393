import { CatalogContext } from 'context/CatalogContext'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import SeriesSelector from 'components/common/SeriesSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import { AssetClassSource, IChartFrequencyData, Source, Ticker } from 'types'
import AssetClass from 'types/assetClass'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import MultiTimeSeriesBarChart from './MultiSeriesBarChart'
import ChartLoader from './ChartLoader'

const FutureVolume: React.FC = () => {
  const catalog = useContext(CatalogContext)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [source, setSource] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )
  const [activeExpiries, setActiveExpiries] = useState<Array<string>>([])

  const listOfActiveExpires = useMemo(() => {
    return catalog.data
      .filter(
        (item) =>
          item.active &&
          item.baseAsset === ticker &&
          item.exchange === source.toLowerCase() &&
          item.assetType === 'future' &&
          new Date(item.expiry) > new Date(),
      )
      .map((item) => item.instrument.split('-')[1])
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  }, [catalog.data, ticker])

  useEffect(() => {
    setActiveExpiries(listOfActiveExpires.slice(0, 2))
  }, [listOfActiveExpires])

  const [seriesDataReader] = useAsyncResource(
    dataService.getVolume,
    source,
    AssetClass.FUTURE,
    ticker,
    activeExpiries,
  )

  const handleExpiriesChange = (expiries: Array<string>) => {
    setActiveExpiries(expiries)
  }

  return (
    <ChartWrapper
      title="Volume"
      singleRow={false}
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={AssetClassSource[AssetClass.FUTURE]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <FrequencyChange value={frequency} handleChange={setFrequency} />
        </>
      }
      series={
        <SeriesSelector
          options={listOfActiveExpires}
          value={activeExpiries}
          handleChange={handleExpiriesChange}
        />
      }
      chart={
        catalog.fetching ? (
          <ChartLoader />
        ) : (
          <AsyncResourceContent
            fallback={<ChartLoader />}
            errorMessage="Failed to load series data"
          >
            <MultiTimeSeriesBarChart
              dataReader={seriesDataReader}
              frequency={frequency}
              activeSeries={activeExpiries}
              valueAxisLabels={{ format: 'n0', font: '0.75em "OpenSans"' }}
              valueAxisTitle={() => 'Contracts'}
              valueAxisCrosshairFormat="n0"
              singleAxis
              title="Futures Volume"
            />
          </AsyncResourceContent>
        )
      }
    />
  )
}

export default FutureVolume
