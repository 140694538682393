import { Dropdown } from 'components/atoms'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import DateRangeSelector from 'components/common/DateRangeSelector'
import SeriesSelector from 'components/common/SeriesSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import React, { useState } from 'react'
import { dataService } from 'services'
import {
  ConstantTenorByDays,
  CONSTANT_TENORS_BY_DAYS,
  IChartFrequencyData,
  Source,
  Ticker,
} from 'types'
import { Model, SABRParam, SVIParam } from 'types/models'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import ChartLoader from './ChartLoader'
import MultiTimeSeriesLineChart from './MultiTimeSeriesLineChart'

const SVI_PARAM_OPTIONS = Object.entries(SVIParam)
  .filter(([_, val]) => {
    return val !== 'sviVol'
  })
  .map(([key, val]) => {
    return {
      name: key,
      value: val,
    }
  })

const SABR_PARAM_OPTIONS = Object.entries(SABRParam)
  .filter(([key, val]) => {
    return val !== 'sabrVol'
  })
  .map(([key, val]) => {
    return {
      name: key,
      value: val,
    }
  })

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: Model.SABR,
  },
  {
    name: 'SVI',
    value: Model.SVI,
  },
]

const OptionsHistoricalModel: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [activeModel, setActiveModel] = useState<Model>(Model.SVI)
  const [activeSVIParam, setActiveSVIParam] = useState<SVIParam>(SVIParam.A)
  const [activeSABRParam, setActiveSABRParam] = useState<SABRParam>(
    SABRParam.Alpha,
  )
  const [activeTenors, setActiveTenors] = useState<Array<ConstantTenorByDays>>([
    CONSTANT_TENORS_BY_DAYS[0],
  ])
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )

  const handleTenorsChange = (tenors: Array<ConstantTenorByDays>) => {
    if (!tenors.length) {
      return
    }
    setActiveTenors(tenors)
  }

  const [dataReader] = useAsyncResource(
    dataService.getHistoricalModelParams,
    source,
    ticker,
    activeModel,
    activeTenors,
    activeModel === Model.SABR ? activeSABRParam : activeSVIParam,
  )

  const title = 'Implied Vol Model Parameters'
  return (
    <ChartWrapper
      title={title}
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <Dropdown
            showLabel
            current={MODEL_OPTIONS.find(({ value }) => value === activeModel)}
            options={MODEL_OPTIONS}
            onChange={setActiveModel}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Model"
          />
          {activeModel === Model.SVI && (
            <Dropdown
              showLabel
              current={SVI_PARAM_OPTIONS.find(
                ({ value }) => value === activeSVIParam,
              )}
              options={SVI_PARAM_OPTIONS}
              onChange={setActiveSVIParam}
              className="k-mr-4"
              labelClassName="k-mr-2"
              label="Param"
            />
          )}
          {activeModel === Model.SABR && (
            <Dropdown
              showLabel
              current={SABR_PARAM_OPTIONS.find(
                ({ value }) => value === activeSABRParam,
              )}
              options={SABR_PARAM_OPTIONS}
              onChange={setActiveSABRParam}
              className="k-mr-4"
              labelClassName="k-mr-2"
              label="Param"
            />
          )}
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      series={
        <SeriesSelector
          options={CONSTANT_TENORS_BY_DAYS}
          value={activeTenors}
          handleChange={handleTenorsChange}
        />
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <MultiTimeSeriesLineChart
            dataReader={dataReader}
            frequency={frequency}
            activeSeries={activeTenors}
            valueAxisLabels={{ format: 'p1', font: '0.75em "OpenSans"' }}
            valueAxisTitle={() => {
              if (activeModel === Model.SVI) {
                const field = SVI_PARAM_OPTIONS.find(
                  ({ value }) => value === activeSVIParam,
                )
                return `SVI ${field?.name || ''}`
              }
              const field = SABR_PARAM_OPTIONS.find(
                ({ value }) => value === activeSABRParam,
              )
              return `SABR ${field?.name || ''}`
            }}
            singleAxis
            title={title}
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default OptionsHistoricalModel
