import React, { useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import { Loader } from '@progress/kendo-react-indicators'
import ChartWrapper from 'components/common/ChartWrapper'
import SeriesSelector from 'components/common/SeriesSelector'
import DateRangeSelector from 'components/common/DateRangeSelector'
import FrequencyChange, {
  FREQUENCIES,
} from 'components/molecules/FrequencyChange'
import { ACTIVE_TICKERS } from 'consts'
import { IChartFrequencyData, Source, Ticker } from 'types'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import MultiTimeSeriesLineChart from './MultiTimeSeriesLineChart'
import ChartLoader from './ChartLoader'

const PerpetualSwapsMidPrice: React.FC = () => {
  const [source, _] = useState(Source.DERIBIT)
  const [frequency, setFrequency] = useState<IChartFrequencyData>(
    FREQUENCIES['1D'],
  )
  const [activeTickers, setActiveTickers] = useState<Array<Ticker>>([
    Ticker.BTC,
    Ticker.ETH,
  ])

  const [seriesDataReader] = useAsyncResource(
    dataService.getPerpetualSwap,
    source,
    ACTIVE_TICKERS,
    'mid.px',
  )
  const title = 'Perpetual swap price'
  return (
    <ChartWrapper
      title={title}
      singleRow={false}
      controls={
        <>
          <FrequencyChange value={frequency} handleChange={setFrequency} />
          <DateRangeSelector />
        </>
      }
      series={
        <SeriesSelector
          options={ACTIVE_TICKERS}
          value={activeTickers}
          handleChange={setActiveTickers}
        />
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <MultiTimeSeriesLineChart
            dataReader={seriesDataReader}
            frequency={frequency}
            activeSeries={activeTickers}
            valueAxisTitle={(seriesLabel: string) => `${seriesLabel}-USD`}
            valueAxisCrosshairFormat="c0"
            title={title}
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default PerpetualSwapsMidPrice
