import React from 'react'
import { Label } from '@progress/kendo-react-labels'
import { StatNumber } from 'components/molecules/StatNumber'

export const SpotDisplayBar = () => {
  return (
    <div className="container-fluid p-10">
      <div className="row my-4 mt-4" style={{ marginTop: '20px' }}>
        <div className="col-12">
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="LAST"
            value={55152.45}
          />
          <div className=" k-mr-4 k-d-inline-flex">
            <Label />
            <span className="number">
              <strong>+$362.83(33%)</strong>
            </span>
          </div>
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="COMP"
            value={55149.37}
          />
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="Op"
            value={54968.21}
          />
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="Hi"
            value={55347.18}
          />
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="Lo"
            value={54950.18}
          />
          <StatNumber
            className="text-right k-mr-4"
            labelClassName="text-right k-mr-2"
            label="CI"
            value={54986.21}
          />
          <StatNumber
            className="text-right k-mr-4"
            label="VOLUME"
            labelClassName="text-right k-mr-2"
            hasCurrency={false}
            value={54950.18}
          />
        </div>
      </div>
    </div>
  )
}
