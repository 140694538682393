import {
  DateTimePicker as KendoDateTimePicker,
  DatePicker as KendoDatePicker,
} from '@progress/kendo-react-dateinputs'
import styled, { css } from 'styled-components'

const baseDatePickerStyles = css`
  display: flex;
  flex-direction: row-reverse;
  border-bottom: 1px solid #ffffff;
  &.k-input:focus-within {
    box-shadow: none;
  }
  &.k-input:focus,
  .k-input:focus-within {
    background-color: inherit;
    box-shadow: none;
  }
  .k-button {
    color: white;
    background: inherit;
    border: none;
  }
`

export const DateTimePicker = styled(KendoDateTimePicker)`
  ${baseDatePickerStyles}
`

export const DatePicker = styled(KendoDatePicker)`
  ${baseDatePickerStyles}
`
