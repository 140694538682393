import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
  ChartTooltip,
  ChartXAxis,
  ChartXAxisItem,
  ChartYAxis,
  ChartYAxisItem,
} from '@progress/kendo-react-charts'
import { DaysTenor } from 'consts'
import { HeatMapData } from 'types'

import { formatNumber } from '@telerik/kendo-intl'
import 'hammerjs'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components'

interface IHeatMapChartProps {
  data: Array<HeatMapData>
  color: (dataItem: HeatMapData) => string
  categories: { x: Array<string>; y: Array<string> }
  tooltipRenderer?: (props: any) => ReactNode // workaround broken Kendo types
  xAxisTitle?: string
  yAxisTitle?: string
  onCellClick: (dataItem: HeatMapData) => void
}
const HeatMapChart: React.FC<IHeatMapChartProps> = (
  props: IHeatMapChartProps,
) => {
  const {
    data,
    color,
    categories,
    onCellClick,
    tooltipRenderer,
    xAxisTitle,
    yAxisTitle,
  } = props
  const theme = useTheme()
  return (
    <>
      {!data.length ? (
        <p>No series data available</p>
      ) : (
        <>
          <Chart
            renderAs="svg"
            style={{
              width: '100%',
              height: 400,
              overscrollBehavior: 'contain',
              paddingTop: '2rem',
              cursor: 'pointer',
            }}
            transitions={false}
            onSeriesClick={(e) => {
              onCellClick(e.dataItem)
            }}
          >
            <ChartTooltip
              render={tooltipRenderer}
              color="white"
              background={theme.palette.common.kendoGrey}
            />
            <ChartSeries>
              <ChartSeriesItem
                type="heatmap"
                xField="expiry"
                yField="strike"
                field="value"
                data={data}
                color={(e) => color(e.dataItem)}
              >
                <ChartSeriesLabels
                  content={({ value }) => formatNumber(value, 'p2')}
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartYAxis>
              <ChartYAxisItem
                categories={categories.y}
                labels={{ content: ({ value }) => value.toUpperCase() }}
                title={{ text: yAxisTitle }}
              />
            </ChartYAxis>
            <ChartXAxis>
              <ChartXAxisItem
                labels={{ content: ({ value }) => DaysTenor[value] }}
                title={{ text: xAxisTitle }}
              />
            </ChartXAxis>
          </Chart>
        </>
      )}
    </>
  )
}

export default HeatMapChart
