import {
  Text,
  geometry,
  Rect as RectShape,
  Group,
  Circle,
} from '@progress/kendo-drawing'
import { LegendItemVisualArgs } from '@progress/kendo-react-charts'

const { Point, Rect, Size, Circle: GeomCircle } = geometry

export const customLabel = (props: LegendItemVisualArgs): Group => {
  // Create rectangular shape on top of which the label will be drawn
  const rectOptions = {
    stroke: { color: 'none' },
    fill: { color: 'none' },
  }
  const rectGeometry = new Rect(new Point(0, 3), new Size(60, 10))
  const rect = new RectShape(rectGeometry, rectOptions)

  // Create the lines used to represent the custom legend item
  const pathColor = props.options.markers.border.color

  // Create the text associated with the legend item
  const labelText = props.series.name
  const labelFont = props.options.labels.font

  const fontColor = props.options.labels.color
  const textOptions = { font: labelFont, fill: { color: fontColor } }
  const text = new Text(labelText, new Point(22, 0), textOptions)

  const geom = new GeomCircle([10, 10], 5)
  const circle = new Circle(geom, {
    stroke: { color: pathColor, width: 1 },
    fill: { color: pathColor },
  })
  // Place all the shapes in a group
  const group = new Group()

  group.append(rect, circle, text)
  // set opacity if the legend item is disabled

  return group
}
