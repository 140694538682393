import { ReactElement, ReactText, Attributes } from 'react'
import styled, { css } from 'styled-components'
import { VariantType } from 'styles/theme'
import { typographyCSS } from '../Typography/styles'

export interface ButtonProps extends Attributes {
  variant?: 'outlined' | 'contained' | 'inline' | 'neutral'
  size?: 'sm' | 'md'
  fullWidth?: boolean
  color?: string
  children: ReactElement | ReactText
  pulse?: boolean
}

const variantStyles = (theme, variant = 'contained', color) =>
  ({
    outlined: css`
      border-color: ${theme.palette.primary.contrastText};
      color: transparent;
      transition: filter 300ms;
      &:hover {
        color: ${theme.palette.primary.contrastText};
        background: transparent;
        border-color: ${theme.palette.primary.contrastText};
        filter: brightness(140%);
        transition: filter 150ms;
      }
      &:focus {
        color: ${theme.palette.primary.contrastText};
        background: transparent;
        border-color: ${theme.palette.primary.contrastText};
        filter: brightness(150%);
        transition: filter 50ms;
      }
      &:disabled {
        border-color: ${theme.palette.primary.dark};
        color: ${theme.palette.common.darkGrey};
        transition: color 150ms;
      }
    `,
    contained: css`
      background-color: ${theme.palette.primary.contrastText};
      color: ${theme.palette.primary.light};
      transition: filter 300ms;
      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }
      &:focus {
        filter: brightness(150%);
        transition: filter 50ms;
      }
      &:disabled {
        filter: contrast(50%);
        transition: filter 150ms;
      }
    `,
    inline: css`
      background-color: inherit;
      color: inherit;
      border: none;
      padding: 0 0 0;
      display: inline-flex;
      text-transformation: none;

      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }
    `,
    neutral: css`
      background-color: inherit;
      color: ${theme.palette.common[color] || color};
      display: flex;
      flex-direction: column;
      border: none;
      padding: 1rem;
      display: inline-flex;
      text-transformation: none;

      &:hover {
        filter: brightness(140%);
        transition: filter 150ms;
      }

      &:disabled {
        filter: contrast(50%);
        transition: filter 150ms;
      }
    `,
  }[variant])

const Button = styled.button<ButtonProps>`
  background: none;
  border: none;
  outline: none;
  border: 3px solid transparent;
  border-radius: 4px;
  transition: border-color 0.3s, color 0.3s, background 0.3s;

  text-align: center;
  cursor: pointer;
  padding: 50px 30px 50px 80px;
  font-weight: 600 !important;
  &:disabled {
    cursor: initial;
  }

  ${({ variant, size }) => {
    if (variant === 'inline') return undefined
    return size === 'sm'
      ? css`
          border-width: 2px;
          ${typographyCSS(VariantType.buttonSmall)}
          padding: 0.95em 2em;
        `
      : css`
          ${typographyCSS(VariantType.buttonMedium)}
          padding: 0.95em 2em;
        `
  }}
  ${({ theme, variant, color }) => variantStyles(theme, variant, color)}

  ${({ fullWidth }) =>
    fullWidth &&
    `
  display: block;
  width: 100%;
  `}
  ${({ pulse, theme }) =>
    pulse
      ? `
      animation: pulse 4s infinite;

      @keyframes pulse {
        0% {
          background-color: inherit;
        }

        50% {
          opacity; 0.2;
          background-color: ${theme.palette.common.grey1};
        }

        100% {
          background-color: inherit;
        }
      }
  `
      : ''}
`

export default Button
