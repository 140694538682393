import React, { useState } from 'react'
import { dataService } from 'services/lambdaDataService'

import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import { DateTimePicker } from 'components/atoms/DatePickers'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import { DATETIME_FORMAT, START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'
import { PriceType, Series, Source, Ticker } from 'types'
import { convertDateToUTC } from 'utils/convert-date-to-utc'
import TermStructureChart from './TermStructureChart'
import ChartLoader from './ChartLoader'

const FutureTermStructure: React.FC = () => {
  const [source, setSource] = useState(Source.DERIBIT)
  const [priceType, setPriceType] = useState(PriceType.YIELD)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [series, setSeries] = useState(Series.LISTED_EXPIRIES)
  const [dates, setDates] = useState<Array<number | 'LATEST'>>(['LATEST'])

  const [seriesDataReader] = useAsyncResource(
    dataService.getTermStructure,
    source,
    ticker,
    dates,
    series,
  )

  const handleDateSelect = (
    event: DatePickerChangeEvent | DateTimePickerChangeEvent,
  ) => {
    if (!event?.value) {
      return
    }
    const utcDate = convertDateToUTC(event.value)
    setDates(Array.from(new Set([...dates, utcDate.getTime()])))
  }

  const handleSeriesDeletion = (seriesIndex: number) =>
    setDates(dates.filter((_, indx) => indx !== seriesIndex))

  return (
    <ChartWrapper
      title="Term structure"
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
            priceType={priceType}
            handlePriceTypeChange={setPriceType}
            supportedPriceTypes={[PriceType.YIELD, PriceType.PRICE]}
            series={series}
            handleSeriesChange={setSeries}
            supportedSeries={[
              Series.LISTED_EXPIRIES,
              Series.CONSTANT_MATURITIES,
            ]}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <DateTimePicker
              width={300}
              onChange={handleDateSelect}
              format={DATETIME_FORMAT}
              min={START_OF_BLOCKSCHOLES_TIME}
              max={TODAY_UTC}
              placeholder="Select a date and time"
              fillMode="flat"
            />
          </div>
        </>
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <TermStructureChart
            dataReader={seriesDataReader}
            selectedField={priceType}
            onLegendItemClick={handleSeriesDeletion}
            xAxisTitle="Expiry (days)"
            title="Futures Term structure"
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default FutureTermStructure
