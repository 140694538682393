import React, { useState } from 'react'
import {
  DatePickerChangeEvent,
  DateTimePickerChangeEvent,
} from '@progress/kendo-react-dateinputs'
import { AsyncResourceContent, useAsyncResource } from 'use-async-resource'
import { Dropdown } from 'components/atoms'
import { DateTimePicker } from 'components/atoms/DatePickers'
import ChartWrapper from 'components/common/ChartWrapper'
import DataChooser from 'components/common/DataChooser'
import { DATETIME_FORMAT, START_OF_BLOCKSCHOLES_TIME, TODAY_UTC } from 'consts'
import { dataService } from 'services'
import { Source, Ticker } from 'types'
import { Model, SABRParam, SVIParam } from 'types/models'
import { convertDateToUTC } from 'utils/convert-date-to-utc'
import TermStructureChart from './TermStructureChart'
import ChartLoader from './ChartLoader'

const SVI_PARAM_OPTIONS = Object.entries(SVIParam).map(([key, val]) => {
  return {
    name: key,
    value: val,
  }
})

const SABR_PARAM_OPTIONS = Object.entries(SABRParam).map(([key, val]) => {
  return {
    name: key,
    value: val,
  }
})

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: Model.SABR,
  },
  {
    name: 'SVI',
    value: Model.SVI,
  },
]

const OptionsImpliedVolatility: React.FC = () => {
  const [maxDatetime, setMaxDatetime] = useState(TODAY_UTC)
  const [source, setSource] = useState(Source.DERIBIT)
  const [ticker, setTicker] = useState(Ticker.BTC)
  const [activeSVIParam, setActiveSVIParam] = useState<SVIParam>(SVIParam.Vol)
  const [activeSABRParam, setActiveSABRParam] = useState<SABRParam>(
    SABRParam.Vol,
  )
  const [activeModel, setActiveModel] = useState<Model>(Model.SVI)
  const [dates, setDates] = useState<Array<string>>(['LATEST'])

  const [seriesDataReader] = useAsyncResource(
    dataService.getOptionsImpliedVol,
    source,
    ticker,
    activeModel,
    dates,
  )

  const handleDateSelect = (
    event: DatePickerChangeEvent | DateTimePickerChangeEvent,
  ) => {
    if (!event?.value) {
      return
    }
    const utcDate = convertDateToUTC(event.value)
    setDates(Array.from(new Set([...dates, utcDate.toISOString()])))
  }

  const handleSeriesDeletion = (seriesIndex: number) =>
    setDates(dates.filter((_, indx) => indx !== seriesIndex))

  return (
    <ChartWrapper
      title="Volatility Term Structure"
      controls={
        <>
          <DataChooser
            source={source}
            handleSourceChange={setSource}
            supportedSources={[Source.DERIBIT]}
            ticker={ticker}
            handleTickerChange={setTicker}
            supportedTickers={[Ticker.BTC, Ticker.ETH]}
          />
          <Dropdown
            showLabel
            current={MODEL_OPTIONS.find(({ value }) => value === activeModel)}
            options={MODEL_OPTIONS}
            onChange={setActiveModel}
            className="k-mr-4"
            labelClassName="k-mr-2"
            label="Model"
          />
          {activeModel === Model.SVI && (
            <Dropdown
              showLabel
              current={SVI_PARAM_OPTIONS.find(
                ({ value }) => value === activeSVIParam,
              )}
              options={SVI_PARAM_OPTIONS}
              onChange={setActiveSVIParam}
              className="k-mr-4"
              labelClassName="k-mr-2"
              label="Param"
            />
          )}
          {activeModel === Model.SABR && (
            <Dropdown
              showLabel
              current={SABR_PARAM_OPTIONS.find(
                ({ value }) => value === activeSABRParam,
              )}
              options={SABR_PARAM_OPTIONS}
              onChange={setActiveSABRParam}
              className="k-mr-4"
              labelClassName="k-mr-2"
              label="Param"
            />
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <DateTimePicker
              width={300}
              onChange={handleDateSelect}
              format={DATETIME_FORMAT}
              min={START_OF_BLOCKSCHOLES_TIME}
              max={maxDatetime}
              placeholder="Select a date and time"
              fillMode="flat"
            />
          </div>
        </>
      }
      chart={
        <AsyncResourceContent
          fallback={<ChartLoader />}
          errorMessage="Failed to load series data"
        >
          <TermStructureChart
            dataReader={seriesDataReader}
            selectedField={
              activeModel === Model.SABR ? activeSABRParam : activeSVIParam
            }
            onLegendItemClick={handleSeriesDeletion}
            xAxisTitle="Days"
            yAxisTitle={() => {
              if (activeModel === Model.SVI) {
                const field = SVI_PARAM_OPTIONS.find(
                  ({ value }) => value === activeSVIParam,
                )
                return `SVI ${field?.name || ''}`
              }
              const field = SABR_PARAM_OPTIONS.find(
                ({ value }) => value === activeSABRParam,
              )
              return `SABR ${field?.name || ''}`
            }}
            title="Options Volatility Term Structure"
          />
        </AsyncResourceContent>
      }
    />
  )
}

export default OptionsImpliedVolatility
