import { MouseEventHandler, MutableRefObject, Ref, useRef } from 'react'
import { Chart, exportVisual } from '@progress/kendo-react-charts'
import { exportImage } from '@progress/kendo-drawing'
import { saveAs } from '@progress/kendo-file-saver'
import { downloadIcon } from '@progress/kendo-svg-icons'
import { SvgIcon } from '@progress/kendo-react-common'

export const useSave = (): {
  handleSave: (title: string) => void
  chartRef: MutableRefObject<Chart | null>
} => {
  const chartRef = useRef<Chart | null>(null)

  const handleSave = (title: string) => {
    if (!chartRef.current) return
    const chartVisual = exportVisual(chartRef.current)

    if (chartVisual) {
      exportImage(chartVisual)
        .then((dataURI) => saveAs(dataURI, `${title}.png`))
        .catch((e) => console.error(e))
    }
  }

  return { handleSave, chartRef }
}

const Download: React.FC<{
  handleClick: MouseEventHandler<HTMLButtonElement>
}> = ({ handleClick }) => {
  return (
    <button
      type="button"
      style={{
        background: 'inherit',
        border: 'none',
        padding: '0 0.75rem',
      }}
      onClick={handleClick}
    >
      <SvgIcon icon={downloadIcon} themeColor="primary" />
    </button>
  )
}

export default Download
