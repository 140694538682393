import { FinancialTicker } from 'nice-react-ticker'
import { LivePrices } from './useLivePrices'

const tickers = ['XBT', 'ETH', 'ADA', 'SOL', 'XRP', 'DOT', 'UNI']
const LiveDataFeed: React.FC<{ prices: LivePrices }> = ({
  prices,
}: {
  prices: LivePrices
}) => {
  const order =
    document.body.clientWidth < 1500
      ? tickers.slice(0, 4)
      : document.body.clientWidth < 1530
      ? tickers.slice(0, 5)
      : document.body.clientWidth < 1740
      ? tickers.slice(0, 6)
      : tickers

  const orderedPrices = Object.entries(prices).reduce((acc, [ticker, p]) => {
    const indx = order.findIndex((o) => o === ticker)
    acc[indx] = p
    return acc
  }, new Array(7))
  return (
    <span style={{ transform: 'scale(0.8)', padding: 0 }}>
      {orderedPrices.map((p, indx) => {
        return (
          <FinancialTicker
            change={p.prevPrice < p.price}
            percentage={
              p.prevPrice
                ? (((p.price - p.prevPrice) / p.prevPrice) * 100)
                    .toFixed(3)
                    .toString()
                : '0'
            }
            key={order[indx]}
            id={indx}
            symbol={order[indx] === 'XBT' ? 'BTC' : order[indx]}
            lastPrice={
              p?.prevPrice?.toFixed(4).toString() ||
              p.price.toFixed(4).toString()
            }
            currentPrice={p.price.toFixed(4).toString()}
          />
        )
      })}
    </span>
  )
}

export default LiveDataFeed
