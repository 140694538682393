import { dataService } from 'services'
import { Ticker, Source, IChartFrequencyData } from 'types'
import { useAsyncResource, AsyncResourceContent } from 'use-async-resource'
import ChartLoader from '../ChartLoader'
import MultiTimeSeriesLineChart from '../MultiTimeSeriesLineChart'

interface ExpiresChartWrapperProps {
  ticker: Ticker
  source: Source
  activeExpiries: Array<string>
  frequency: IChartFrequencyData
  title?: string
}
const ExpiresChartWrapper: React.FC<ExpiresChartWrapperProps> = ({
  ticker,
  source,
  activeExpiries,
  frequency,
  title,
}) => {
  const [constantExpiriesDataReader] = useAsyncResource(
    dataService.getFutureConstantExpiry,
    ticker,
    source,
    activeExpiries,
  )
  return (
    <AsyncResourceContent
      fallback={<ChartLoader />}
      errorMessage="Failed to load series data"
    >
      <MultiTimeSeriesLineChart
        dataReader={constantExpiriesDataReader}
        frequency={frequency}
        activeSeries={activeExpiries}
        valueAxisLabels={{ format: 'n0', font: '0.75em "OpenSans"' }}
        valueAxisTitle={() => `${ticker}-USD`}
        valueAxisCrosshairFormat="c2"
        singleAxis
        title={title}
      />
    </AsyncResourceContent>
  )
}

export default ExpiresChartWrapper
