import { classNames } from '@progress/kendo-react-common'
import { Label } from '@progress/kendo-react-labels'
import { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'

interface ISeriesSelectorProps<T> {
  value: Array<T>
  options: T[]
  handleChange: (newState: Array<T>) => void
  label?: string
  labelClassName?: string
}

const Badge = styled.div`
  padding: 5px 14px;
  margin-left: 8px;
  border-radius: 35px;
  border: 1px solid #ffffff;
  cursor: pointer;
`
const BadgeActive = styled.div`
  padding: 5px 14px;
  margin-left: 8px;
  border-radius: 35px;
  border: 1px solid #247cff;
  cursor: pointer;
  background: rgba(36, 124, 255, 0.2);
  font-weight: 600;
`

// TODO: add dataItemKey and textField props
const SeriesSelector = <T extends unknown>(
  props: PropsWithChildren<ISeriesSelectorProps<T>>,
): ReactElement => {
  const {
    options,
    value,
    handleChange,
    label = 'Series',
    labelClassName,
  } = props
  return (
    <div className="k-d-inline-flex k-align-items-center">
      <Label
        className={classNames('k-mr-2', labelClassName)}
        style={{ width: 'auto', textAlign: 'right' }}
      >
        {label}
      </Label>
      {options.map((item: any, index) => {
        return value.includes(item) ? (
          <BadgeActive
            key={index}
            onClick={() => {
              const val = [...value]
              const index = value.indexOf(item)
              if (index > -1) {
                val.splice(index, 1)
              }
              handleChange(val)
            }}
          >
            {item}
          </BadgeActive>
        ) : (
          <Badge
            key={index}
            onClick={() => {
              const val = [...value]
              val.push(item)
              handleChange(val)
            }}
          >
            {item}
          </Badge>
        )
      })}
    </div>
  )
}

export default SeriesSelector
