import { GridCellProps } from '@progress/kendo-react-grid'
import { Model } from 'types/models'
import { OptionType } from 'types/options'
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns'
import { Ticker } from 'types'
import styled from 'styled-components'
import {
  DatePicker,
  DatePickerChangeEvent,
} from '@progress/kendo-react-dateinputs'
import {
  DATE_FORMAT,
  SEVEN_DAYS_AHEAD,
  TODAY_UTC,
  TWO_YEARS_AHEAD,
} from 'consts'

const TICKER_OPTIONS = [
  {
    name: 'BTC',
    value: Ticker.BTC,
  },
  {
    name: 'ETH',
    value: Ticker.ETH,
  },
]

const MODEL_OPTIONS = [
  {
    name: 'SABR',
    value: Model.SABR,
  },
  {
    name: 'SVI',
    value: Model.SVI,
  },
]

const OPTION_TYPE_OPTIONS = [
  {
    name: 'Put',
    value: OptionType.PUT,
  },
  {
    name: 'Call',
    value: OptionType.CALL,
  },
]

const StyledDropDownList = styled(DropDownList)`
  & .k-input-inner {
    padding: 0.2rem !important;
  }
`

const StyledDatePicker = styled(DatePicker)`
  & {
    font-size: 1em;
  }
`

export const ModelDropdownCell = (props: GridCellProps) => {
  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]

  return (
    <td>
      <StyledDropDownList
        style={{ width: '100%', fontSize: '1em' }}
        onChange={handleChange}
        value={MODEL_OPTIONS.find((c) => c.value === dataValue)}
        data={MODEL_OPTIONS}
        textField="name"
        size="small"
      />
    </td>
  )
}

export const OptionTypeDropdownCell = (props: GridCellProps) => {
  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]

  return (
    <td>
      <StyledDropDownList
        style={{ width: '100%', fontSize: '1em' }}
        onChange={handleChange}
        value={OPTION_TYPE_OPTIONS.find((c) => c.value === dataValue)}
        data={OPTION_TYPE_OPTIONS}
        size="small"
        textField="name"
      />
    </td>
  )
}

export const TickerDropdownCell = (props: GridCellProps) => {
  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DropDownListChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]

  return (
    <td>
      <StyledDropDownList
        style={{ width: '100%', fontSize: '1em' }}
        onChange={handleChange}
        value={TICKER_OPTIONS.find((c) => c.value === dataValue)}
        data={TICKER_OPTIONS}
        size="small"
        textField="name"
      />
    </td>
  )
}

export const DateDropdownCell = (props: GridCellProps) => {
  const { onChange, dataItem, field = '' } = props
  const handleChange = (e: DatePickerChangeEvent) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem,
        field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      })
    }
  }
  const dataValue = dataItem[field] === null ? '' : dataItem[field]

  return (
    <td>
      <StyledDatePicker
        onChange={handleChange}
        value={dataValue}
        format={DATE_FORMAT}
        min={SEVEN_DAYS_AHEAD}
        max={TWO_YEARS_AHEAD}
        size="small"
      />
    </td>
  )
}

export const OptionPricerCommandCell = (props) => {
  const { dataItem, remove, update, isLoading } = props

  return (
    <td className="k-command-cell">
      <button
        type="button"
        style={{ fontSize: '0.8rem' }}
        className="k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-base k-grid-save-command"
        onClick={() => update(dataItem)}
        disabled={isLoading}
      >
        <span className="k-icon k-i-reload" />
      </button>
      <button
        type="button"
        style={{ fontSize: '0.8rem' }}
        className="k-button k-button-sm k-rounded-sm k-button-solid k-button-solid-base k-grid-cancel-command"
        onClick={() => remove(dataItem)}
        disabled={isLoading}
      >
        <span className="k-icon k-i-delete" />
      </button>
    </td>
  )
}
