import {
  Chart,
  ChartTitle,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartLegendItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  DragEndEvent,
  ZoomEndEvent,
  ZoomStartEvent,
} from '@progress/kendo-react-charts'
import { useTheme } from 'styled-components'
import {
  DateRange,
  HistoricalAnalyzerTimeSeriesData,
  IChartFrequencyData,
} from 'types'
import { formatDate, formatNumber } from '@telerik/kendo-intl'
import { useEffect, useMemo, useState } from 'react'
import { compareAsc } from 'date-fns'
import Query from './query'

import 'hammerjs'
import { AxisNames, HistChartTitle } from './types'
import { indexOf, split } from 'lodash'
import { ConsoleLogger } from '@aws-amplify/core'
import Download, { useSave } from 'hooks/useSave'

interface IHistoricalAnalyzerChartProps {
  data: Array<[Query, HistoricalAnalyzerTimeSeriesData]>
  dateRange: DateRange
  frequency: IChartFrequencyData
  axisNames: AxisNames
  title: HistChartTitle
}

const tooltipRenderer = ({ point }) => {
  return (
    <table>
      <tr>
        <td>{formatDate(point.category, { skeleton: 'yMMMdEHm' })}</td>
      </tr>
      <tr>
        <td>{`${point?.series?.name || ''}: ${formatNumber(
          point.value,
          'n2',
        )}`}</td>
      </tr>
    </table>
  )
}

const AXIS_LABEL_LOOKUP = {
  left: 'LHS',
  right: 'RHS',
}

const customLegendItemVisual = (args) => {
  const { createVisual, pointIndex } = args
  const legendVisual = createVisual()
  return legendVisual
}

// TODO: implement onLegendItemClick and connect to query active status
const HistoricalAnalyzerTimeSeriesChart: React.FC<IHistoricalAnalyzerChartProps> =
  ({ data, dateRange, frequency, axisNames, title }) => {
    const theme = useTheme()
    const { chartRef, handleSave } = useSave()

    const [timestampRange, setTimestampRange] = useState<DateRange>(() => {
      const allSeriesDates = data
        .flatMap(([_, series]) => series.data.map((el) => el.x))
        .sort((a, b) => compareAsc(a, b))
      return {
        from: allSeriesDates[0],
        until: allSeriesDates[allSeriesDates.length - 1],
      }
    })

    useEffect(() => {
      setTimestampRange(dateRange)
    }, [dateRange])

    useEffect(() => {
      const allSeriesDates = data
        .flatMap(([_, series]) => series.data.map((el) => el.x))
        .sort((a, b) => compareAsc(a, b))
      setTimestampRange({
        from: allSeriesDates[0],
        until: allSeriesDates[allSeriesDates.length - 1],
      })
    }, [data])

    const handleZoomEnd = (e: ZoomEndEvent) => {
      if (
        e.axisRanges.timestamp.min instanceof Date &&
        e.axisRanges.timestamp.max instanceof Date
      ) {
        setTimestampRange({
          from: e.axisRanges.timestamp.min,
          until: e.axisRanges.timestamp.max,
        })
      }
    }

    const handleZoomStart = (e: ZoomStartEvent) => {
      if (!e.nativeEvent.shiftKey) {
        e.preventDefault()
      }
    }

    const handleDragEnd = (e: DragEndEvent) => {
      if (e.nativeEvent.event.shiftKey) {
        // Shift-drag is used for zooming, so we do not want to handle here
        return
      }
      if (
        e.axisRanges.timestamp.min instanceof Date &&
        e.axisRanges.timestamp.max instanceof Date
      ) {
        setTimestampRange({
          from: e.axisRanges.timestamp.min,
          until: e.axisRanges.timestamp.max,
        })
      }
    }

    // FIXME: extract to hook
    const minYValue = useMemo(() => {
      const allSeriesData = data.flatMap(([query, series]) => {
        return query.isActive ? series.data.map((el) => el.y) : []
      })
      const min = Math.min(...(allSeriesData as Array<number>))
      return Number.isNaN(min) ? 0 : min
    }, [data])

    return (
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: '3rem',
          }}
        >
          <span
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          ></span>
        </div>
        <Chart
          pannable
          renderAs="svg"
          style={{
            width: 1200,
            height: 400,
            overscrollBehavior: 'contain',
          }}
          zoomable={{ mousewheel: { rate: 0.1 } }}
          onZoomStart={handleZoomStart}
          onZoomEnd={handleZoomEnd}
          onDragEnd={handleDragEnd}
          transitions={false}
          ref={chartRef}
        >
          <ChartLegend position="bottom" orientation="horizontal">
            <ChartLegendItem />
          </ChartLegend>
          <ChartTooltip />
          <ChartValueAxis>
            <ChartValueAxisItem
              name="left"
              title={{ text: axisNames.left }}
              labels={{ format: 'n2', font: '0.75em "OpenSans"' }}
              axisCrossingValue={minYValue}
            />
            <ChartValueAxisItem
              name="right"
              title={{
                text: axisNames.right,
              }}
              labels={{ format: 'n2', font: '0.75em "OpenSans"' }}
              axisCrossingValue={minYValue}
            />
          </ChartValueAxis>
          <ChartSeries>
            {data.map(
              ([query, seriesData]) =>
                query.isActive && (
                  <ChartSeriesItem
                    key={query.id}
                    type="line"
                    style="smooth"
                    name={`${query.userFacingIdent} (${
                      AXIS_LABEL_LOOKUP[query.axis]
                    })`}
                    axis={query.axis}
                    width={2}
                    field="y"
                    categoryAxis="timestamp"
                    categoryField="x"
                    data={seriesData.data}
                    markers={{ visible: false }}
                    color={query.histSeriesColor}
                  >
                    <ChartSeriesItemTooltip
                      render={tooltipRenderer}
                      color="white"
                      background={theme.palette.common.kendoGrey}
                    />
                  </ChartSeriesItem>
                ),
            )}
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              name="timestamp"
              baseUnit={frequency.unit}
              baseUnitStep={frequency.step}
              min={timestampRange.from}
              max={timestampRange.until}
              maxDivisions={20}
              axisCrossingValue={[0, Date.now()]}
              labels={{
                rotation: 'auto',
                font: '0.75em "OpenSans"',
                dateFormats: {
                  weeks: 'd MMM yy',
                },
              }}
            />
          </ChartCategoryAxis>
        </Chart>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
          }}
        >
          <p style={{ opacity: '50%', fontSize: '0.9rem' }}>
            Shift + scroll to zoom
          </p>
          {data.length > 0 && (
            <Download handleClick={() => handleSave(title)} />
          )}
        </div>
      </>
    )
  }

export default HistoricalAnalyzerTimeSeriesChart
